import React, { useEffect, useState } from "react";
import { firestore, firebaseAuth } from "../../config/Fire";
import jsPDF from "jspdf";
import * as html2canvas from "html2canvas";

import moment from "moment";
// reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { useHistory, useLocation } from "react-router-dom";
import BillingsHeader from "components/Headers/BillingsHeader";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import CurrencyFormat from "react-currency-format";
import ViewNotes from "./ViewNotes";

var FileSaver = require("file-saver");

const CreditNotes = () => {
  const [creditNotes, setcreditNotes] = useState([]);
  const [key, setkey] = useState("");
  const [search, setsearch] = useState("");
  const [modal, setModal] = useState(false);

  useEffect(() => {
    firestore
      .collection("credit-notes")
      .orderBy("timeStamp", "desc")
      .onSnapshot((querrySnapshots) => {
        const creditNotes = [];
        querrySnapshots.forEach((documentSnapshots) => {
          creditNotes.push({
            ...documentSnapshots.data(),
            key: documentSnapshots.id,
          });
        });
        setcreditNotes(creditNotes);
      });
  }, []);

  const history = useHistory();

  const handleDelete = (key) => {
    firestore
      .collection("credit-notes")
      .doc(key)
      .delete()
      .then((res) => {
        setModal(!modal);
      });
  };

  const handleModal = (key) => {
    setkey(key);
    setModal(!modal);
  };

  const handleSearchInput = (event) => {
    console.log(event.target.value);
    setsearch(event.target.value);
    const values = [];
    for (var i = 0; i < creditNotes.length; i++) {
      console.log(creditNotes[i].to.includes(event.target.value));
      if (
        creditNotes[i].to
          .toLowerCase()
          .includes(event.target.value.toLowerCase())
      ) {
        values.push(creditNotes[i]);
      }
    }
    if (event.target.value === "") {
      firestore
        .collection("credit-notes")
        .orderBy("timeStamp", "desc")
        .onSnapshot((querrySnapshots) => {
          const creditNotes = [];
          querrySnapshots.forEach((documentSnapshots) => {
            creditNotes.push({
              ...documentSnapshots.data(),
              key: documentSnapshots.id,
            });
          });
          setcreditNotes(creditNotes);
        });
    }
    console.log(values);
    setcreditNotes(values);
  };

  const exportPdf = () => {
    history.replace("/viewNotes.js");
    window.print();

    // const input = document.getElementById('pdfdownload');
    // html2canvas(input)
    //   .then((canvas) => {
    //     const imgData = canvas.toDataURL('image/png');
    //     const pdf = new jsPDF();
    //     pdf.addImage(imgData, 'JPEG', 0, 0);
    //     // pdf.output('dataurlnewwindow');
    //     pdf.save("download.pdf");
    //   })
    // ;

    // var file = new File([pdflink], `${pdftitle}.pdf`, {
    //   type: "application/pdf;charset=utf-8",
    // });
    // FileSaver.saveAs(file);

    // var oReq = new XMLHttpRequest();
    // The Endpoint of your server
    // var URLToPDF = pdflink;

    // Configure XMLHttpRequest
    // oReq.open("GET", URLToPDF, true);

    // Important to use the blob response type
    // oReq.responseType = "blob";

    // When the file request finishes
    // Is up to you, the configuration for error events etc.
    // oReq.onload = function() {
    // Once the file is downloaded, open a new window with the PDF
    // Remember to allow
    //   var file = new Blob([oReq.response], {
    //     type: 'text/plain; charset=utf-8'
    // });

    // Generate file download directly in the browser !
    //   FileSaver.saveAs(file, "mypdffilename.pdf");
    // };

    // oReq.send();
  };

  return (
    <>
      <div>
        <Modal isOpen={modal} toggle={handleModal}>
          <ModalHeader toggle={handleModal}>Alert</ModalHeader>
          <ModalBody>Are you sure?</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => handleDelete(key)}>
              Confirm
            </Button>
            <Button color="secondary" onClick={handleModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
      <div
        className="header pb-4 pt-5 pt-lg-2 d-flex align-items-center"
        style={{
          minHeight: "600px",
          backgroundImage:
            "url(" +
            require("../../assets/img/theme/template-cover.png").default +
            ")",
          backgroundSize: "cover",
          backgroundPosition: "center top",
        }}
      >
        {/* Mask */}
        <span className="mask bg-gradient-default opacity-8" />
        {/* Header container */}
        <Container className="d-flex align-items-center" fluid>
          <Row>
            <Col lg="7" md="4">
              <h1 className="display-2 text-white">Hey YEP</h1>
              <p className="text-white mt-0 mb-5">
                This is Billing page. You can use these form to create invoice.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      {/* Page content */}
      <Container className="mt--9" fluid>
        <Row className="mt-5">
          <div className="col">
            <Card className="bg-default shadow">
              <CardHeader className="bg-transparent border-0">
                <Row>
                  <Col>
                    <h3 className="text-white mb-0">Total Billings</h3>
                  </Col>
                  <Col>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-search" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Search"
                        type="text"
                        name="search"
                        value={search}
                        onChange={(e) => handleSearchInput(e)}
                      />
                    </InputGroup>
                  </Col>
                </Row>
              </CardHeader>
              <Table
                className="align-items-center table-dark table-flush"
                responsive
              >
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">Bill Date</th>
                    <th scope="col">Bill To</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Total Books</th>
                    <th scope="col">Credit Note No.</th>
                    {/* <th scope="col">Dated</th> */}
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {creditNotes.map((item, index) => (
                    <tr>
                      <th scope="row">
                        <Media className="align-items-center">
                          <span className="mb-0 text-sm">
                            {moment(item?.timeStamp).date() +
                              "/" +
                              parseInt(moment(item?.timeStamp).month() + 1) +
                              "/" +
                              moment(item?.timeStamp).year() +
                              ", "}
                            {moment(item?.timeStamp).format("LT")}
                          </span>
                        </Media>
                      </th>
                      <th scope="row">
                        {item.billingInfo && item?.billingInfo[0]?.to}
                      </th>
                      <td>
                        <CurrencyFormat
                          value={
                            parseInt(Math.round(item?.netAmount)) +
                            parseInt(item.price ? item.price : 0)
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"₹"}
                        />
                      </td>
                      <td>{item?.totalBooks}</td>
                      <td>{item?.creditNoteNo}</td>
                      {/* <td>{(item?.invoiceInfo[0]?.dated === "undefined-undefined-" ? null: item?.invoiceInfo[0]?.dated)}</td> */}
                      <td className="text-right">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            href="#pablo"
                            role="button"
                            size="sm"
                            color=""
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem
                              href="#pablo"
                              onClick={() =>
                                history.push(`/view-note/${item.key}`, {
                                  params: item.key,
                                })
                              }
                            >
                              View Details
                            </DropdownItem>
                            <DropdownItem
                              href="#pablo"
                              onClick={(e) =>
                                history.push(`/edit-credit-note/${item.key}`, {
                                  params: item.key,
                                })
                              }
                            >
                              Edit
                            </DropdownItem>
                            <DropdownItem
                              href="#pablo"
                              onClick={() => handleModal(item?.key)}
                            >
                              Delete
                            </DropdownItem>
                            <DropdownItem
                              // href="#pablo"
                              onClick={() =>
                                history.push(`/view-note2/${item.key}`, {
                                  params: item.key,
                                })
                              }
                            >
                              Download
                              {/* <a href={`/view-note/${item.key}.pdf`}  download="proposed_file_name">Download</a> */}
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default CreditNotes;
