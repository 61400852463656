import React, { useEffect, useState } from "react";
import { firestore, firebaseAuth } from "../../config/Fire";
import moment from "moment";
 // reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { useHistory, useLocation } from "react-router-dom";
import {Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import DealerHeader from "components/Headers/DealerHeader";

const Dealers = () => {
  const history = useHistory();
  const [dealers, setDealers] = useState([]);
  const [key, setkey] = useState("");
  const [modal, setModal] = useState(false);
  useEffect(() => {
    firestore
    .collection("dealers")
    .orderBy("timeStamp", "desc")
    .onSnapshot(querrySnapshots => {
      const dealers = [];
      querrySnapshots.forEach(documentSnapshots => {
        dealers.push({
          ...documentSnapshots.data(),
          key: documentSnapshots.id,
        });
      });
      setDealers(dealers);
    })
  }, []);
  const handleModal = (key) => {
    setkey(key);
    setModal(!modal);

  } 

  const handleDelete = (key) => {
    firestore
    .collection("dealers")
    .doc(key)
    .delete()
    .then(res => {
      setModal(!modal);
    })
  }
  return (
    <>
    <div>
      <Modal isOpen={modal} toggle={handleModal}>
        <ModalHeader toggle={handleModal}>Alert</ModalHeader>
        <ModalBody>
          Are you sure?
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => handleDelete(key)}>Confirm</Button>
          <Button color="secondary" onClick={handleModal}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
      <DealerHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Our Dealers</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Dealer</th>
                    <th scope="col">Email</th>
                    <th scope="col">Registered on</th>
                    <th scope="col">Contact</th>
                    <th scope="col">Status</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                {dealers.map((item, index) => (
                  <tr>
                    <td onClick={() => history.push(`/dealerbilling/${item.key}`, {billTo: item?.basicInfo[0]?.name})}>
                    {item?.basicInfo[0]?.name}
                    </td>
                    <td>
                    {item?.basicInfo[0]?.email}
                    </td>
                    <td scope="row">
                      <Media className="align-items-center">
                          <span className="mb-0 text-sm">
                            {moment(item?.timeStamp).format('lll')}
                          </span>
                      </Media>
                    </td>
                    <td>
                    {item?.basicInfo[0]?.phoneNo}
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div>
                        Active
                        </div>
                      </div>
                    </td>
                    <td className="text-right">
                      <UncontrolledDropdown>
                        <DropdownToggle
                          className="btn-icon-only text-light"
                          href="#pablo"
                          role="button"
                          size="sm"
                          color=""
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fas fa-ellipsis-v" />
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-arrow" right>
                          {/* <DropdownItem
                            href="#pablo"
                            onClick={() => history.push(`/invoice/${item.key}`, {params: item.key})}
                          >
                            View Details
                          </DropdownItem> */}
                          <DropdownItem
                            href="#pablo"
                            onClick={(e) => history.push(`/edit-dealer/${item.key}`, {params: item.key})}
                          >
                            Edit
                          </DropdownItem>
                          <DropdownItem
                            href="#pablo"
                            onClick={(e) => handleModal(item?.key)}
                          >
                            Delete
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </td>
                  </tr>
                ))}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem className="disabled">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                        tabIndex="-1"
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem className="active">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        1
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        2 <span className="sr-only">(current)</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        3
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
        
      </Container>
    </>
  );
};

export default Dealers;
