import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Table,
  Media,
  Badge,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";
import { savePDF } from "@progress/kendo-react-pdf";
// core components
import InvoiceHeader from "components/Headers/InvoiceHeader";
import { firestore } from "../../config/Fire";
import { ToWords } from "to-words";
import { useLocation, useParams, useHistory } from "react-router-dom";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import CurrencyFormat from "react-currency-format";
import Converter from "number-to-words";
var converter = require("number-to-words");
const Invoice = ({ match }) => {
  const location = useLocation();
  const history = useHistory();
  const [param, setparam] = useState("");
  const [billData, setbillData] = useState([]);
  let { slug,id } = useParams();
  // console.log('slug', match);
  const toWords = new ToWords();
  useEffect(() => {
    if (match.params.id != "") {
      firestore
        .collection("billings")
        .doc(match.params.id)
        .onSnapshot((documentSnapshots) => {
          const billData = [];
          billData.push({
            ...documentSnapshots.data(),
            key: documentSnapshots.id,
          });
          setbillData(billData[0]);
          // console.log('bilData', billData[0])
        });
    }
  }, []);

  const bodyRef = React.createRef();

  const generatePDF = () => {
    window.print();
  };

  const createPdf = (html) => {
    savePDF(html, {
      paperSize: "Letter",
      fileName: "form.pdf",
      margin: 3,
    });
  };

  return (
    <>
      {/* <InvoiceHeader /> */}
      {/* Page content */}
      <div ref={bodyRef}>
        <Container className="p-1" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row>
                    <Col>
                      <img
                        alt="..."
                        className="rounded-circle"
                        src={require("../../assets/img/brand/yep_logo 1.jpg").default}
                        style={{ width: 265, height: 120 }}
                        onClick={() => generatePDF()}
                      />
                    </Col>
                    <Col className="text-center">
                      <Row className="text-center">
                        <Col className="text-center">
                          <h2 style={{ fontSize: 28, fontWeight: "bold" }}>
                            YEP Education Pvt. Ltd{" "}
                          </h2>
                        </Col>
                      </Row>
                      <Row className="text-center">
                        <Col className="text-center mt-4">
                          <h4 style={{ fontWeight: "bold" }}>
                            Address: C-313, Raghubir Nagar New Delhi 110027
                          </h4>
                        </Col>
                      </Row>
                    </Col>
                    <Col className="text-right">
                      <h3 style={{ fontWeight: "bold" }}>
                        GSTIN : 07AABCY3475H1Z4
                      </h3>
                      <h3 style={{ fontWeight: "bold" }}>
                        HSN Code : 49011010
                      </h3>
                      <h4 style={{ fontWeight: "bold" }}>
                        Email: info@yep.education
                      </h4>
                      <h4 style={{ fontWeight: "bold" }}>
                        Web: www.yep.education
                      </h4>
                      <h4 style={{ fontWeight: "bold" }}>Mob. : 8826467474</h4>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="bg-white mt--4">
                  <Row>
                    {billData?.billingInfo?.map((item, index) => (
                      <Col className="border border-darker m-0 p-0">
                        <table className="w-100">
                          <thead>
                            <tr>
                              <th>
                                <h3 style={{ fontWeight: "bold" }}>
                                  Bill To:{" "}
                                </h3>
                              </th>
                              <th>
                                <h3 style={{ fontWeight: "bold" }}>
                                  {item.billTo}
                                </h3>
                              </th>
                            </tr>
                            <tr>
                              <th colSpan="2">
                                <hr className="m-0 p-0 bg-dark" />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <h4 style={{ fontWeight: "bold" }}>
                                  Address:{" "}
                                </h4>
                              </td>
                              <td>
                                <h4 style={{ fontWeight: "bold" }}>
                                  {item.address}
                                </h4>
                              </td>
                            </tr>
                            <tr>
                              <th colSpan="2">
                                <hr className="m-0 p-0 bg-dark" />
                              </th>
                            </tr>
                            <tr>
                              <td>
                                <h4 style={{ fontWeight: "bold" }}>
                                  Contact Person:{" "}
                                </h4>
                              </td>
                              <td>
                                <h4 style={{ fontWeight: "bold" }}>
                                  {item.contactPerson}
                                </h4>
                              </td>
                            </tr>
                            <tr>
                              <th colSpan="2">
                                <hr className="m-0 p-0 bg-dark" />
                              </th>
                            </tr>
                            <tr>
                              <td>
                                <h4 style={{ fontWeight: "bold" }}>
                                  Phone No.:
                                </h4>
                              </td>
                              <td>
                                <h4 style={{ fontWeight: "bold" }}>
                                  {item.phoneNo}
                                </h4>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </Col>
                    ))}
                    {billData?.shippingInfo?.map((item, index) => (
                      <Col className="border border-darker m-0 p-0">
                        <table className="w-100">
                          <thead>
                            <tr>
                              <th>
                                <h3 style={{ fontWeight: "bold" }}>
                                  Ship To:{" "}
                                </h3>
                              </th>
                              <th>
                                <h3 style={{ fontWeight: "bold" }}>
                                  {item.shipTo}
                                </h3>
                              </th>
                            </tr>
                            <tr>
                              <th colSpan="2">
                                <hr className="m-0 p-0 bg-dark" />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <h4 style={{ fontWeight: "bold" }}>
                                  Address:{" "}
                                </h4>
                              </td>
                              <td>
                                <h4 style={{ fontWeight: "bold" }}>
                                  {item.address}
                                </h4>
                              </td>
                            </tr>
                            <tr>
                              <th colSpan="2">
                                <hr className="m-0 p-0 bg-dark" />
                              </th>
                            </tr>
                            <tr>
                              <td>
                                <h4 style={{ fontWeight: "bold" }}>
                                  Contact No.:{" "}
                                </h4>
                              </td>
                              <td>
                                <h4 style={{ fontWeight: "bold" }}>
                                  {item.contactNo}
                                </h4>
                              </td>
                            </tr>
                            <tr>
                              <th colSpan="2">
                                <hr className="m-0 p-0 bg-dark" />
                              </th>
                            </tr>
                          </tbody>
                        </table>
                      </Col>
                    ))}
                    {billData?.invoiceInfo?.map((item, index) => (
                      <Col className="border border-darker m-0 p-0">
                        <table className="w-100">
                          <thead>
                            <tr>
                              <th>
                                <h3 style={{ fontWeight: "bold" }}>
                                  Invoice No :
                                </h3>
                              </th>
                              <th>
                                <h3 style={{ fontWeight: "bold" }}>
                                  {item.invoiceNo}
                                </h3>
                              </th>
                            </tr>
                            <tr>
                              <th colSpan="2">
                                <hr className="m-0 p-0 bg-dark" />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <h4 style={{ fontWeight: "bold" }}>Dated : </h4>
                              </td>
                              <td>
                                <h4 style={{ fontWeight: "bold" }}>
                                  {moment(billData?.timeStamp).date() +
                                    "/" +
                                    parseInt(
                                      moment(billData?.timeStamp).month() + 1
                                    ) +
                                    "/" +
                                    moment(billData?.timeStamp).year()}
                                </h4>
                              </td>
                              {/* <td ><h4 style={{fontWeight:"bold"}}>{moment(item.dated).format("DD-MM-YYYY")}</h4></td> */}
                              {/* <td ><h4 style={{fontWeight:"bold"}}>{moment(item.dated).date()+"/" + parseInt(moment(item.dated).month()+1)+"/"+ moment(item.dated).year()}</h4></td> */}
                            </tr>
                            {/* <tr>
                            <th colSpan="2"><hr className="m-0 p-0 bg-dark" /></th>
                          </tr> */}
                            {/* <tr>
                            <td ><h4 style={{fontWeight:"bold"}}>Cust Order ref : </h4></td>
                            <td ><h4 style={{fontWeight:"bold"}}>{item.custOrderRef}</h4></td>
                          </tr> */}
                            <tr>
                              <th colSpan="2">
                                <hr className="m-0 p-0 bg-dark" />
                              </th>
                            </tr>
                            {/* <tr>
                            <td ><h4 style={{fontWeight:"bold"}}>Registered at : </h4></td>
                            <td ><h4 style={{fontWeight:"bold"}}>{moment(billData.timeStamp).date() +"/" + parseInt(moment(billData.timeStamp).month()+1) + "/" + moment(billData.timeStamp).year()}</h4></td>
                          </tr> */}
                          </tbody>
                        </table>
                      </Col>
                    ))}
                  </Row>
                  <Row className="border border-darker">
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="">
                        <tr>
                          <th
                            scope="col"
                            style={{ fontWeight: "bold", fontSize: 16 }}
                          >
                            S. No.
                          </th>
                          <th
                            scope="col"
                            style={{ fontWeight: "bold", fontSize: 16 }}
                          >
                            Book Name
                          </th>
                          <th
                            scope="col"
                            style={{ fontWeight: "bold", fontSize: 16 }}
                          >
                            Price
                          </th>
                          <th
                            scope="col"
                            style={{ fontWeight: "bold", fontSize: 16 }}
                          >
                            Disc. %
                          </th>
                          <th
                            scope="col"
                            style={{ fontWeight: "bold", fontSize: 16 }}
                          >
                            Quantity
                          </th>
                          <th
                            scope="col"
                            style={{ fontWeight: "bold", fontSize: 16 }}
                          >
                            Total
                          </th>
                        </tr>
                      </thead>
                      {billData?.productInfo?.map((item, index) => (
                        <tbody>
                          <tr>
                            <th scope="row">
                              <Media className="align-items-center">
                                <span
                                  className="mb-0 text-sm"
                                  style={{ fontWeight: "bold", fontSize: 16 }}
                                >
                                  {index + 1}
                                </span>
                              </Media>
                            </th>
                            <td style={{ fontWeight: "bold", fontSize: 16 }}>
                              {item.particulars}
                            </td>
                            <td style={{ fontWeight: "bold", fontSize: 16 }}>
                              {item.price}
                            </td>
                            <td style={{ fontWeight: "bold", fontSize: 16 }}>
                              {item.discount}
                            </td>
                            <td style={{ fontWeight: "bold", fontSize: 16 }}>
                              {item.quantity}
                            </td>
                            <td style={{ fontWeight: "bold", fontSize: 16 }}>
                              {Math.round(item.total)}
                            </td>
                          </tr>
                        </tbody>
                      ))}
                      <tfoot className="bg-white">
                        <tr>
                          <th
                            scope="col"
                            colSpan="4"
                            style={{ fontWeight: "bold", fontSize: 16 }}
                          >
                            Total No. of Books
                          </th>
                          <th
                            scope="col"
                            style={{ fontWeight: "bold", fontSize: 16 }}
                          >
                            {billData?.totalBooks}
                          </th>
                          <th
                            scope="col"
                            style={{ fontWeight: "bold", fontSize: 16 }}
                          >
                            {"₹ " + Math.round(billData?.netAmount)}
                          </th>
                        </tr>
                      </tfoot>
                    </Table>
                  </Row>
                  {billData?.invoiceInfo?.map((item, index) => (
                    <>
                      <Row className="border border-darker pt-1 pb-1">
                        <Col xs="7">
                          <h3 style={{ fontWeight: "bold", fontSize: 16 }}>
                            Customer GST No. :{" "}
                            {billData?.billingInfo[0]?.customerGST}
                          </h3>
                        </Col>
                        <Col>
                          <Row>
                            <Col style={{ fontWeight: "bold", fontSize: 16 }}>
                              POSTAGE & COURIER :
                            </Col>
                            <Col style={{ fontWeight: "bold", fontSize: 16 }}>
                              {item?.courier===""?0:item?.courier}
                            </Col>
                          </Row>
                          <Row>
                            <Col style={{ fontWeight: "bold", fontSize: 16 }}>
                              FREIGHT COST :
                            </Col>
                            <Col style={{ fontWeight: "bold", fontSize: 16 }}>
                              {item?.freightCost}
                            </Col>
                          </Row>
                          <Row>
                            <Col style={{ fontWeight: "bold", fontSize: 16 }}>
                              Round Off :
                            </Col>
                            <Col style={{ fontWeight: "bold", fontSize: 16 }}>
                              {item?.roundOff}
                            </Col>
                          </Row>
                          <hr />
                          <Row className="mt-2">
                            <Col style={{ fontWeight: "bold", fontSize: 16 }}>
                              <h4>TOTAL(Rs.) :</h4>
                            </Col>
                            <Col style={{ fontWeight: "bold", fontSize: 16 }}>
                              <h4>
                                <CurrencyFormat
                                  value={
                                    Math.round(billData?.netAmount) +
                                    parseInt(item.courier===""?0:item.courier) +
                                    parseInt(item.freightCost)
                                  }
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"₹"}
                                />
                              </h4>
                            </Col>
                          </Row>
                          <Row className="mt-2">
                            <Col style={{ fontWeight: "bold", fontSize: 16 }}>
                              <h4>Amount in words :</h4>
                            </Col>
                            <Col style={{ fontWeight: "bold", fontSize: 16 }}>
                              <h4>
                                {toWords.convert(
                                  parseInt(item.courier===""?0:item.courier) +
                                    parseInt(item.freightCost) +
                                    item.roundOff +
                                    billData?.netAmount,
                                  { currency: true }
                                )}
                              </h4>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="border border-darker justify-content-center">
                        <h2>Amount in words :</h2>
                        <h2 className="pl-4">
                          {toWords.convert(
                            parseInt(item.courier===""?0:item.courier) +
                              parseInt(item.freightCost) +
                              item.roundOff +
                              Math.round(billData?.netAmount),
                            { currency: true }
                          )}
                        </h2>
                      </Row>
                    </>
                  ))}
                </CardBody>
                <CardFooter className="mt--4">
                  <Row>
                    <Col xs="8">
                      <h2>Terms and Conditions</h2>
                      <div>
                        <Row>1. Goods once sold are not returnable</Row>
                        <Row>
                          2. Our responsibility ceases as soon as goods leave
                          our premises.
                        </Row>
                        <Row>3. Subject to DELHI jurisdiction only</Row>
                        <Row>
                          4. All payments to be made to YEP Education Private
                          Limited.
                        </Row>
                      </div>
                    </Col>
                    <Col>
                      <h3>For YEP Education Private Limited</h3>
                      <div>
                        <Col>
                          <img
                            alt="..."
                            className="rounded-circle"
                            src={
                              require("../../assets/img/signature (1).jpg")
                                .default
                            }
                            style={{ width: 180, height: 120 }}
                          />
                          <h3>Authorised Signatory</h3>
                        </Col>
                      </div>
                    </Col>
                  </Row>
                </CardFooter>
                <Row className="border border-darker justify-content-center m-1">
                  <Col md="2">Cash/Cheque</Col>
                  <Col md="3">
                    <Row>
                      <Col>Beneficiary Name:</Col>
                      <Col>YEP EDUCATION PVT LTD</Col>
                    </Row>
                  </Col>
                  {/* <Col>
                    ICICI Bank Ltd. Vishal Encalve, New Delhi 110027, A/c No.
                    630105007738
                  </Col>
                  <Col md="2">IFSC Code: ICIC0006301</Col> */}
                  <Col>
                    HDFC Bank Ltd. A/c No.
                    50200093200991
                  </Col>
                  <Col md="2">IFSC Code: HDFC0000933</Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Invoice;
