import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Switch, Redirect, useHistory } from "react-router-dom";
import { firebaseAuth, firestore } from "./config/Fire";
import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import Invoice from "./views/examples/Invoice";
import Invoice2 from "./views/examples/Invoice2";
import Invoice3 from "./views/examples/Invoice3";
import ViewNotes from "./views/examples/ViewNotes";
import ViewNotes2 from "./views/examples/ViewNotes2";
import DealerProfile from "./views/examples/DealerProfile";
import EditBilling from "./views/forms/EditBilling";
import EditDealer from "./views/forms/EditDealer";
import EditCreditNotes from "./views/forms/EditCreditNotes";

const App = () => {
    const history = useHistory()
    useEffect(() => {
        firebaseAuth
        .onAuthStateChanged((user) => {
            if(user) {
                history.push("/admin");
            }
            else {
                history.push("/auth");
            }
        })
    }, []);
    return (
        <Switch>
            <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
            <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
            <Route path="/invoice/:id" render={(props) => <Invoice {...props} />} />
            <Route path="/invoice2/:id" render={(props) => <Invoice2 {...props} />} />
            <Route path="/invoice3/:id" render={(props) => <Invoice3 {...props} />} />
            <Route path="/view-note/:id" render={(props) => <ViewNotes {...props} />} />
            <Route path="/view-note2/:id" render={(props) => <ViewNotes2 {...props} />} />
            <Route path="/editbilling/:id" render={(props) => <EditBilling {...props} />} />
            <Route path="/edit-dealer/:id" render={(props) => <EditDealer {...props} />} />
            <Route path="/edit-credit-note/:id" render={(props) => <EditCreditNotes {...props} />} />
            <Route path="/dealerbilling/:id" render={(props) => <DealerProfile {...props} />} />
            <Redirect from="" to="/admin/index" />
        </Switch>
    )
}

export default App
