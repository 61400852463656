import React, { useState, useEffect } from "react";
import {
  Button,
  Badge,
  Card,
  CardHeader,
  CardFooter,
  CardBody,
  DropdownItem,
  DropdownMenu,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  UncontrolledTooltip,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import AddBillingHeader from "components/Headers/AddBillingHeader";
import { TextField, MenuItem, Select, FormControl } from "@material-ui/core";
import { firebaseAuth, firestore } from "../../config/Fire";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
const products = [
  {
    name: "Books",
    sub_products: [
      { name: "It Kode", values: ["It Kode 1", "It Kode 2"] },
      { name: "Krypto" },
      { name: "Cyber Navigation" },
      { name: "Analog" },
      { name: "Neuro kode" },
      { name: "CyberAI" },
    ],
  },
  {
    name: "Gifts",
    sub_products: [
      { name: "Gift Books" },
      { name: "Sarees" },
      { name: "Photo Frame" },
      { name: "Diary Set" },
    ],
  },
];
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));
const AddBilling = () => {
  const history = useHistory();
  const classes = useStyles();
  const [dealers, setdealers] = useState([]);
  const [books, setbooks] = useState([]);
  const [netAmount, setnetAmount] = useState(0);
  const [totalBooks, settotalBooks] = useState(0);
  const [billings, setbillings] = useState([]);
  const [inv, setinv] = useState(0);
  const [productValue, setProductValue] = useState("Select");
  const [types, setTypes] = useState("0");
  const [subType, setSubType] = useState("0");
  const [fields, setFields] = useState([]);
  const [newBooks, setNewBooks] = useState([]);
  const [newGifts, setNewGifts] = useState([]);
  const [subProducts, setSubProducts] = useState([]);
  var today = new Date();
  useEffect(() => {
    firestore
      .collection("billings")
      .orderBy("timeStamp", "desc")
      .onSnapshot((querrySnapshots) => {
        const billings = [];
        querrySnapshots.forEach((documentSnapshots) => {
          billings.push({
            ...documentSnapshots.data(),
            key: documentSnapshots.id,
          });
        });
        setbillings(billings);
        setinv(billings.length + 1);
      });
  }, []);
  const [billingInfo, setbillingInfo] = useState([
    {
      billTo: "",
      address: "",
      contactPerson: "",
      phoneNo: "",
      customerGST: "",
    },
  ]);
  const [shippingInfo, setshippingInfo] = useState([
    {
      shipTo: "",
      address: "",
      contactNo: "",
      grNo: "",
      weight: "",
    },
  ]);
  const [invoiceInfo, setinvoiceInfo] = useState([
    {
      invoiceNo: 0,
      invoiceDate: "",
      dated:
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate(),
      transporter: "",
      courier: 0,
      freightCost: 0,
      roundOff: 0,
      noOfBoxes: 0,
    },
  ]);
  const [productInfo, setproductInfo] = useState([
    {
      particulars: "",
      isbn: 0,
      quantity: 0,
      price: 0,
      discount: 0,
      amount: 0,
      total: 0,
    },
  ]);

  useEffect(() => {
    firestore.collection("dealers").onSnapshot((querrySnapshots) => {
      const dealers = [];
      querrySnapshots.forEach((documentSnapshots) => {
        dealers.push({
          ...documentSnapshots.data(),
          key: documentSnapshots.id,
        });
      });
      setdealers(dealers);
      const billing = [...billingInfo];
      const shipping = [...shippingInfo];
      billing[0].billTo = dealers[0].basicInfo[0].name;
      billing[0].address = dealers[0].billingAddress[0].address;
      billing[0].contactPerson = dealers[0].basicInfo[0].primaryContactName;
      billing[0].phoneNo = dealers[0].basicInfo[0].phoneNo;
      // billing[0].customerGST =
      //   dealers[0].basicInfo[0].customerGST === undefined
      //     ? ""
      //     : dealers[0].basicInfo[0].customerGST;
      billing[0].customerGST = dealers[0].basicInfo[0].customerGST;
      shipping[0].shipTo = dealers[0].shippingAddress[0].name;
      shipping[0].address = dealers[0].shippingAddress[0].address;
      shipping[0].contactNo = dealers[0].shippingAddress[0].phone;
      setbillingInfo(billing);
      setshippingInfo(shipping);
    });
  }, []);
  useEffect(() => {
    firestore
      .collection("new_books")
      .orderBy("bookInfo.name", "asc")
      .onSnapshot((querrySnapshots) => {
        const books = [];
        querrySnapshots.forEach((documentSnapshots) => {
          books.push({
            ...documentSnapshots.data(),
            key: documentSnapshots.id,
          });
        });
        setbooks(books);
        setNewBooks(books);
        // const product = [...productInfo];
        // product[0].particulars = books[0].bookInfo.name;
        // product[0].price = books[0].bookInfo.price;
        // product[0].discount = books[0].bookInfo.discount;
        // product[0].total =
        //   parseInt(product[0].price) * parseInt(product[0].quantity) -
        //   (parseInt(product[0].price) *
        //     parseInt(product[0].quantity) *
        //     parseInt(product[0].discount)) /
        //     100;
        // setproductInfo(product);
      });
    firestore.collection("gifts").onSnapshot((querrySnapshots) => {
      const gifts = [];
      querrySnapshots.forEach((documentSnapshots) => {
        gifts.push({
          ...documentSnapshots.data(),
          key: documentSnapshots.id,
        });
      });
      console.log("gifts: ", gifts);
      setNewGifts(gifts);
      // setNewBooks(books)
      // const product = [...productInfo];
      // product[0].particulars = books[0].bookInfo.name;
      // product[0].price = books[0].bookInfo.price;
      // product[0].discount = books[0].bookInfo.discount;
      // product[0].total =
      //   parseInt(product[0].price) * parseInt(product[0].quantity) -
      //   (parseInt(product[0].price) *
      //     parseInt(product[0].quantity) *
      //     parseInt(product[0].discount)) /
      //     100;
      // setproductInfo(product);
    });
  }, []);

  const handleAddFields = () => {
    const values = [...productInfo];
    values.push({
      particulars: "",
      isbn: 0,
      quantity: 0,
      price: 0,
      discount: 0,
      amount: 0,
      total: 0,
    });
    let newFields = [...fields];
    // {
    //   value:subProducts[0],
    //   filteredProducts,
    //   quantity:1,
    //   total:types === 'Books' ? subProducts[0].bookInfo.price :subProducts[0].giftInfo.price
    // }
    newFields.push({
      value: subProducts[0],
      filteredProducts: subProducts,
      quantity: 1,
      total:
        types === "Books"
          ? subProducts[0].bookInfo.price
          : subProducts[0].giftInfo.price,
    });
    setFields(newFields);
    setproductInfo(values);
  };

  const handleRemoveFields = (index) => {
    const values = [...productInfo];
    values.splice(index, 1);
    setproductInfo(values);
  };

  const handleProductInputChange = (index, event) => {
    const values = [...productInfo];
    if (event.target.name === "particulars") {
      const product = [...productInfo];
      product[index].total = 0;
      for (var i = 0; i < books.length; i++) {
        if (books[i].bookInfo.name === event.target.value) {
          product[index].particulars = books[i].bookInfo.name;
          product[index].price = books[i].bookInfo.price;
          product[index].discount = books[i].bookInfo.discount;
          product[index].total =
            parseInt(product[index].price) * parseInt(product[index].quantity) -
            (parseInt(product[index].price) *
              parseInt(product[index].quantity) *
              parseInt(product[index].discount)) /
            100;
          setproductInfo(product);
        }
      }
    } else if (event.target.name === "isbn") {
      values[index].isbn = event.target.value;
    } else if (event.target.name === "quantity") {
      values[index].quantity = event.target.value;
      values[index].total =
        parseInt(values[index].price) * parseInt(values[index].quantity) -
        (parseInt(values[index].price) *
          parseInt(values[index].quantity) *
          parseInt(values[index].discount)) /
        100;
    } else if (event.target.name === "price") {
      values[index].price = event.target.value;
      values[index].total =
        parseInt(values[index].price) * parseInt(values[index].quantity) -
        (parseInt(values[index].price) *
          parseInt(values[index].quantity) *
          parseInt(values[index].discount)) /
        100;
    } else if (event.target.name === "discount") {
      values[index].discount = event.target.value;
      values[index].total =
        parseInt(values[index].price) * parseInt(values[index].quantity) -
        (parseInt(values[index].price) *
          parseInt(values[index].quantity) *
          parseInt(values[index].discount)) /
        100;
    } else {
      values[index].amount = event.target.value;
    }
    setproductInfo(values);
    var temp1 = 0;
    var temp2 = 0;
    for (var i = 0; i < productInfo.length; i++) {
      temp1 = temp1 + productInfo[i].total;
      temp2 = temp2 + parseInt(productInfo[i].quantity);
    }
    setnetAmount(temp1);
    settotalBooks(temp2);
  };
  const [invoiceNO, setINvoice] = useState();
  const mapper = {
    Books: ["IT", "Cyber"],
    Gifts: ["Sarees", "3 in", "Positive"],
  };
  const handleBillingInputChange = (event) => {
    const values = [...billingInfo];
    if (event.target.name === "billTo") {
      // debugger;
      values[0].billTo = event.target.value;
      const billing = [...billingInfo];
      const shipping = [...shippingInfo];
      for (var i = 0; i < dealers.length; i++) {
        if (dealers[i].basicInfo[0].name === event.target.value) {
          billing[0].address = dealers[i].billingAddress[0].address;
          billing[0].contactPerson = dealers[i].basicInfo[0].primaryContactName;
          billing[0].phoneNo = dealers[i].basicInfo[0].phoneNo;
          // billing[0].customerGST =
          //   dealers[i].basicInfo[0].customerGST === undefined
          //     ? ""
          //     : dealers[0].basicInfo[0].customerGST;
          billing[0].customerGST = dealers[i].basicInfo[0].customerGST;
          shipping[0].shipTo = dealers[i].shippingAddress[0].name;
          shipping[0].address = dealers[i].shippingAddress[0].address;
          shipping[0].contactNo = dealers[i].shippingAddress[0].phone;
          setbillingInfo(billing);
          setshippingInfo(shipping);
        }
      }
    } else if (event.target.name === "address") {
      values[0].address = event.target.value;
    } else if (event.target.name === "contactPerson") {
      values[0].contactPerson = event.target.value;
    } else if (event.target.name === "customerGST") {
      values[0].customerGST = event.target.value;
    } else {
      values[0].phoneNo = event.target.value;
    }

    setbillingInfo(values);
  };
  const handleShippingInputChange = (event) => {
    const values = [...shippingInfo];
    if (event.target.name === "shipTo") {
      values[0].shipTo = event.target.value;
    } else if (event.target.name === "address") {
      values[0].address = event.target.value;
    } else if (event.target.name === "contactNo") {
      values[0].contactNo = event.target.value;
    } else if (event.target.name === "grNo") {
      values[0].grNo = event.target.value;
    } else {
      values[0].weight = event.target.value;
    }

    setshippingInfo(values);
  };
  function containsAny(str, substrings) {
    for (var i = 0; i != substrings.length; i++) {
      var substring = substrings[i];
      if (str.indexOf(substring) != -1) {
        return substring;
      }
    }
    return null;
  }
  const handleInvoiceInputChange = (event) => {
    const values = [...invoiceInfo];
    console.log(values, "++++++++++++++++");
    if (event.target.name === "invoiceNo") {
      values[0].invoiceNo = event.target.value;
    } else if (event.target.name === "invoiceDate") {
      values[0].invoiceDate = event.target.value;
    }
    // else if (event.target.name === "custGSTNo") {
    //   values[0].custGSTNo = event.target.value;
    // }
    else if (event.target.name === "dated") {
      values[0].dated = event.target.value;
    } else if (event.target.name === "transporter") {
      values[0].transporter = event.target.value;
    } else if (event.target.name === "courier") {
      values[0].courier = event.target.value;
    } else if (event.target.name === "freightCost") {
      values[0].freightCost = event.target.value;
    } else if (event.target.name === "roundOff") {
      values[0].roundOff = event.target.value;
    } else {
      values[0].noOfBoxes = event.target.value;
    }

    setinvoiceInfo(values);
  };

  const handleCopy = () => {
    const values = [...shippingInfo];
    console.log(billingInfo);
    values[0].shipTo = billingInfo[0].billTo;
    values[0].address = billingInfo[0].address;
    values[0].contactNo = billingInfo[0].phoneNo;
    setshippingInfo(values);
    console.log(shippingInfo[0]);
  };
  

  const handleSubmit = (e) => {
    const values = [...invoiceInfo];
    // console.log("***",invoiceInfo);
    const date = values[0]?.dated?.split("-"); //['2024','09','8']
    values[0].dated = date[2] + "-" + date[1] + "-" + date[0];
    const temp = billings[0].invoiceInfo[0].invoiceNo.toString().split("/");
    // console.log("777",temp)
    // console.log("bb",billings)
    let nextInv;
    let bilinfos = billings.filter((item) => {
      if (item.invoiceInfo[0].invoiceNo.toString().length >= 11) return true;
      else return false;
    });
    const sortByDate = arr => {
      const sorter = (a, b) => {
        let x = parseInt(a.invoiceInfo[0].invoiceNo.toString().split("/")[1]);
        let y = parseInt(b.invoiceInfo[0].invoiceNo.toString().split("/")[1]);
        return x - y;
      }
      arr.sort(sorter);
    };
    sortByDate(bilinfos);
    // console.log("bill", bilinfos)
// const isAprilFirst = today.getMonth() === 4 && today.getDate() === 1;

    let lastInvoiceDate = billings[0].invoiceInfo[0].dated;
    let todayDate = values[0]?.dated;
    
    // let lastInvoiceYear = moment(lastInvoiceDate).year();
    // Financial year starts from April 1st
    // let lastInvoiceStartRange = moment({ month: 3, day: 1, year: lastInvoiceYear }).format('YYYY-MM-DD');
    let lastInvoiceStartRange = `01-04-${moment(lastInvoiceDate).month() > 3 ? moment(lastInvoiceDate).year() : moment(lastInvoiceDate).subtract(1, "years").year()}`
    // Financial year ends on March 31st
    // let lastInvoiceEndRange = moment({ month: 2, day: 31, year: lastInvoiceYear + 1 }).format('YYYY-MM-DD');
    let lastInvoiceEndRange = `31-03-${moment(lastInvoiceDate).month() > 3 ? moment(lastInvoiceDate).add(1, "years").year() : moment(lastInvoiceDate).year()}`
    // let todayYear = moment(todayDate).year();
    let todayDateStartRange = `01-04-${moment(todayDate).month() > 3 ? moment(todayDate).year() : moment(todayDate).subtract(1, "years").year()}`
    // let todayDateStartRange = moment({ month: 3, day: 1, year: todayYear }).format('YYYY-MM-DD');
    let todayDateEndRange = `31-03-${moment(todayDate).month() > 3 ? moment(todayDate).add(1, "years").year() : moment(todayDate).year()}`
    // let todayDateEndRange = moment({ month: 2, day: 31, year: todayYear + 1 }).format('YYYY-MM-DD');
    
    if (lastInvoiceStartRange !== todayDateEndRange && lastInvoiceEndRange !== todayDateStartRange) {
      console.log("inside.....")
      nextInv = "001"
    }
    // if (moment(todayDate).isBefore(lastInvoiceStartRange) || moment(todayDate).isAfter(lastInvoiceEndRange)) {
    //   console.log("Inside reset...");
    //   nextInv = "001"; // Reset invoice number
    // }
    else {
    let lastInvoiceNumber = parseInt(billings[0].invoiceInfo[0].invoiceNo.split('/')[1]);
    console.log("lastInvoiceNumber:", lastInvoiceNumber);
    if (isNaN(lastInvoiceNumber)) {
      lastInvoiceNumber = 0; // Default to 0 if the last invoice number is not a valid number
    }
    // let lastInvoiceYearDiffers = lastInvoiceYear !== todayYear;
    // nextInv = lastInvoiceYearDiffers ? 1 : lastInvoiceNumber + 1; // Reset if different, increment otherwise
  
    // nextInv = lastInvoiceNumber + 1; // Increment invoice number
    // console.log("nextInv before padding:", nextInv);
}
    nextInv = parseInt(temp[1]) + 1;
    // else nextInv = temp[1].length < 3 ? '00' : '' + (parseInt(temp[1]) + 1);
    // }
    // console.log("0",lastInvoiceDate,"22",todayDate)
    // console.log("1",lastInvoiceStartRange)
    // console.log("2",lastInvoiceEndRange)
    // console.log("3",todayDateStartRange)
    // console.log("4",todayDateEndRange)
      
    // nextInv = parseInt(bilinfos.reverse()[0].invoiceInfo[0].invoiceNo.toString().split("/")[1])+1;
    // console.log("+++1", nextInv);

    // nextInv = parseInt(temp[1]) + 1;
  //  console.log("nextInv before padding:", nextInv);
    if (nextInv < 10) nextInv = `00${nextInv}`;
    else if (nextInv < 100) nextInv = `0${nextInv}`;
    // else nextInv = nextInv.toString(); // Convert to string if greater than 100
    // console.log("nextInv after padding:", nextInv);
     
    values[0].invoiceNo = `2024-25/${nextInv}`;
    // console.log("values:", values);
    // values[0].invoiceNo = `${moment().format('YYYY')}-${(moment().format('YYYY') + 1).toString().slice(-2)}/${nextInv}`;
    // Set the invoice number
      // values[0].invoiceNo = `${todayYear}-${todayYear + 1}/${nextInv}`;

  //   const currentYear = new Date().getFullYear();
  //   if (currentYear !== parseInt(values[0].invoiceNo.split('/')[0])) {
  //     // If the year has changed, reset the invoice number
  //     values[0].invoiceNo = `${currentYear}-${currentYear + 1}/001`;
  // } else {
  //     // If the year is the same, continue incrementing the invoice number
  //     let nextInv = parseInt(temp[1]) + 1;
  //     if (nextInv < 10) nextInv = `00${nextInv}`;
  //     else if (nextInv < 100) nextInv = `0${nextInv}`;
  
  //     values[0].invoiceNo = `${currentYear}-${currentYear + 1}/${nextInv}`;
  // }

    setinvoiceInfo(values);
    let netAmount = subProducts.reduce(function (sum, current) {
      return sum + parseFloat(current.total);
    }, 0);
    let calcTotalBooks = 0;
    let selectedProducts = subProducts
      .filter((sub_product) => {
        return sub_product.quantity && sub_product.quantity != 0;
      })
      .map((sub_product) => {
        if (sub_product.quantity) {
          calcTotalBooks = calcTotalBooks + parseInt(sub_product.quantity);
        }
        return {
          particulars:
            types === "Books"
              ? sub_product.bookInfo.name
              : sub_product.giftInfo.name,
          amount:
            types === "Books"
              ? sub_product.bookInfo.price
              : sub_product.giftInfo.price,
          price:
            types === "Books"
              ? sub_product.bookInfo.price
              : sub_product.giftInfo.price,
          total: sub_product.total,
          isbn: 0,
          quantity: sub_product.quantity ? sub_product.quantity : 0,
          discount: sub_product.discount ? sub_product.discount : 0,
        };
      });
    firestore
      .collection("billings")
      .add({
        billingInfo,
        shippingInfo,
        invoiceInfo,
        productInfo: selectedProducts,
        netAmount,
        totalBooks: calcTotalBooks,
        // invoiceNO,
        timeStamp: Date.now(),
        billTo: billingInfo[0].billTo,
        type: types,
        subType,
      })
      .catch((err) => {
        console.log(err);
      })
      .then(() => {
        history.push("/admin/billings");
      });
  };
  const getTotal = () => {
    var temp = 0;
    for (var i = 0; i < productInfo.length; i++) {
      temp = temp + productInfo[i].total;
    }
    setnetAmount(temp);
  };
  return (
    <>
      <AddBillingHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Please fill the required details</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <h6 className="heading-small text-muted mb-4">
                    Bill information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          {/* <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            INVOICE No
                          </label>
                          <TextField
                          fullWidth
                          size="small"
                          style={{backgroundColor:"#fff",color:"#8898aa"}}
                          placeholder="Invoice No"
                          name="invoice"
                          value={invoiceNO}
                          onChange={(e) => setINvoice(e.target.value)
                            }
                          helperText="Please fill this out"
                          variant="outlined"
                        >  
                        </TextField> */}
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Bill To
                          </label>
                          <TextField
                            id="outlined-select-currency-native"
                            fullWidth
                            size="small"
                            style={{
                              backgroundColor: "#fff",
                              color: "#8898aa",
                            }}
                            select
                            placeholder="Bill To"
                            name="billTo"
                            value={billingInfo[0].billTo}
                            onChange={(e) => handleBillingInputChange(e)}
                            onch
                            SelectProps={{
                              native: true,
                            }}
                            helperText="Please fill this out"
                            variant="outlined"
                          >
                            {dealers.map((option) => (
                              <option
                                key={option.key}
                                value={option.basicInfo[0].name}
                              >
                                {option.basicInfo[0].name}
                              </option>
                            ))}
                          </TextField>
                          {/* <Input
                            className="form-control-alternative"
                            id="input-username"
                            required
                            name="billTo"
                            value={billingInfo.billTo}
                            onChange={(e) => handleBillingInputChange(e)}
                            placeholder="Bill To"
                            type="text"
                          /> */}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Address
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-email"
                            name="address"
                            value={billingInfo[0].address}
                            onChange={(e) => handleBillingInputChange(e)}
                            placeholder="Address"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Contact Person
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            name="contactPerson"
                            value={billingInfo[0].contactPerson}
                            onChange={(e) => handleBillingInputChange(e)}
                            placeholder="Contact Person"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Phone No./ Mob. No.
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-email"
                            name="phoneNo"
                            value={billingInfo[0].phoneNo}
                            onChange={(e) => handleBillingInputChange(e)}
                            placeholder="Phone Number"
                            type="number"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Customer GST No.
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-first-name"
                            name="customerGST"
                            value={billingInfo[0].customerGST}
                            onChange={(e) => handleBillingInputChange(e)}
                            placeholder="Cust GST Number"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <Row>
                    <Col xs="10">
                      <h6 className="heading-small text-muted mb-4">
                        Shipping information
                      </h6>
                    </Col>
                    <Col>
                      <Button
                        color="info"
                        onClick={() => handleCopy()}
                        size="sm"
                      >
                        Same as Billing
                      </Button>
                    </Col>
                  </Row>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Ship To
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-first-name"
                            name="shipTo"
                            value={shippingInfo[0].shipTo}
                            onChange={(e) => handleShippingInputChange(e)}
                            placeholder="Ship To"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Shipping Address
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-last-name"
                            name="address"
                            value={shippingInfo[0].address}
                            onChange={(e) => handleShippingInputChange(e)}
                            placeholder="Address"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Contact No.
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-first-name"
                            name="contactNo"
                            value={shippingInfo[0].contactNo}
                            onChange={(e) => handleShippingInputChange(e)}
                            placeholder="Contact No"
                            type="number"
                          />
                        </FormGroup>
                      </Col>
                      {/* <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            G.R. No
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-last-name"
                            name="grNo"
                            value={shippingInfo[0].grNo}
                            onChange={(e) => handleShippingInputChange(e)}
                            placeholder="G R. No."
                            type="text"
                          />
                        </FormGroup>
                      </Col> */}
                    </Row>
                    {/* <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Weight
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-first-name"
                            name="weight"
                            value={shippingInfo[0].weight}
                            onChange={(e) => handleShippingInputChange(e)}
                            placeholder="Weight"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row> */}
                  </div>
                  <h6 className="heading-small text-muted mb-4">
                    Invoice information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Dated
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-last-name"
                            name="dated"
                            value={invoiceInfo[0].dated}
                            onChange={(e) => handleInvoiceInputChange(e)}
                            placeholder="Dated"
                            type="date"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            POSTAGE & COURIER
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-last-name"
                            name="courier"
                            value={invoiceInfo[0].courier}
                            onChange={(e) => handleInvoiceInputChange(e)}
                            placeholder="POSTAGE & COURIER"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      {/* <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Transporter
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-first-name"
                            name="transporter"
                            value={invoiceInfo[0].transporter}
                            onChange={(e) => handleInvoiceInputChange(e)}
                            placeholder="Transporter"
                            type="text"
                          />
                        </FormGroup>
                      </Col> */}
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            FREIGHT COST
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-first-name"
                            name="freightCost"
                            value={invoiceInfo[0].freightCost}
                            onChange={(e) => handleInvoiceInputChange(e)}
                            placeholder="FREIGHT COST"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Round Off
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-first-name"
                            name="roundOff"
                            value={invoiceInfo[0].roundOff}
                            onChange={(e) => handleInvoiceInputChange(e)}
                            placeholder="Round Off"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  <Row>
                    <div className="col">
                      <Card className="shadow">
                        <CardHeader className="border-0">
                          <Row>
                            <Col xs="10">
                              <h3 className="mb-0">
                                Product Information &emsp;
                                <select
                                  style={{
                                    backgroundColor: "#fff",
                                    color: "#8898aa",
                                    borderRadius: "4px",
                                    paddingTop: "6px",
                                    paddingBottom: "6px",
                                    paddingLeft: "12px",
                                    paddingRight: "12px",
                                  }}
                                  onChange={(e) => {
                                    setTypes(e.target.value);
                                    setSubType("");
                                    setFields([]);
                                  }}
                                  value={types}
                                >
                                  <option
                                    selected="true"
                                    style={{ display: "none" }}
                                  >
                                    Select
                                  </option>
                                  {products.map((product) => {
                                    return (
                                      <option value={product.value}>
                                        {product.name}
                                      </option>
                                    );
                                  })}
                                </select>{" "}
                                &emsp;
                                <select
                                  style={{
                                    backgroundColor: "#fff",
                                    color: "#8898aa",
                                    borderRadius: "4px",
                                    paddingTop: "6px",
                                    paddingBottom: "6px",
                                    paddingLeft: "12px",
                                    paddingRight: "12px",
                                  }}
                                  onChange={(e) => {
                                    setSubType(e.target.value);
                                    // let newFields = [...fields];
                                    let filteredProducts = [];
                                    setSubProducts([]);
                                   
                                    if (types === "Books") {
                                      //  XProducts = newBooks.filter(
                                      //   (book) => {
                                      //     console.log(
                                      //       "book: ",
                                      //       book,
                                      //       "???",
                                      //       e.target.value
                                      //     );
                                      //     return (
                                      //       book.bookType === e.target.value
                                      //     );
                                      //   }
                                      // );
                                      //  XProducts = newBooks.filter((book) => book.bookType === e.target.value);
                                      let XProducts = newBooks.filter((book) => {
                                        // console.log('book', book);
                                        // console.log('event', e.target.value);
                                       return book.bookType === e.target.value;
                                    });
                                      filteredProducts = XProducts.map(
                                        (product) => {
                                          console.log(
                                            "product: ",
                                            product,
                                            ":::::::::::::"
                                          );
                                          return {
                                            ...product,
                                            quantity: 0,
                                            total: 0,
                                            price: product.bookInfo.price,
                                            discount: product.bookInfo.discount,
                                          };
                                        }
                                      );
                                    } else {
                                      filteredProducts = newGifts
                                        .filter((gift) => {
                                          console.log("gift: ", gift);
                                          return (
                                            gift.giftType === e.target.value
                                          );
                                        })
                                        .map((product) => {
                                          return {
                                            ...product,
                                            quantity: 0,
                                            total: 0,
                                            price: product.giftInfo.price,
                                            discount: product.giftInfo.discount,
                                          };
                                        });
                                    }
                                    setSubProducts(filteredProducts);
                                    // console.log('newFields: ', newFields);
                                    // if(filteredProducts && filteredProducts[0]){
                                    //   let price = types === 'Books' ? filteredProducts[0].bookInfo.price :filteredProducts[0].giftInfo.price

                                    //   // setFields([{
                                    //   //   value:filteredProducts[0],
                                    //   //   filteredProducts,
                                    //   //   quantity:1,
                                    //   //   total: price * ( (100-25) / 100 )
                                    //   // }])
                                    //   console.log({
                                    //     value:filteredProducts[0],
                                    //     filteredProducts,
                                    //     quantity:1,
                                    //     total: price - (price - .25)
                                    //   },'>>>>>>>>>field>>>>>>>>>>>>')
                                    // }

                                    // setFields(newFields);
                                  }}
                                  value={subType}
                                  disabled={types === "0"}
                                >
                                  <option
                                    selected="true"
                                    style={{ display: "none" }}
                                    value={"0"}
                                  >
                                    Select
                                  </option>

                                  {types === "Books" && (
                                    <>
                                      {products[0].sub_products.map(
                                        (sub_product) => {
                                          return (
                                            <option value={sub_product.name}>
                                              {sub_product.name}
                                            </option>
                                          );
                                        }
                                      )}
                                    </>
                                  )}

                                  {types === "Gifts" && (
                                    <>
                                      {products[1].sub_products.map(
                                        (sub_product) => {
                                          return (
                                            <option value={sub_product.name}>
                                              {sub_product.name}
                                            </option>
                                          );
                                        }
                                      )}
                                    </>
                                  )}
                                </select>
                              </h3>
                            </Col>
                            <Col xs="">
                              <Button
                                color="primary"
                                href="#pablo"
                                onClick={() => handleAddFields()}
                                size="sm"
                              >
                                Add New
                              </Button>
                            </Col>
                          </Row>
                        </CardHeader>

                        {types &&
                          subType &&
                          subProducts &&
                          subProducts.length > 0 && (
                            <Table
                              className="align-items-center table-flush"
                              responsive
                            >
                              <thead className="thead-light">
                                <tr>
                                  <th scope="col">Id</th>
                                  <th scope="col">{types} Name</th>
                                  {/* <th scope="col">ISBN</th> */}
                                  <th scope="col">Price</th>
                                  <th scope="col"> Fixed Disc. %</th>
                                  <th scope="col">Quantity</th>
                                  <th scope="col">Total</th>
                                  <th scope="col">Options</th>
                                </tr>
                              </thead>
                              {subProducts.map((sub_product, index) => {
                                console.log(
                                  "sub_product: ",
                                  sub_product,
                                  ">>>>>>"
                                );
                                return (
                                  <tbody>
                                    <tr>
                                      <th scope="row">
                                        <Media className="align-items-center">
                                          <span className="mb-0 text-sm">
                                            {index + 1}
                                          </span>
                                        </Media>
                                      </th>
                                      <td>
                                        <TextField
                                          // id="outlined-read-only-input"
                                          // label="Read Only"
                                          // defaultValue={types === 'Books' ? sub_product.bookInfo.name : sub_product.giftInfo.name}
                                          value={
                                            types === "Books"
                                              ? sub_product.bookInfo.name
                                              : sub_product.giftInfo.name
                                          }
                                          InputProps={{
                                            readOnly: true,
                                          }}
                                          style={{ width: "max-content" }}
                                          variant="outlined"
                                          size="small"
                                        />
                                      </td>
                                      <td>
                                        <Input
                                          className="form-control-alternative"
                                          id="price"
                                          style={{ minWidth: "100px" }}
                                          placeholder="Price"
                                          name="price"
                                          type="number"
                                          value={
                                            types === "Books"
                                              ? sub_product.bookInfo.price
                                              : sub_product.giftInfo.price
                                          }
                                          onChange={(event) =>
                                            handleProductInputChange(
                                              index,
                                              event
                                            )
                                          }
                                        />
                                      </td>
                                      <td>
                                        <Input
                                          className="form-control-alternative"
                                          id="discount"
                                          style={{ minWidth: "100px" }}
                                          placeholder="discount"
                                          name="discount"
                                          type="number"
                                          // value={
                                          //   types === "Books"
                                          //     ? sub_product.bookInfo.discount
                                          //     : sub_product.giftInfo.discount
                                          // }
                                          value={sub_product.discount}
                                          onChange={(event) => {
                                            let newProducts = [...subProducts];
                                            let totalPrice =
                                              sub_product.quantity *
                                              sub_product.price;
                                            let priceAfterDiscount =
                                              totalPrice *
                                              ((100 - event.target.value) /
                                                100);
                                            newProducts[index] = {
                                              ...newProducts[index],
                                              discount: event.target.value,
                                              // quantity:event.target.value,
                                              total: priceAfterDiscount,
                                            };
                                            setSubProducts(newProducts);
                                            // handleProductInputChange(index, event)
                                          }}
                                        />
                                      </td>
                                      <td>
                                        <Input
                                          className="form-control-alternative"
                                          id="quantity"
                                          style={{ minWidth: "100px" }}
                                          name="quantity"
                                          placeholder="Quantity"
                                          type="number"
                                          // value={types === 'Books' ? sub_product.bookInfo.quantity : sub_product.giftInfo.quantity}
                                          value={sub_product.quantity}
                                          onChange={(event) => {
                                            let newProducts = [...subProducts];
                                            let enteredQuantity = !!event.target
                                              .value
                                              ? event.target.value
                                              : 0;
                                            let totalPrice =
                                              enteredQuantity *
                                              sub_product.price;
                                            let priceAfterDiscount =
                                              totalPrice *
                                              ((100 - sub_product.discount) /
                                                100);

                                            newProducts[index] = {
                                              ...newProducts[index],
                                              quantity: event.target.value,
                                              total: priceAfterDiscount,
                                            };
                                            setSubProducts(newProducts);
                                            // let newValue = JSON.parse(
                                            //   event.target.value
                                            //   )
                                            // let price = types === "Books"
                                            // ? newValue
                                            // .bookInfo.price
                                            //   : newValue
                                            //       .giftInfo.price
                                            // console.log('price:+++++++++++++++++ ', price);
                                            // console.log(newValue,'????????????????????????');
                                            //       newFields[index] = {
                                            //   ...newFields[index],
                                            //   value: newValue,
                                            //     total:price * ( (100-25) / 100 ),
                                            //   quantity:1
                                            // };
                                            // setFields(newFields);

                                            console.log(
                                              "event: ",
                                              event,
                                              "???",
                                              event.target.value,
                                              "???????"
                                            );
                                          }}
                                        />
                                      </td>
                                      <td>
                                        {/*{(types === "Books"
                                        ? field.value.bookInfo.price
                                        : field.value.giftInfo.price) *
                                      field.quantity}*/}
                                        {parseFloat(sub_product.total).toFixed(
                                          2
                                        )}
                                      </td>
                                      <td className="text-right">
                                        <UncontrolledDropdown>
                                          <DropdownToggle
                                            className="btn-icon-only text-light"
                                            href="#pablo"
                                            role="button"
                                            size="sm"
                                            color=""
                                            onClick={(e) => e.preventDefault()}
                                          >
                                            <i className="fas fa-ellipsis-v" />
                                          </DropdownToggle>
                                          <DropdownMenu
                                            className="dropdown-menu-arrow"
                                            right
                                          >
                                            <DropdownItem
                                              href="#pablo"
                                              onClick={(e) =>
                                                e.preventDefault()
                                              }
                                            >
                                              View Details
                                            </DropdownItem>
                                            <DropdownItem
                                              href="#pablo"
                                              onClick={(e) =>
                                                handleRemoveFields(index)
                                              }
                                            >
                                              Delete
                                            </DropdownItem>
                                            <DropdownItem
                                              href="#pablo"
                                              onClick={(e) =>
                                                e.preventDefault()
                                              }
                                            >
                                              Something else here
                                            </DropdownItem>
                                          </DropdownMenu>
                                        </UncontrolledDropdown>
                                      </td>
                                    </tr>
                                  </tbody>
                                );
                              })}
                            </Table>
                          )}

                        {types && subType && subProducts.length > 0 && (
                          <CardFooter className="py-4">
                            <Row>
                              <Col xs="6" />
                              <Col xs="6">
                                <Row>
                                  <Col>
                                    <h4>POSTAGE & COURIER :</h4>
                                  </Col>
                                  <Col>
                                    <h4>{invoiceInfo[0].courier}</h4>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <h4>FREIGHT COST :</h4>
                                  </Col>
                                  <Col>
                                    <h4>{invoiceInfo[0].freightCost}</h4>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <h4>Round Off :</h4>
                                  </Col>
                                  <Col>
                                    <h4>{invoiceInfo[0].roundOff}</h4>
                                  </Col>
                                </Row>
                                <hr></hr>
                                <Row>
                                  <Col>Net Amount</Col>
                                  <Col>
                                    {Math.round(
                                      subProducts.reduce(function (
                                        sum,
                                        current
                                      ) {
                                        return sum + parseFloat(current.total);
                                      },
                                        0)
                                    )}
                                    {/*{parseInt(invoiceInfo[0].courier) +
                                    parseInt(invoiceInfo[0].freightCost) +
                                    parseInt(invoiceInfo[0].roundOff) +
                                  Math.round(netAmount)}*/}
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </CardFooter>
                        )}
                      </Card>
                    </div>
                  </Row>
                </Form>
                <Row></Row>
              </CardBody>
              <CardFooter>
                <Button
                  color="success"
                  href="#pablo"
                  onClick={() => handleSubmit()}
                  size="m"
                >
                  Save Billing
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AddBilling;
