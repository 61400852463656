import React, { useState, useEffect } from "react";
import { savePDF } from "@progress/kendo-react-pdf";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Table,
  Media,
  Badge,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";
// core components
import InvoiceHeader from "components/Headers/InvoiceHeader";
import { firestore } from "../../config/Fire";
import { useLocation, useParams, useHistory } from "react-router-dom";
import jsPDF from "jspdf";
import { ToWords } from "to-words";
import "jspdf-autotable";
import moment from "moment";
import CurrencyFormat from "react-currency-format";
import Converter from "number-to-words";
var converter = require("number-to-words");

const ViewNotes = ({ match }) => {
  //efwenewewkj
  ///weweew
  const location = useLocation();
  const history = useHistory();
  const [param, setparam] = useState("");
  const [creditData, setcreditData] = useState([]);
  let { slug } = useParams();
  const toWords = new ToWords();
  useEffect(() => {
    if (match.params.id != "") {
      firestore
        .collection("credit-notes")
        .doc(match.params.id)
        .onSnapshot((documentSnapshots) => {
          const creditData = [];
          creditData.push({
            ...documentSnapshots.data(),
            key: documentSnapshots.id,
          });
          setcreditData(creditData[0]);
          console.log(creditData[0]);
        });
    }
  }, []);

  const generatePDF = () => {
    window.print();
  };

  const createPdf = (html) => {
    savePDF(html, {
      paperSize: "Letter",
      fileName: "form.pdf",
      margin: 3,
    });
  };

  const bodyRef = React.createRef();
  console.log(bodyRef);
  return (
    <>
      {/* <InvoiceHeader /> */}
      {/* Page content */}
      <button onClick={() => createPdf(bodyRef.current)}>Download</button>
      <div ref={bodyRef}>
        <Container className="p-1" fluid id="pdfdownload">
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row>
                    <Col>
                      <img
                        alt="..."
                        className="rounded-circle"
                        src={require("../../assets/img/brand/yep_logo 1.jpg").default}
                        style={{ width: 265, height: 120 }}
                        onClick={() => generatePDF()}
                      />
                    </Col>
                    {/* <button>Download</button> */}
                    <Col className="text-center">
                      <Col className="text-center">
                        <h2 style={{ fontSize: 28 }}>CREDIT NOTE</h2>
                      </Col>
                      <Row className="text-center">
                        <Col className="text-center">
                          <h2 style={{ fontSize: 28, fontWeight: "bold" }}>
                            YEP Education Pvt. Ltd{" "}
                          </h2>
                        </Col>
                      </Row>
                      <Row className="text-center">
                        <Col className="text-center mt-4">
                          <h4 style={{ fontWeight: "bold" }}>
                            Address: C-313, Raghubir Nagar New Delhi 110027
                          </h4>
                        </Col>
                      </Row>
                    </Col>
                    <Col className="text-right">
                      <h3>GSTIN : 07AABCY3475H1Z4</h3>
                      <h3>HSN Code : 49011010</h3>
                      <h4>Email: info@yep.education</h4>
                      <h4>Web: www.yep.education</h4>
                      <h4>Mob. : 8826467474</h4>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="bg-white mt--4">
                  <Row>
                    {creditData?.billingInfo?.map((item, index) => (
                      <Col className="border border-darker m-0 p-0">
                        <table className="w-100">
                          <thead>
                            <tr>
                              <th>
                                <h3 style={{ fontWeight: "bold" }}>
                                  Bill To:{" "}
                                </h3>
                              </th>
                              <th>
                                <h3 style={{ fontWeight: "bold" }}>
                                  {item.to}
                                </h3>
                              </th>
                            </tr>
                            <tr>
                              <th colSpan="2">
                                <hr className="m-0 p-0 bg-dark" />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <h4 style={{ fontWeight: "bold" }}>
                                  Address:{" "}
                                </h4>
                              </td>
                              <td>
                                <h4 style={{ fontWeight: "bold" }}>
                                  {item.address}
                                </h4>
                              </td>
                            </tr>
                            <tr>
                              <th colSpan="2">
                                <hr className="m-0 p-0 bg-dark" />
                              </th>
                            </tr>
                            <tr>
                              <td>
                                <h4 style={{ fontWeight: "bold" }}>
                                  Contact Person:{" "}
                                </h4>
                              </td>
                              <td>
                                <h4 style={{ fontWeight: "bold" }}>
                                  {item.contactPerson}
                                </h4>
                              </td>
                            </tr>
                            <tr>
                              <th colSpan="2">
                                <hr className="m-0 p-0 bg-dark" />
                              </th>
                            </tr>
                            <tr>
                              <td>
                                <h4 style={{ fontWeight: "bold" }}>
                                  Phone No.:
                                </h4>
                              </td>
                              <td>
                                <h4 style={{ fontWeight: "bold" }}>
                                  {item.phoneNo}
                                </h4>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </Col>
                    ))}
                    <Col className="border border-darker m-0 p-0">
                      <table className="w-100">
                        <thead>
                          <tr>
                            <th>
                              <h3 style={{ fontWeight: "bold" }}>
                                Credit Note No. :{" "}
                              </h3>
                            </th>
                            <th>
                              <h3 style={{ fontWeight: "bold" }}>
                                {"RTN-21-" + creditData.creditNoteNo}
                              </h3>
                            </th>
                          </tr>
                          <tr>
                            <th colSpan="2">
                              <hr className="m-0 p-0 bg-dark" />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <h4 style={{ fontWeight: "bold" }}>Date: </h4>
                            </td>
                            <td>
                              <h4 style={{ fontWeight: "bold" }}>
                                {moment(creditData?.timeStamp).date() +
                                  "/" +
                                  parseInt(
                                    moment(creditData?.timeStamp).month() + 1
                                  ) +
                                  "/" +
                                  moment(creditData?.timeStamp).year()}
                              </h4>
                            </td>
                          </tr>
                          <tr>
                            <th colSpan="2">
                              <hr className="m-0 p-0 bg-dark" />
                            </th>
                          </tr>
                          {/* <tr>
                          <td ><h4 style={{fontWeight:"bold"}}>Contact No.: </h4></td>
                          <td ><h4 style={{fontWeight:"bold"}}>{}</h4></td>
                        </tr> */}
                          {/* <tr>
                          <th colSpan="2"><hr className="m-0 p-0 bg-dark" /></th>
                        </tr> */}
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                  {creditData?.mode !== "none" && (
                    <Row className="border border-darker">
                      <Table
                        className="align-items-center table-flush"
                        responsive
                      >
                        <thead className="">
                          <tr>
                            <th
                              className="border border-darker"
                              scope="col"
                              style={{ fontWeight: "bold", fontSize: 16 }}
                            >
                              S. No.
                            </th>
                            <th
                              className="border border-darker"
                              scope="col"
                              style={{ fontWeight: "bold", fontSize: 16 }}
                            >
                              Mode
                            </th>
                            <th
                              className="border border-darker"
                              scope="col"
                              style={{ fontWeight: "bold", fontSize: 16 }}
                            >
                              Cost
                            </th>
                          </tr>
                        </thead>

                        <tbody className="border border-darker">
                          <tr className="border border-darker">
                            <td
                              className="border border-darker"
                              style={{ fontWeight: "bold", fontSize: 16 }}
                            >
                              1
                            </td>
                            <td
                              className="border border-darker"
                              style={{ fontWeight: "bold", fontSize: 16 }}
                            >
                              {creditData?.mode}
                            </td>
                            <td
                              className="border border-darker"
                              style={{ fontWeight: "bold", fontSize: 16 }}
                            >
                              {creditData?.price}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Row>
                  )}
                  <br />
                  <Row className="border border-darker">
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="">
                        <tr>
                          <th
                            className="border border-darker"
                            scope="col"
                            style={{ fontWeight: "bold", fontSize: 16 }}
                          >
                            S. No.
                          </th>
                          <th
                            className="border border-darker"
                            scope="col"
                            style={{ fontWeight: "bold", fontSize: 16 }}
                          >
                            Book Name
                          </th>
                          <th
                            className="border border-darker"
                            scope="col"
                            style={{ fontWeight: "bold", fontSize: 16 }}
                          >
                            Price
                          </th>
                          <th
                            className="border border-darker"
                            scope="col"
                            style={{ fontWeight: "bold", fontSize: 16 }}
                          >
                            Disc. %
                          </th>
                          <th
                            className="border border-darker"
                            scope="col"
                            style={{ fontWeight: "bold", fontSize: 16 }}
                          >
                            Quantity
                          </th>
                          <th
                            className="border border-darker"
                            scope="col"
                            style={{ fontWeight: "bold", fontSize: 16 }}
                          >
                            Total
                          </th>
                        </tr>
                      </thead>
                      {creditData?.productInfo?.map((item, index) => (
                        <tbody className="border border-darker">
                          <tr className="border border-darker">
                            <th className="border border-darker" scope="row">
                              <Media className="align-items-center">
                                <span
                                  className="mb-0 text-sm"
                                  style={{ fontWeight: "bold", fontSize: 16 }}
                                >
                                  {index + 1}
                                </span>
                              </Media>
                            </th>
                            <td
                              className="border border-darker"
                              style={{ fontWeight: "bold", fontSize: 16 }}
                            >
                              {item.particulars}
                            </td>
                            <td
                              className="border border-darker"
                              style={{ fontWeight: "bold", fontSize: 16 }}
                            >
                              {item.price}
                            </td>
                            <td
                              className="border border-darker"
                              style={{ fontWeight: "bold", fontSize: 16 }}
                            >
                              {item.discount}
                            </td>
                            <td
                              className="border border-darker"
                              style={{ fontWeight: "bold", fontSize: 16 }}
                            >
                              {item.quantity}
                            </td>
                            <td
                              className="border border-darker"
                              style={{ fontWeight: "bold", fontSize: 16 }}
                            >
                              {Math.round(item.total)}
                            </td>
                          </tr>
                        </tbody>
                      ))}
                      <tfoot className="border border-darker">
                        <tr>
                          <th
                            className="border-black border-dark"
                            scope="col"
                            colSpan="4"
                            style={{
                              fontWeight: "bold",
                              fontSize: 16,
                              borderWidth: 3,
                            }}
                          >
                            Total No. of Books
                          </th>
                          <th
                            className="border-black border-dark"
                            scope="col"
                            style={{
                              fontWeight: "bold",
                              fontSize: 16,
                              borderWidth: 3,
                            }}
                          >
                            {creditData?.totalBooks}
                          </th>
                          <th
                            className="border-black border-dark"
                            scope="col"
                            style={{
                              fontWeight: "bold",
                              fontSize: 16,
                              borderWidth: 3,
                            }}
                          >
                            {"₹ " + Math.round(creditData?.netAmount)}
                          </th>
                        </tr>
                      </tfoot>
                    </Table>
                  </Row>
                  <>
                    <Row className="border border-darker pt-1 pb-1">
                      <Col xs="7">
                        <Row>
                          <Col
                            xs="3"
                            style={{ fontWeight: "bold", fontSize: 16 }}
                          >
                            Customer GST No. :
                          </Col>
                          <Col style={{ fontWeight: "bold", fontSize: 16 }}>
                            {creditData?.billingInfo?.map((item, index) => (
                              <Col style={{ fontWeight: "bold", fontSize: 16 }}>
                                {item?.customerGST}
                              </Col>
                            ))}
                          </Col>
                        </Row>
                      </Col>
                      <Col>
                        <Row>
                          <Col style={{ fontWeight: "bold", fontSize: 16 }}>
                            POSTAGE & COURIER :
                          </Col>
                          <Col style={{ fontWeight: "bold", fontSize: 16 }}>
                            0.0
                          </Col>
                        </Row>
                        <Row>
                          <Col style={{ fontWeight: "bold", fontSize: 16 }}>
                            FREIGHT COST :
                          </Col>
                          <Col style={{ fontWeight: "bold", fontSize: 16 }}>
                            0.0
                          </Col>
                        </Row>
                        <Row>
                          <Col style={{ fontWeight: "bold", fontSize: 16 }}>
                            Round Off :
                          </Col>
                          <Col style={{ fontWeight: "bold", fontSize: 16 }}>
                            0.0
                          </Col>
                        </Row>
                        <hr />
                        <Row className="mt-2">
                          <Col style={{ fontWeight: "bold", fontSize: 16 }}>
                            <h4>TOTAL(Rs.) :</h4>
                          </Col>
                          <Col style={{ fontWeight: "bold", fontSize: 16 }}>
                            <h4>
                              <CurrencyFormat
                                value={
                                  Math.round(
                                    parseFloat(creditData?.netAmount)
                                  ) + parseInt(creditData?.price)
                                }
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"₹"}
                              />
                              {/* <CurrencyFormat
                                value={Math.round(creditData?.netAmount)}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"₹"}
                              /> */}
                            </h4>
                          </Col>
                        </Row>
                        {/* <Row className="mt-2">
                        <Col style={{fontWeight:"bold", fontSize:16}}><h4>Amount in words :</h4></Col>
                        <Col style={{fontWeight:"bold", fontSize:16}}><h4>
                          {converter.toWords(creditData?.netAmount).toUpperCase() }
                          </h4>
                        </Col>
                      </Row> */}
                      </Col>
                    </Row>

                    <Row className="border border-darker justify-content-center">
                      <h2>Amount in words :</h2>
                      <h2 className="pl-4">
                        {toWords.convert(
                          parseInt("0" + creditData?.netAmount),
                          { currency: true }
                        )}
                        {/* {"Rs. "+ converter.toWords(parseInt("0"+creditData?.netAmount)).toUpperCase() +" ONLY"} */}
                      </h2>
                    </Row>
                  </>
                </CardBody>
                <CardFooter className="mt--4">
                  <Row>
                    <Col xs="8"></Col>
                    <Col>
                      <h3>For YEP Education Private Limited</h3>
                      <div>
                        <Col>
                          <img
                            alt="..."
                            className="rounded-circle"
                            src={
                              require("../../assets/img/signature (1).jpg")
                                .default
                            }
                            style={{ width: 180, height: 120 }}
                          />
                          <h3>Authorised Signatory</h3>
                        </Col>
                      </div>
                    </Col>
                  </Row>
                </CardFooter>
                {/* <Row className="border border-darker justify-content-center m-1">
              <Col md="2">Cash/Cheque</Col>
              <Col md="3">
                <Row>
                <Col>Beneficiary Name:</Col>
                <Col>YEP EDUCATION PVT LTD</Col>
                </Row>
              </Col>
              <Col>ICICI Bank Ltd. Vishal Encalve, New Delhi 110027, A/c No. 630105007738</Col>
              <Col md="2">IFSC Code: ICIC0006301</Col>                
               </Row> */}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ViewNotes;
