// import React, { useState, useEffect } from "react";
// import {
//   Button,
//   Badge,
//   Card,
//   CardHeader,
//   CardFooter,
//   CardBody,
//   DropdownItem,
//   DropdownMenu,
//   UncontrolledDropdown,
//   DropdownToggle,
//   Media,
//   Table,
//   FormGroup,
//   Form,
//   Input,
//   Container,
//   Row,
//   Col,
// } from "reactstrap";
// // core components
// import EditBillingHeader from "components/Headers/EditBillingHeader";
// import {TextField,} from "@material-ui/core";
// import { firestore } from "../../config/Fire";
// import { useHistory, useLocation } from "react-router-dom";
// import { makeStyles } from '@material-ui/core/styles';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     '& .MuiTextField-root': {
//       margin: theme.spacing(1),
//       width: '25ch',
//     },
//   },
// }));

// const EditBilling = ({match}) => {
//   const history = useHistory();
//   const classes = useStyles();
//   const [dealers, setdealers] = useState([]);
//   const [books, setbooks] = useState([]);
//   const [netAmount, setnetAmount] = useState(0);
//   const [totalBooks, settotalBooks] = useState(0);
//   const [billings, setbillings] = useState([]);
//   const [inv, setinv] = useState(0);
//   const [billData, setbillData] = useState([]);
//   const [billingInfo, setbillingInfo] = useState([
//     {
//       billTo: '',
//       address: '',
//       contactPerson: '',
//       phoneNo: '',
//       customerGST: '',
//     }
//   ]);
//   const [shippingInfo, setshippingInfo] = useState([
//     {
//       shipTo: '',
//       address: '',
//       contactNo: '',
//       grNo: '',
//       weight: '',
//     }
//   ]);
//   const [invoiceInfo, setinvoiceInfo] = useState([
//     {
//       invoiceNo: 0,
//       invoiceDate: '',
//       dated: '',
//       transporter: '',
//       courier: 0,
//       freightCost: 0,
//       roundOff: 0,
//       noOfBoxes: 0,
//     }
//   ]);
//   const [productInfo, setproductInfo] = useState([
//     {
//       particulars: '',
//       isbn: 0,
//       quantity: 0,
//       price: 0,
//       discount: 0,
//       amount: 0,
//       total: 0,
//      }
//   ]);
//   useEffect(() => {
//     firestore
//     .collection("billings")
//     .onSnapshot(querrySnapshots => {
//       const billings = [];
//       querrySnapshots.forEach(documentSnapshots => {
//         billings.push({
//           ...documentSnapshots.data(),
//           key: documentSnapshots.id,
//         });
//       });
//       setbillings(billings);
//       setinv(billings.length+1);
//     })
//   }, []);

//   useEffect(() => {
//     if(match.params.id != "") {
//         firestore
//         .collection("billings")
//         .doc(match.params.id)
//         .onSnapshot(documentSnapshots => {
//             const billData = [];
//             billData.push({
//                 ...documentSnapshots.data(),
//                 key: documentSnapshots.id,
//             })
//             setproductInfo(billData[0].productInfo);
//             setbillingInfo(billData[0].billingInfo);
//             setshippingInfo(billData[0].shippingInfo);
//             setinvoiceInfo(billData[0].invoiceInfo);
//             settotalBooks(billData[0].totalBooks);
//             setnetAmount(billData[0].netAmount);
//
//
//
//

//             setbillData(billData[0]);
//
//         });
//     }
//   }, []);

//   useEffect(() => {
//     firestore
//     .collection("dealers")
//     .onSnapshot(querrySnapshots => {
//       const dealers = [];
//       querrySnapshots.forEach(documentSnapshots => {
//         dealers.push({
//           ...documentSnapshots.data(),
//           key: documentSnapshots.id,
//         });
//       });
//       setdealers(dealers);
//       // const billing = [...billingInfo];
//       // const shipping = [...shippingInfo];
//       // billing[0].address = dealers[0].billingAddress[0].address;
//       // billing[0].contactPerson = dealers[0].basicInfo[0].primaryContactName;
//       // billing[0].phoneNo = dealers[0].basicInfo[0].phoneNo;
//       // shipping[0].shipTo = dealers[0].shippingAddress[0].name;
//       // shipping[0].address = dealers[0].shippingAddress[0].address;
//       // shipping[0].contactNo = dealers[0].shippingAddress[0].phone;
//       // setbillingInfo(billing);
//       // setshippingInfo(shipping)
//     });
//   }, []);
//   useEffect(() => {
//     firestore
//     .collection("books")
//     .onSnapshot(querrySnapshots => {
//       const books = [];
//       querrySnapshots.forEach(documentSnapshots => {
//         books.push({
//           ...documentSnapshots.data(),
//           key: documentSnapshots.id,
//         });
//       });
//       setbooks(books);
//     })
//   }, []);

//   const handleAddFields = () => {
//     const values = [...productInfo];
//     values.push({
//       particulars: '',
//       isbn: 0,
//       quantity: 0,
//       price: 0,
//       discount: 0,
//       amount: 0,
//       total: 0
//      });
//     setproductInfo(values);
//   };

//   const handleRemoveFields = index => {
//     const values = [...productInfo];
//     values.splice(index, 1);
//     setproductInfo(values);
//   };

//   const handleProductInputChange = (index, event) => {
//     const values = [...productInfo];
//     if (event.target.name === "particulars") {
//       const product = [...productInfo];
//       product[index].total = 0;
//       for(var i = 0; i< books.length; i++) {
//         if(books[i].bookInfo.name === event.target.value) {
//           product[index].particulars = books[i].bookInfo.name;
//           product[index].price = books[i].bookInfo.price;
//           product[index].discount = books[i].bookInfo.discount;
//           product[index].total = (parseInt(product[index].price)*parseInt(product[index].quantity)) - (parseInt(product[index].price)*parseInt(product[index].quantity)*parseInt(product[index].discount)) / 100;
//           setproductInfo(product);
//         }
//       }
//     }
//     else if (event.target.name === "isbn") {
//       values[index].isbn = event.target.value;
//     }
//     else if (event.target.name === "quantity") {
//       values[index].quantity = event.target.value;
//       values[index].total = (parseInt(values[index].price)*parseInt(values[index].quantity)) - (parseInt(values[index].price)*parseInt(values[index].quantity)*parseInt(values[index].discount)) / 100;

//     }
//     else if (event.target.name === "price") {
//       values[index].price = event.target.value;
//       values[index].total = (parseInt(values[index].price)*parseInt(values[index].quantity)) - (parseInt(values[index].price)*parseInt(values[index].quantity)*parseInt(values[index].discount)) / 100;

//     }
//     else if (event.target.name === "discount") {
//       values[index].discount = event.target.value;
//       values[index].total = (parseInt(values[index].price)*parseInt(values[index].quantity)) - (parseInt(values[index].price)*parseInt(values[index].quantity)*parseInt(values[index].discount)) / 100;

//     }
//     else {
//       values[index].amount = event.target.value;
//     }
//     setproductInfo(values);
//     var temp1 = 0;
//     var temp2 = 0;
//     for(var i = 0; i< productInfo.length;i++) {
//       temp1 = temp1+productInfo[i].total;
//       temp2 = temp2+ parseInt(productInfo[i].quantity);
//     }
//     setnetAmount(temp1);
//     settotalBooks(temp2);
//   };

//   const handleBillingInputChange = (event) => {
//     const values = [...billingInfo];
//     if (event.target.name === "billTo") {
//       values[0].billTo = event.target.value;
//       const billing = [...billingInfo];
//       const shipping = [...shippingInfo];
//       for(var i = 0; i< dealers.length; i++) {
//         if(dealers[i].basicInfo[0].name === event.target.value) {
//           billing[0].address = dealers[i].billingAddress[0].address;
//           billing[0].contactPerson = dealers[i].basicInfo[0].primaryContactName;
//           billing[0].phoneNo = dealers[i].basicInfo[0].phoneNo;
//           billing[0].customerGST = dealers[i].basicInfo[0].customerGST === undefined? "":dealers[0].basicInfo[0].customerGST;
//           shipping[0].shipTo = dealers[i].shippingAddress[0].name;
//           shipping[0].address = dealers[i].shippingAddress[0].address;
//           shipping[0].contactNo = dealers[i].shippingAddress[0].phone;
//           setbillingInfo(billing);
//           setshippingInfo(shipping)
//         }
//       }
//     }
//     else if (event.target.name === "address") {
//       values[0].address = event.target.value;
//     }
//     else if (event.target.name === "contactPerson") {
//       values[0].contactPerson = event.target.value;
//     }
//     else if (event.target.name === "cust-gst-no") {
//       values[0].customerGST = event.target.value;
//     }
//     else {
//       values[0].phoneNo = event.target.value;
//     }

//     setbillingInfo(values);
//   }
//   const handleShippingInputChange = (event) => {
//     const values = [...shippingInfo];
//     if (event.target.name === "shipTo") {
//       values[0].shipTo = event.target.value;
//     }
//     else if (event.target.name === "address") {
//       values[0].address = event.target.value;
//     }
//     else if (event.target.name === "contactNo") {
//       values[0].contactNo = event.target.value;
//     }
//     else if (event.target.name === "grNo") {
//       values[0].grNo = event.target.value;
//     }
//     else {
//       values[0].weight = event.target.value;
//     }

//     setshippingInfo(values);
//   }
//   const handleInvoiceInputChange = (event) => {
//     const values = [...invoiceInfo];
//     if (event.target.name === "invoiceNo") {
//       values[0].invoiceNo = event.target.value;
//     }
//     else if (event.target.name === "invoiceDate") {
//       values[0].invoiceDate = event.target.value;
//     }
//     // else if (event.target.name === "custGSTNo") {
//     //   values[0].custGSTNo = event.target.value;
//     // }
//     else if (event.target.name === "dated") {
//       values[0].dated = event.target.value;
//     }
//     else if (event.target.name === "transporter") {
//       values[0].transporter = event.target.value;
//     }
//     else if (event.target.name === "courier") {
//       values[0].courier = event.target.value;
//     }
//     else if (event.target.name === "freightCost") {
//       values[0].freightCost = event.target.value;
//     }
//     else if (event.target.name === "roundOff") {
//       values[0].roundOff = event.target.value;
//     }
//     else {
//       values[0].noOfBoxes = event.target.value;
//     }

//     setinvoiceInfo(values);
//   }
//   const handleSubmit = e => {
//     const values = [...invoiceInfo];
//     const date = values[0]?.dated?.split("-")
//     values[0].dated = date[2]+"-"+date[1]+"-"+date[0]
//     setinvoiceInfo(values)
//     firestore
//     .collection("billings")
//     .doc(match.params.id)
//     .set({
//       billingInfo,
//       shippingInfo,
//       invoiceInfo,
//       productInfo,
//       netAmount,
//       totalBooks,
//       timeStamp: Date.now(),
//     }, { merge: true})
//     .catch(err => {
//
//     })
//     .then(() => {
//       history.push("/admin/billings");
//     });
//   };
//   const getTotal = () => {
//     var temp = 0;
//     for(var i = 0; i< productInfo.length;i++) {
//       temp = temp+productInfo[i].total;
//     }
//     setnetAmount(temp);
//   }
//   return (
//     <>
//       <EditBillingHeader />
//       {/* Page content */}
//       <Container className="mt--7" fluid>
//         <Row>
//           <Col className="order-xl-1" xl="12">
//             <Card className="bg-secondary shadow">
//               <CardHeader className="bg-white border-0">
//                 <Row className="align-items-center">
//                   <Col xs="8">
//                     <h3 className="mb-0">Please fill the required details</h3>
//                   </Col>
//                 </Row>
//               </CardHeader>
//               <CardBody>
//                 <Form>
//                   <h6 className="heading-small text-muted mb-4">
//                     Bill information
//                   </h6>
//                   <div className="pl-lg-4">
//                     <Row>
//                       <Col lg="6">
//                         <FormGroup>
//                           <label
//                             className="form-control-label"
//                             htmlFor="input-username"
//                           >
//                             Bill To
//                           </label>
//                           <TextField
//                           id="outlined-select-currency-native"
//                           fullWidth
//                           size="small"
//                           style={{backgroundColor:"#fff",color:"#8898aa"}}
//                           select
//                           placeholder="Bill To"
//                           name="billTo"
//                           value={billingInfo[0].billTo}
//                           onChange={(e) => handleBillingInputChange(e)}
//                           onch
//                           SelectProps={{
//                             native: true,
//                           }}
//                           helperText="Please fill this out"
//                           variant="outlined"
//                         >
//                           {dealers.map((option) => (
//                             <option key={option.key} value={option.basicInfo[0].name}>
//                               {option.basicInfo[0].name}
//                             </option>
//                           ))}
//                         </TextField>
//                           {/* <Input
//                             className="form-control-alternative"
//                             id="input-username"
//                             required
//                             name="billTo"
//                             value={billingInfo.billTo}
//                             onChange={(e) => handleBillingInputChange(e)}
//                             placeholder="Bill To"
//                             type="text"
//                           /> */}
//                         </FormGroup>
//                       </Col>
//                       <Col lg="6">
//                         <FormGroup>
//                           <label
//                             className="form-control-label"
//                             htmlFor="input-email"
//                           >
//                             Address
//                           </label>
//                           <Input
//                             className="form-control-alternative"
//                             id="input-email"
//                             name="address"
//                             value={billingInfo[0].address}
//                             onChange={(e) => handleBillingInputChange(e)}
//                             placeholder="Address"
//                             type="text"
//                           />
//                         </FormGroup>
//                       </Col>
//                     </Row>
//                     <Row>
//                       <Col lg="6">
//                         <FormGroup>
//                           <label
//                             className="form-control-label"
//                             htmlFor="input-username"
//                           >
//                             Contact Person
//                           </label>
//                           <Input
//                             className="form-control-alternative"
//                             id="input-username"
//                             name="contactPerson"
//                             value={billingInfo[0].contactPerson}
//                             onChange={(e) => handleBillingInputChange(e)}
//                             placeholder="Contact Person"
//                             type="text"
//                           />
//                         </FormGroup>
//                       </Col>
//                       <Col lg="6">
//                         <FormGroup>
//                           <label
//                             className="form-control-label"
//                             htmlFor="input-email"
//                           >
//                             Phone No./ Mob. No.
//                           </label>
//                           <Input
//                             className="form-control-alternative"
//                             id="input-email"
//                             name="phoneNo"
//                             value={billingInfo[0].phoneNo}
//                             onChange={(e) => handleBillingInputChange(e)}
//                             placeholder="Phone Number"
//                             type="number"
//                           />
//                         </FormGroup>
//                       </Col>
//                       <Col lg="6">
//                         <FormGroup>
//                           <label
//                             className="form-control-label"
//                             htmlFor="input-first-name"
//                           >
//                             Customer GST No.
//                           </label>
//                           <Input
//                             className="form-control-alternative"
//                             id="input-first-name"
//                             name="cust-gst-no"
//                             value={billingInfo[0].customerGST}
//                             onChange={(e) => handleBillingInputChange(e)}
//                             placeholder="Cust GST Number"
//                             type="text"
//                           />
//                         </FormGroup>
//                       </Col>
//                     </Row>
//                   </div>
//                   <h6 className="heading-small text-muted mb-4">
//                     Shipping information
//                   </h6>
//                   <div className="pl-lg-4">
//                     <Row>
//                       <Col lg="6">
//                         <FormGroup>
//                           <label
//                             className="form-control-label"
//                             htmlFor="input-first-name"
//                           >
//                             Ship To
//                           </label>
//                           <Input
//                             className="form-control-alternative"
//                             id="input-first-name"
//                             name="shipTo"
//                             value={shippingInfo[0].shipTo}
//                             onChange={(e) => handleShippingInputChange(e)}
//                             placeholder="Ship To"
//                             type="text"
//                           />
//                         </FormGroup>
//                       </Col>
//                       <Col lg="6">
//                         <FormGroup>
//                           <label
//                             className="form-control-label"
//                             htmlFor="input-last-name"
//                           >
//                             Shipping Address
//                           </label>
//                           <Input
//                             className="form-control-alternative"
//                             id="input-last-name"
//                             name="address"
//                             value={shippingInfo[0].address}
//                             onChange={(e) => handleShippingInputChange(e)}
//                             placeholder="Address"
//                             type="text"
//                           />
//                         </FormGroup>
//                       </Col>
//                     </Row>
//                     <Row>
//                       <Col lg="6">
//                         <FormGroup>
//                           <label
//                             className="form-control-label"
//                             htmlFor="input-first-name"
//                           >
//                             Contact No.
//                           </label>
//                           <Input
//                             className="form-control-alternative"
//                             id="input-first-name"
//                             name="contactNo"
//                             value={shippingInfo[0].contactNo}
//                             onChange={(e) => handleShippingInputChange(e)}
//                             placeholder="Contact No"
//                             type="number"
//                           />
//                         </FormGroup>
//                       </Col>
//                       {/* <Col lg="6">
//                         <FormGroup>
//                           <label
//                             className="form-control-label"
//                             htmlFor="input-last-name"
//                           >
//                             G.R. No
//                           </label>
//                           <Input
//                             className="form-control-alternative"
//                             id="input-last-name"
//                             name="grNo"
//                             value={shippingInfo[0].grNo}
//                             onChange={(e) => handleShippingInputChange(e)}
//                             placeholder="G R. No."
//                             type="text"
//                           />
//                         </FormGroup>
//                       </Col> */}
//                     </Row>
//                     {/* <Row>
//                       <Col lg="6">
//                         <FormGroup>
//                           <label
//                             className="form-control-label"
//                             htmlFor="input-first-name"
//                           >
//                             Weight
//                           </label>
//                           <Input
//                             className="form-control-alternative"
//                             id="input-first-name"
//                             name="weight"
//                             value={shippingInfo[0].weight}
//                             onChange={(e) => handleShippingInputChange(e)}
//                             placeholder="Weight"
//                             type="text"
//                           />
//                         </FormGroup>
//                       </Col>
//                     </Row> */}
//                   </div>
//                   <h6 className="heading-small text-muted mb-4">
//                     Invoice information
//                   </h6>
//                   <div className="pl-lg-4">
//                     <Row>
//                       <Col lg="6">
//                         <FormGroup>
//                           <label
//                             className="form-control-label"
//                             htmlFor="input-last-name"
//                           >
//                             Dated
//                           </label>
//                           <Input
//                             className="form-control-alternative"
//                             id="input-last-name"
//                             name="dated"
//                             value={invoiceInfo[0].dated}
//                             onChange={(e) => handleInvoiceInputChange(e)}
//                             placeholder="Dated"
//                             type="date"
//                           />
//                         </FormGroup>
//                       </Col>
//                       <Col lg="6">
//                         <FormGroup>
//                           <label
//                             className="form-control-label"
//                             htmlFor="input-last-name"
//                           >
//                             POSTAGE & COURIER
//                           </label>
//                           <Input
//                             className="form-control-alternative"
//                             id="input-last-name"
//                             name="courier"
//                             value={invoiceInfo[0].courier}
//                             onChange={(e) => handleInvoiceInputChange(e)}
//                             placeholder="POSTAGE & COURIER"
//                             type="text"
//                           />
//                         </FormGroup>
//                       </Col>
//                     </Row>
//                     <Row>
//                       {/* <Col lg="6">
//                         <FormGroup>
//                           <label
//                             className="form-control-label"
//                             htmlFor="input-first-name"
//                           >
//                             Transporter
//                           </label>
//                           <Input
//                             className="form-control-alternative"
//                             id="input-first-name"
//                             name="transporter"
//                             value={invoiceInfo[0].transporter}
//                             onChange={(e) => handleInvoiceInputChange(e)}
//                             placeholder="Transporter"
//                             type="text"
//                           />
//                         </FormGroup>
//                       </Col> */}
//                     </Row>
//                     <Row>
//                       <Col lg="6">
//                         <FormGroup>
//                           <label
//                             className="form-control-label"
//                             htmlFor="input-first-name"
//                           >
//                             FREIGHT COST
//                           </label>
//                           <Input
//                             className="form-control-alternative"
//                             id="input-first-name"
//                             name="freightCost"
//                             value={invoiceInfo[0].freightCost}
//                             onChange={(e) => handleInvoiceInputChange(e)}
//                             placeholder="FREIGHT COST"
//                             type="text"
//                           />
//                         </FormGroup>
//                       </Col>
//                       <Col lg="6">
//                         <FormGroup>
//                           <label
//                             className="form-control-label"
//                             htmlFor="input-first-name"
//                           >
//                             Round Off
//                           </label>
//                           <Input
//                             className="form-control-alternative"
//                             id="input-first-name"
//                             name="roundOff"
//                             value={invoiceInfo[0].roundOff}
//                             onChange={(e) => handleInvoiceInputChange(e)}
//                             placeholder="Round Off"
//                             type="text"
//                           />
//                         </FormGroup>
//                       </Col>

//                     </Row>
//                   </div>
//                   <hr className="my-4" />
//                   <Row>
//                     <div className="col">
//                       <Card className="shadow">
//                         <CardHeader className="border-0">
//                             <Row>
//                               <Col xs="10">
//                                 <h3 className="mb-0">Product Information</h3>
//                               </Col>
//                               <Col xs="">
//                                 <Button
//                                   color="primary"
//                                   href="#pablo"
//                                   onClick={() => handleAddFields()}
//                                   size="sm"
//                                 >
//                                   Add New
//                                 </Button>
//                               </Col>
//                             </Row>
//                         </CardHeader>

//                         <Table className="align-items-center table-flush" responsive>
//                           <thead className="thead-light">
//                             <tr>
//                               <th scope="col">Id</th>
//                               <th scope="col">Book Name</th>
//                               {/* <th scope="col">ISBN</th> */}
//                               <th scope="col">Price</th>
//                               <th scope="col"> Fixed Disc. %</th>
//                               <th scope="col">Quantity</th>
//                               <th scope="col">Total</th>
//                               <th scope="col">Options</th>
//                             </tr>
//                           </thead>
//                           {productInfo.map((inputField, index) => (
//                             <tbody>
//                             <tr>
//                               <th scope="row">
//                                 <Media className="align-items-center">
//                                     <span className="mb-0 text-sm">
//                                       {index+1}
//                                     </span>
//                                 </Media>
//                               </th>
//                               <td>
//                               <TextField
//                                 id="outlined-select-currency-native"
//                                 fullWidth
//                                 size="small"
//                                 style={{backgroundColor:"#fff",color:"#8898aa"}}
//                                 select
//                                 placeholder="Book Name"
//                                 name="particulars"
//                                 value={inputField.particulars}
//                                 onChange={event => handleProductInputChange(index, event)}
//                                 SelectProps={{
//                                   native: true,
//                                 }}
//                                 variant="outlined"
//                               >
//                                 {books.map((option) => (
//                                   <option key={option.key} value={option.bookInfo.name}>
//                                     {option.bookInfo.name}
//                                   </option>
//                                 ))}
//                               </TextField>
//                               {/* <Input
//                                 className="form-control-alternative"
//                                 style={{minWidth:"100px"}}
//                                 placeholder="Particulars"
//                                 type="text"
//                                 id="particulars"
//                                 name="particulars"
//                                 value={inputField.particulars}
//                                 onChange={event => handleProductInputChange(index, event)}
//                               /> */}
//                               </td>
//                               {/* <td>
//                                 <Badge color="" className="badge-dot mr-4">
//                                   <Input
//                                     className="form-control-alternative"
//                                     id="isbn"
//                                     style={{minWidth:"100px"}}
//                                     name="isbn"
//                                     placeholder="ISBN"
//                                     type="number"
//                                     value={inputField.isbn}
//                                     onChange={event => handleProductInputChange(index, event)}
//                                   />
//                                 </Badge>
//                               </td> */}
//                               <td>
//                               <Input
//                                 className="form-control-alternative"
//                                 id="price"
//                                 style={{minWidth:"100px"}}
//                                 placeholder="Price"
//                                 name="price"
//                                 type="number"
//                                 value={inputField.price}
//                                 onChange={event => handleProductInputChange(index, event)}
//                               />
//                               </td>
//                               <td>
//                               <Input
//                                 className="form-control-alternative"
//                                 id="discount"
//                                 style={{minWidth:"100px"}}
//                                 placeholder="Discount"
//                                 name="discount"
//                                 type="number"
//                                 value={inputField.discount}
//                                 onChange={event => handleProductInputChange(index, event)}
//                               />
//                               </td>
//                               <td>
//                                 <Input
//                                   className="form-control-alternative"
//                                   id="quantity"
//                                   style={{minWidth:"100px"}}
//                                   name="quantity"
//                                   placeholder="Quantity"
//                                   type="number"
//                                   value={inputField.quantity}
//                                   onChange={event => handleProductInputChange(index, event)}
//                                 />
//                               </td>
//                               {/* <td>
//                               <Input
//                                 className="form-control-alternative"
//                                 id="amount"
//                                 style={{minWidth:"100px"}}
//                                 placeholder="Amount"
//                                 name="amount"
//                                 type="number"
//                                 value={inputField.amount}
//                                   onChange={event => handleProductInputChange(index, event)}
//                               />
//                               </td> */}
//                               <td>
//                                 {inputField.total}
//                               </td>
//                               <td className="text-right">
//                       <UncontrolledDropdown>
//                         <DropdownToggle
//                           className="btn-icon-only text-light"
//                           href="#pablo"
//                           role="button"
//                           size="sm"
//                           color=""
//                           onClick={(e) => e.preventDefault()}
//                         >
//                           <i className="fas fa-ellipsis-v" />
//                         </DropdownToggle>
//                         <DropdownMenu className="dropdown-menu-arrow" right>
//                           <DropdownItem
//                             href="#pablo"
//                             onClick={(e) => e.preventDefault()}
//                           >
//                             View Details
//                           </DropdownItem>
//                           <DropdownItem
//                             href="#pablo"
//                             onClick={(e) => handleRemoveFields(index)}
//                           >
//                             Delete
//                           </DropdownItem>
//                           <DropdownItem
//                             href="#pablo"
//                             onClick={(e) => e.preventDefault()}
//                           >
//                             Something else here
//                           </DropdownItem>
//                         </DropdownMenu>
//                       </UncontrolledDropdown>
//                     </td>
//                     </tr>
//                   </tbody>
//                             ))}
//                             <thead className="thead-light">
//                             <tr>
//                               <th scope="col" colSpan="4">Total No. of Books</th>
//                               <th scope="col">{totalBooks}</th>
//                               <th scope="col" colSpan="2">{"₹ " + netAmount}</th>
//                             </tr>
//                           </thead>
//                         </Table>
//                         <CardFooter className="py-4">
//                         <Row>
//                           <Col xs="6" />
//                           <Col xs="6">
//                             <Row>
//                               <Col>
//                               <h4>POSTAGE & COURIER :</h4>
//                               </Col>
//                               <Col>
//                               <h4>{invoiceInfo[0].courier}</h4>
//                               </Col>
//                             </Row>
//                             <Row>
//                               <Col>
//                               <h4>FREIGHT COST :</h4>
//                               </Col>
//                               <Col>
//                               <h4>{invoiceInfo[0].freightCost}</h4>
//                               </Col>
//                             </Row>
//                             <Row>
//                               <Col>
//                               <h4>Round Off :</h4>
//                               </Col>
//                               <Col>
//                               <h4>{invoiceInfo[0].roundOff}</h4>
//                               </Col>
//                             </Row>
//                         <hr></hr>
//                         <Row>
//                           <Col>
//                             Net Amount
//                           </Col>
//                           <Col>
//                             {invoiceInfo[0].courier+invoiceInfo[0].freightCost+invoiceInfo[0].roundOff+netAmount}
//                           </Col>
//                         </Row>
//                           </Col>
//                         </Row>
//                         </CardFooter>
//                       </Card>
//                     </div>
//                   </Row>
//                 </Form>
//                 <Row>

//                 </Row>
//               </CardBody>
//               <CardFooter>
//               <Button
//                 color="success"
//                 href="#pablo"
//                 onClick={() => handleSubmit()}
//                 size="m"
//               >
//                 Save Billing
//               </Button>
//               </CardFooter>
//             </Card>
//           </Col>
//         </Row>
//       </Container>
//     </>
//   );
// };

// export default EditBilling;
import React, { useState, useEffect } from "react";
import {
  Button,
  Badge,
  Card,
  CardHeader,
  CardFooter,
  CardBody,
  DropdownItem,
  DropdownMenu,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  UncontrolledTooltip,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import EditBillingHeader from "components/Headers/EditBillingHeader";
import { TextField } from "@material-ui/core";
import { firestore } from "../../config/Fire";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

const products = [
  {
    name: "Books",
    sub_products: [
      { name: "It Kode", values: ["It Kode 1", "It Kode 2"] },
      { name: "Krypto" },
      { name: "Cyber Navigation" },
      { name: "Analog" },
    ],
  },
  {
    name: "Gifts",
    sub_products: [
      { name: "Gift Books" },
      { name: "Sarees" },
      { name: "Photo Frame" },
      { name: "Diary Set" },
    ],
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

const EditBilling = ({ match }) => {
  const history = useHistory();

  const [dealers, setdealers] = useState([]);
  const [books, setbooks] = useState([]);
  const [newGifts, setNewGifts] = useState([]);
  const [netAmount, setnetAmount] = useState(0);
  const [totalBooks, settotalBooks] = useState(0);
  const [billings, setbillings] = useState([]);
  const [inv, setinv] = useState(0);
  const [billData, setbillData] = useState([]);
  const [intialproduct, setInitialProductInfo] = useState([]);
  const [billingInfo, setbillingInfo] = useState([
    {
      billTo: "",
      address: "",
      contactPerson: "",
      phoneNo: "",
      customerGST: "",
    },
  ]);
  const [shippingInfo, setshippingInfo] = useState([
    {
      shipTo: "",
      address: "",
      contactNo: "",
      grNo: "",
      weight: "",
    },
  ]);
  const [invoiceInfo, setinvoiceInfo] = useState([
    {
      invoiceNo: 0,
      invoiceDate: "",
      dated: "",
      transporter: "",
      courier: 0,
      freightCost: 0,
      roundOff: 0,
      noOfBoxes: 0,
    },
  ]);
  const [productInfo, setproductInfo] = useState([
    {
      particulars: "",
      isbn: 0,
      quantity: 0,
      price: 0,
      discount: 0,
      amount: 0,
      total: 0,
    },
  ]);
  const [types, setTypes] = useState("");
  const [subType, setSubType] = useState("");
  const [fields, setFields] = useState([]);
  const [subProducts, setSubProducts] = useState([]);
  const [newBooks, setNewBooks] = useState([]);
  const [initialSubProducts, setInitialSubProducts] = useState([]);
  const [initialSubType, setInitialSubType] = useState([]);

  useEffect(() => {
    firestore.collection("books").onSnapshot((querrySnapshots) => {
      const books = [];
      querrySnapshots.forEach((documentSnapshots) => {
        books.push({
          ...documentSnapshots.data(),
          key: documentSnapshots.id,
        });
      });
      setbooks(books);
    });

    firestore
      .collection("new_books")
      .orderBy("bookInfo.name", "asc")
      .onSnapshot((querrySnapshots) => {
        const books = [];
        querrySnapshots.forEach((documentSnapshots) => {
          books.push({
            ...documentSnapshots.data(),
            key: documentSnapshots.id,
          });
        });
        setNewBooks(books);
      });

    firestore.collection("gifts").onSnapshot((querrySnapshots) => {
      const gifts = [];
      querrySnapshots.forEach((documentSnapshots) => {
        gifts.push({
          ...documentSnapshots.data(),
          key: documentSnapshots.id,
        });
      });

      setNewGifts(gifts);
      // setNewBooks(books)
      // const product = [...productInfo];
      // product[0].particulars = books[0].bookInfo.name;
      // product[0].price = books[0].bookInfo.price;
      // product[0].discount = books[0].bookInfo.discount;
      // product[0].total =
      //   parseInt(product[0].price) * parseInt(product[0].quantity) -
      //   (parseInt(product[0].price) *
      //     parseInt(product[0].quantity) *
      //     parseInt(product[0].discount)) /
      //     100;
      // setproductInfo(product);
    });
  }, []);
  useEffect(() => {
    firestore.collection("billings").onSnapshot((querrySnapshots) => {
      const billings = [];
      querrySnapshots.forEach((documentSnapshots) => {
        billings.push({
          ...documentSnapshots.data(),
          key: documentSnapshots.id,
        });
      });

      setbillings(billings);
      setinv(billings.length + 1);
    });
  }, []);

  useEffect(() => {
    if (match.params.id != "") {
      firestore
        .collection("billings")
        .doc(match.params.id)
        .onSnapshot((documentSnapshots) => {
          const billData = [];
          billData.push({
            ...documentSnapshots.data(),
            key: documentSnapshots.id,
          });
          console.log("billData: ", billData);
          let data = billData[0];
          setTypes(data.type);
          setSubType(data.subType);
          setInitialProductInfo(billData[0].productInfo);
          setproductInfo(billData[0].productInfo);
          setbillingInfo(billData[0].billingInfo);
          setshippingInfo(billData[0].shippingInfo);
          setinvoiceInfo(billData[0].invoiceInfo);
          settotalBooks(billData[0].totalBooks);
          setnetAmount(billData[0].netAmount);
          setbillData(billData[0]);

          let filteredProducts = [];
          if (data.type === "Books") {
            console.log("HIIIIIIIIIIIIIIIIIIII", newBooks);
            let XProducts = newBooks.filter((book) => {
              return book.bookType === data.subType;
            });
            console.log("XProducts: ", XProducts);
            filteredProducts = XProducts.map((product) => {
              console.log("product: ", product);
              let book = data.productInfo.find((book) => {
                return book.particulars === product.bookInfo.name;
              });

              console.log("book: !!!!!!!!!!!!!!!!!!", book);
              return {
                ...product,
                quantity: book ? book.quantity : 0,
                total: book ? book.total : 0,
                price: book ? book.price : product.bookInfo.price,
                discount: book ? book.discount : product.bookInfo.discount,
              };
            });
          } else {
            filteredProducts = newGifts
              .filter((gift) => {
                console.log("gift: ", gift);
                return gift.giftType === data.type;
              })
              .map((product) => {
                return {
                  ...product,
                  quantity: 0,
                  total: 0,
                  price: product.giftInfo.price,
                  discount: product.giftInfo.discount,
                };
              });
          }
          setInitialSubProducts(filteredProducts);
          setInitialSubType(data.subType);
          console.log("filteredProducts :>> ", filteredProducts);
          setSubProducts(filteredProducts);
        });
    }
  }, [newBooks, newGifts]);

  useEffect(() => {
    firestore.collection("dealers").onSnapshot((querrySnapshots) => {
      const dealers = [];
      querrySnapshots.forEach((documentSnapshots) => {
        dealers.push({
          ...documentSnapshots.data(),
          key: documentSnapshots.id,
        });
      });
      setdealers(dealers);
      // const billing = [...billingInfo];
      // const shipping = [...shippingInfo];
      // billing[0].address = dealers[0].billingAddress[0].address;
      // billing[0].contactPerson = dealers[0].basicInfo[0].primaryContactName;
      // billing[0].phoneNo = dealers[0].basicInfo[0].phoneNo;
      // shipping[0].shipTo = dealers[0].shippingAddress[0].name;
      // shipping[0].address = dealers[0].shippingAddress[0].address;
      // shipping[0].contactNo = dealers[0].shippingAddress[0].phone;
      // setbillingInfo(billing);
      // setshippingInfo(shipping)
    });
  }, []);

  const handleAddFields = () => {
    const values = [...productInfo];
    values.push({
      particulars: "",
      isbn: 0,
      quantity: 0,
      price: 0,
      discount: 0,
      amount: 0,
      total: 0,
    });
    setproductInfo(values);
  };

  const handleRemoveFields = (index) => {
    const values = [...productInfo];
    values.splice(index, 1);
    setproductInfo(values);
  };

  const handleProductInputChange = (index, event) => {
    const values = [...productInfo];
    if (event.target.name === "particulars") {
      const product = [...productInfo];
      product[index].total = 0;
      for (var i = 0; i < books.length; i++) {
        if (books[i].bookInfo.name === event.target.value) {
          product[index].particulars = books[i].bookInfo.name;
          product[index].price = books[i].bookInfo.price;
          product[index].discount = books[i].bookInfo.discount;
          product[index].total =
            parseInt(product[index].price) * parseInt(product[index].quantity) -
            (parseInt(product[index].price) *
              parseInt(product[index].quantity) *
              parseInt(product[index].discount)) /
              100;
          setproductInfo(product);
        }
      }
    } else if (event.target.name === "isbn") {
      values[index].isbn = event.target.value;
    } else if (event.target.name === "quantity") {
      values[index].quantity = event.target.value;
      values[index].total =
        parseInt(values[index].price) * parseInt(values[index].quantity) -
        (parseInt(values[index].price) *
          parseInt(values[index].quantity) *
          parseInt(values[index].discount)) /
          100;
    } else if (event.target.name === "price") {
      values[index].price = event.target.value;
      values[index].total =
        parseInt(values[index].price) * parseInt(values[index].quantity) -
        (parseInt(values[index].price) *
          parseInt(values[index].quantity) *
          parseInt(values[index].discount)) /
          100;
    } else if (event.target.name === "discount") {
      values[index].discount = event.target.value;
      values[index].total =
        parseInt(values[index].price) * parseInt(values[index].quantity) -
        (parseInt(values[index].price) *
          parseInt(values[index].quantity) *
          parseInt(values[index].discount)) /
          100;
    } else {
      values[index].amount = event.target.value;
    }
    setproductInfo(values);
    var temp1 = 0;
    var temp2 = 0;
    for (var i = 0; i < productInfo.length; i++) {
      temp1 = temp1 + productInfo[i].total;
      temp2 = temp2 + parseInt(productInfo[i].quantity);
    }
    setnetAmount(temp1);
    settotalBooks(temp2);
  };

  const handleBillingInputChange = (event) => {
    const values = [...billingInfo];
    if (event.target.name === "billTo") {
      values[0].billTo = event.target.value;
      const billing = [...billingInfo];
      const shipping = [...shippingInfo];
      for (var i = 0; i < dealers.length; i++) {
        if (dealers[i].basicInfo[0].name === event.target.value) {
          billing[0].address = dealers[i].billingAddress[0].address;
          billing[0].contactPerson = dealers[i].basicInfo[0].primaryContactName;
          billing[0].phoneNo = dealers[i].basicInfo[0].phoneNo;
          billing[0].customerGST =
            dealers[i].basicInfo[0].customerGST === undefined
              ? ""
              : dealers[0].basicInfo[0].customerGST;
          shipping[0].shipTo = dealers[i].shippingAddress[0].name;
          shipping[0].address = dealers[i].shippingAddress[0].address;
          shipping[0].contactNo = dealers[i].shippingAddress[0].phone;
          setbillingInfo(billing);
          setshippingInfo(shipping);
        }
      }
    } else if (event.target.name === "address") {
      values[0].address = event.target.value;
    } else if (event.target.name === "contactPerson") {
      values[0].contactPerson = event.target.value;
    } else if (event.target.name === "cust-gst-no") {
      values[0].customerGST = event.target.value;
    } else {
      values[0].phoneNo = event.target.value;
    }

    setbillingInfo(values);
  };
  const handleShippingInputChange = (event) => {
    const values = [...shippingInfo];
    if (event.target.name === "shipTo") {
      values[0].shipTo = event.target.value;
    } else if (event.target.name === "address") {
      values[0].address = event.target.value;
    } else if (event.target.name === "contactNo") {
      values[0].contactNo = event.target.value;
    } else if (event.target.name === "grNo") {
      values[0].grNo = event.target.value;
    } else {
      values[0].weight = event.target.value;
    }

    setshippingInfo(values);
  };
  const handleInvoiceInputChange = (event) => {
    const values = [...invoiceInfo];
    if (event.target.name === "invoiceNo") {
      values[0].invoiceNo = event.target.value;
    } else if (event.target.name === "invoiceDate") {
      values[0].invoiceDate = event.target.value;
    }
    // else if (event.target.name === "custGSTNo") {
    //   values[0].custGSTNo = event.target.value;
    // }
    else if (event.target.name === "dated") {
      values[0].dated = event.target.value;
    } else if (event.target.name === "transporter") {
      values[0].transporter = event.target.value;
    } else if (event.target.name === "courier") {
      values[0].courier = event.target.value;
    } else if (event.target.name === "freightCost") {
      values[0].freightCost = event.target.value;
    } else if (event.target.name === "roundOff") {
      values[0].roundOff = event.target.value;
    } else {
      values[0].noOfBoxes = event.target.value;
    }

    setinvoiceInfo(values);
  };

  console.log("initialSubProducts", initialSubProducts);

  const handleSubmit = (e) => {
    const values = [...invoiceInfo];
    console.log("values: ", values);
    const date = values[0]?.dated?.split("-");
    // values[0].dated = date[2] + "-" + date[1] + "-" + date[0];
    setinvoiceInfo(values);
    console.log(billings[0].invoiceInfo);
    // values[0].invoiceNo = billings[0].invoiceInfo[0].invoiceNo + 1;
    setinvoiceInfo(values);
    var billTo = billTo ? billTo : "";
    // let netAmount = subProducts.reduce(function (sum, current) {
    //   return sum + parseFloat(current.total);
    // }, 0);

    let selectedProducts = subProducts
      .filter((sub_product) => {
        return sub_product.quantity && sub_product.quantity != 0;
      })
      .map((sub_product) => {
        if (sub_product.quantity) {
          calcTotalBooks = calcTotalBooks + parseInt(sub_product.quantity);
        }
        return {
          particulars:
            types === "Books"
              ? sub_product.bookInfo.name
              : sub_product.giftInfo.name,
          amount:
            types === "Books"
              ? sub_product.bookInfo.price
              : sub_product.giftInfo.price,
          price:
            types === "Books"
              ? sub_product.bookInfo.price
              : sub_product.giftInfo.price,
          total: sub_product.total,
          isbn: 0,
          quantity: sub_product.quantity ? sub_product.quantity : 0,
          discount: sub_product.discount ? sub_product.discount : 0,
        };
      });

    selectedProducts = selectedProducts.map((p) => {
      if (intialproduct?.some((t) => t.particulars === p.particulars)) {
        return {
          ...p,
          quantity: p.quantity,
          total:
            parseInt(p.price) * parseInt(p.quantity) -
            (parseInt(p.price) * parseInt(p.quantity) * parseInt(p.discount)) /
              100,
        };
      } else {
        return p;
      }
    });

    const updatedInitalProduct = intialproduct.filter(
      (p) => !selectedProducts.some((t) => t.particulars === p.particulars)
    );

    selectedProducts =
      updatedInitalProduct.length > 0
        ? [...updatedInitalProduct, ...selectedProducts]
        : selectedProducts;
    let netAmount = selectedProducts.reduce(function (sum, current) {
      return sum + parseFloat(current.total);
    }, 0);
    let calcTotalBooks = selectedProducts.reduce(function (sum, current) {
      return sum + parseFloat(current.quantity);
    }, 0);
    console.log("selectedProducts", selectedProducts);
    console.log("invoiceInfo: ", invoiceInfo);
    // console.log("book data==",  {
    //   billingInfo,
    //   shippingInfo,
    //   invoiceInfo,
    //   productInfo: selectedProducts,
    // billTo: billingInfo[0].billTo,
    // totalBooks: calcTotalBooks,

    //   netAmount,
    //   timeStamp: Date.now(),
    //   type:types,
    // subType,
    // });
    firestore
      .collection("billings")
      .doc(match.params.id)
      .set(
        {
          billingInfo,
          shippingInfo,
          invoiceInfo,
          productInfo: selectedProducts,
          billTo: billingInfo[0].billTo,
          totalBooks: calcTotalBooks,

          netAmount,
          timeStamp: Date.now(),
          type: types,
          subType,
        },
        { merge: true }
      )
      .catch((err) => {})
      .then(() => {
        history.push("/admin/billings");
      });
  };
  const getTotal = () => {
    var temp = 0;
    for (var i = 0; i < productInfo.length; i++) {
      temp = temp + productInfo[i].total;
    }
    setnetAmount(temp);
  };
  return (
    <>
      <EditBillingHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Please fill the required details</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <h6 className="heading-small text-muted mb-4">
                    Bill information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Bill To
                          </label>
                          <TextField
                            id="outlined-select-currency-native"
                            fullWidth
                            size="small"
                            style={{
                              backgroundColor: "#fff",
                              color: "#8898aa",
                            }}
                            select
                            placeholder="Bill To"
                            name="billTo"
                            value={billingInfo[0].billTo}
                            onChange={(e) => handleBillingInputChange(e)}
                            onch
                            SelectProps={{
                              native: true,
                            }}
                            helperText="Please fill this out"
                            variant="outlined"
                          >
                            {dealers.map((option) => (
                              <option
                                key={option.key}
                                value={option.basicInfo[0].name}
                              >
                                {option.basicInfo[0].name}
                              </option>
                            ))}
                          </TextField>
                          {/* <Input
                            className="form-control-alternative"
                            id="input-username"
                            required
                            name="billTo"
                            value={billingInfo.billTo}
                            onChange={(e) => handleBillingInputChange(e)}
                            placeholder="Bill To"
                            type="text"
                          /> */}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Address
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-email"
                            name="address"
                            value={billingInfo[0].address}
                            onChange={(e) => handleBillingInputChange(e)}
                            placeholder="Address"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Contact Person
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            name="contactPerson"
                            value={billingInfo[0].contactPerson}
                            onChange={(e) => handleBillingInputChange(e)}
                            placeholder="Contact Person"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Phone No./ Mob. No.
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-email"
                            name="phoneNo"
                            value={billingInfo[0].phoneNo}
                            onChange={(e) => handleBillingInputChange(e)}
                            placeholder="Phone Number"
                            type="number"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Customer GST No.
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-first-name"
                            name="cust-gst-no"
                            value={billingInfo[0].customerGST}
                            onChange={(e) => handleBillingInputChange(e)}
                            placeholder="Cust GST Number"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <h6 className="heading-small text-muted mb-4">
                    Shipping information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Ship To
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-first-name"
                            name="shipTo"
                            value={shippingInfo[0].shipTo}
                            onChange={(e) => handleShippingInputChange(e)}
                            placeholder="Ship To"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Shipping Address...
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-last-name"
                            name="address"
                            value={shippingInfo[0].address}
                            onChange={(e) => handleShippingInputChange(e)}
                            placeholder="Address"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Contact No.
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-first-name"
                            name="contactNo"
                            value={shippingInfo[0].contactNo}
                            onChange={(e) => handleShippingInputChange(e)}
                            placeholder="Contact No"
                            type="number"
                          />
                        </FormGroup>
                      </Col>
                      {/* <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            G.R. No
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-last-name"
                            name="grNo"
                            value={shippingInfo[0].grNo}
                            onChange={(e) => handleShippingInputChange(e)}
                            placeholder="G R. No."
                            type="text"
                          />
                        </FormGroup>
                      </Col> */}
                    </Row>
                    {/* <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Weight
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-first-name"
                            name="weight"
                            value={shippingInfo[0].weight}
                            onChange={(e) => handleShippingInputChange(e)}
                            placeholder="Weight"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row> */}
                  </div>
                  <h6 className="heading-small text-muted mb-4">
                    Invoice information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Dated
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-last-name"
                            name="dated"
                            value={invoiceInfo[0].dated}
                            onChange={(e) => handleInvoiceInputChange(e)}
                            placeholder="Dated"
                            type="date"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            POSTAGE & COURIER
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-last-name"
                            name="courier"
                            value={invoiceInfo[0].courier}
                            onChange={(e) => handleInvoiceInputChange(e)}
                            placeholder="POSTAGE & COURIER"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      {/* <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Transporter
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-first-name"
                            name="transporter"
                            value={invoiceInfo[0].transporter}
                            onChange={(e) => handleInvoiceInputChange(e)}
                            placeholder="Transporter"
                            type="text"
                          />
                        </FormGroup>
                      </Col> */}
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            FREIGHT COST
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-first-name"
                            name="freightCost"
                            value={invoiceInfo[0].freightCost}
                            onChange={(e) => handleInvoiceInputChange(e)}
                            placeholder="FREIGHT COST"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Round Off
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-first-name"
                            name="roundOff"
                            value={invoiceInfo[0].roundOff}
                            onChange={(e) => handleInvoiceInputChange(e)}
                            placeholder="Round Off"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  <Row>
                    <div className="col">
                      <Card className="shadow">
                        <CardHeader className="border-0">
                          <Row>
                            <Col xs="10">
                              <h3 className="mb-0">
                                Product Information &emsp;
                                <select
                                  style={{
                                    backgroundColor: "#fff",
                                    color: "#8898aa",
                                    borderRadius: "4px",
                                    paddingTop: "6px",
                                    paddingBottom: "6px",
                                    paddingLeft: "12px",
                                    paddingRight: "12px",
                                  }}
                                  onChange={(e) => {
                                    setTypes(e.target.value);
                                    setSubType("");
                                    setFields([]);
                                  }}
                                  value={types}
                                >
                                  <option
                                    selected="true"
                                    style={{ display: "none" }}
                                  >
                                    Select
                                  </option>
                                  {products.map((product) => {
                                    return (
                                      <option value={product.value}>
                                        {product.name}
                                      </option>
                                    );
                                  })}
                                </select>{" "}
                                &emsp;
                                <select
                                  style={{
                                    backgroundColor: "#fff",
                                    color: "#8898aa",
                                    borderRadius: "4px",
                                    paddingTop: "6px",
                                    paddingBottom: "6px",
                                    paddingLeft: "12px",
                                    paddingRight: "12px",
                                  }}
                                  onChange={(e) => {
                                    setSubType(e.target.value);
                                    // let newFields = [...fields];
                                    let filteredProducts = [];
                                    setSubProducts([]);
                                    if (e.target.value === initialSubType) {
                                      setSubProducts(initialSubProducts);
                                      return;
                                    }
                                    if (types === "Books") {
                                      let XProducts = newBooks.filter(
                                        (book) => {
                                          return (
                                            book.bookType === e.target.value
                                          );
                                        }
                                      );
                                      filteredProducts = XProducts.map(
                                        (product) => {
                                          return {
                                            ...product,
                                            quantity: 0,
                                            total: 0,
                                            price: product.bookInfo.price,
                                            discount: product.bookInfo.discount,
                                          };
                                        }
                                      );
                                    } else {
                                      filteredProducts = newGifts
                                        .filter((gift) => {
                                          return (
                                            gift.giftType === e.target.value
                                          );
                                        })
                                        .map((product) => {
                                          return {
                                            ...product,
                                            quantity: 0,
                                            total: 0,
                                            price: product.giftInfo.price,
                                            discount: product.giftInfo.discount,
                                          };
                                        });
                                    }
                                    console.log(
                                      "filteredProducts: ",
                                      filteredProducts
                                    );
                                    setSubProducts(filteredProducts);
                                  }}
                                  value={subType}
                                  disabled={types === "0"}
                                >
                                  <option
                                    selected="true"
                                    style={{ display: "none" }}
                                    value={"0"}
                                  >
                                    Select
                                  </option>

                                  {types === "Books" && (
                                    <>
                                      {products[0].sub_products.map(
                                        (sub_product) => {
                                          return (
                                            <option value={sub_product.name}>
                                              {sub_product.name}
                                            </option>
                                          );
                                        }
                                      )}
                                    </>
                                  )}

                                  {types === "Gifts" && (
                                    <>
                                      {products[1].sub_products.map(
                                        (sub_product) => {
                                          return (
                                            <option value={sub_product.name}>
                                              {sub_product.name}
                                            </option>
                                          );
                                        }
                                      )}
                                    </>
                                  )}
                                </select>
                              </h3>
                            </Col>
                            <Col xs="">
                              <Button
                                color="primary"
                                href="#pablo"
                                onClick={() => handleAddFields()}
                                size="sm"
                              >
                                Add New
                              </Button>
                            </Col>
                          </Row>
                        </CardHeader>

                        {types &&
                          subType &&
                          subProducts &&
                          subProducts.length > 0 && (
                            <Table
                              className="align-items-center table-flush"
                              responsive
                            >
                              <thead className="thead-light">
                                <tr>
                                  <th scope="col">Id</th>
                                  <th scope="col">{types} Name</th>
                                  {/* <th scope="col">ISBN</th> */}
                                  <th scope="col">Price</th>
                                  <th scope="col"> Fixed Disc. %</th>
                                  <th scope="col">Quantity</th>
                                  <th scope="col">Total</th>
                                  <th scope="col">Options</th>
                                </tr>
                              </thead>
                              {subProducts.map((sub_product, index) => {
                                return (
                                  <tbody>
                                    <tr>
                                      <th scope="row">
                                        <Media className="align-items-center">
                                          <span className="mb-0 text-sm">
                                            {index + 1}
                                          </span>
                                        </Media>
                                      </th>
                                      <td>
                                        <TextField
                                          value={
                                            types === "Books"
                                              ? sub_product.bookInfo.name
                                              : sub_product.giftInfo.name
                                          }
                                          InputProps={{
                                            readOnly: true,
                                          }}
                                          style={{ width: "max-content" }}
                                          variant="outlined"
                                          size="small"
                                        />
                                      </td>
                                      <td>
                                        <Input
                                          className="form-control-alternative"
                                          id="price"
                                          style={{ minWidth: "100px" }}
                                          placeholder="Price"
                                          min={0}
                                          name="price"
                                          type="number"
                                          value={
                                            types === "Books"
                                              ? sub_product.bookInfo.price
                                              : sub_product.giftInfo.price
                                          }
                                          onChange={(event) =>
                                            handleProductInputChange(
                                              index,
                                              event
                                            )
                                          }
                                        />
                                      </td>
                                      <td>
                                        <Input
                                          className="form-control-alternative"
                                          id="discount"
                                          style={{ minWidth: "100px" }}
                                          placeholder="discount"
                                          name="discount"
                                          type="number"
                                          min={0}                                          value={sub_product.discount}
                                          onChange={(event) => {
                                            let newProducts = [...subProducts];
                                            let totalPrice =
                                              sub_product.quantity *
                                              sub_product.price;
                                            let priceAfterDiscount =
                                              totalPrice *
                                              ((100 - event.target.value) /
                                                100);
                                            newProducts[index] = {
                                              ...newProducts[index],
                                              discount: event.target.value,
                                              // quantity:event.target.value,
                                              total: priceAfterDiscount,
                                            };
                                            setSubProducts(newProducts);
                                            // handleProductInputChange(index, event)
                                          }}
                                        />
                                      </td>
                                      <td>
                                        <Input
                                          className="form-control-alternative"
                                          id="quantity"
                                          style={{ minWidth: "100px" }}
                                          name="quantity"
                                          placeholder="Quantity"
                                          type="number"
                                          min={0}
                                          value={sub_product.quantity}
                                          onChange={(event) => {
                                            let newProducts = [...subProducts];
                                            let enteredQuantity = !!event.target
                                              .value
                                              ? event.target.value
                                              : 0;
                                            let totalPrice =
                                              enteredQuantity *
                                              sub_product.price;
                                            let priceAfterDiscount =
                                              totalPrice *
                                              ((100 - sub_product.discount) /
                                                100);

                                            newProducts[index] = {
                                              ...newProducts[index],
                                              quantity: event.target.value,
                                              total: priceAfterDiscount,
                                            };
                                            setSubProducts(newProducts);
                                            // let newValue = JSON.parse(
                                            //   event.target.value
                                            //   )
                                            // let price = types === "Books"
                                            // ? newValue
                                            // .bookInfo.price
                                            //   : newValue
                                            //       .giftInfo.price
                                            //
                                            //
                                            //       newFields[index] = {
                                            //   ...newFields[index],
                                            //   value: newValue,
                                            //     total:price * ( (100-25) / 100 ),
                                            //   quantity:1
                                            // };
                                            // setFields(newFields);

                                            console.log(
                                              "event: ",
                                              event,
                                              "???",
                                              event.target.value,
                                              "???????"
                                            );
                                          }}
                                        />
                                      </td>
                                      <td>
                                        {/*{(types === "Books"
                                        ? field.value.bookInfo.price
                                        : field.value.giftInfo.price) *
                                      field.quantity}*/}
                                        {parseFloat(sub_product.total).toFixed(
                                          2
                                        )}
                                      </td>
                                      <td className="text-right">
                                        <UncontrolledDropdown>
                                          <DropdownToggle
                                            className="btn-icon-only text-light"
                                            href="#pablo"
                                            role="button"
                                            size="sm"
                                            color=""
                                            onClick={(e) => e.preventDefault()}
                                          >
                                            <i className="fas fa-ellipsis-v" />
                                          </DropdownToggle>
                                          <DropdownMenu
                                            className="dropdown-menu-arrow"
                                            right
                                          >
                                            <DropdownItem
                                              href="#pablo"
                                              onClick={(e) =>
                                                e.preventDefault()
                                              }
                                            >
                                              View Details
                                            </DropdownItem>
                                            <DropdownItem
                                              href="#pablo"
                                              onClick={(e) =>
                                                handleRemoveFields(index)
                                              }
                                            >
                                              Delete
                                            </DropdownItem>
                                            <DropdownItem
                                              href="#pablo"
                                              onClick={(e) =>
                                                e.preventDefault()
                                              }
                                            >
                                              Something else here
                                            </DropdownItem>
                                          </DropdownMenu>
                                        </UncontrolledDropdown>
                                      </td>
                                    </tr>
                                  </tbody>
                                );
                              })}
                            </Table>
                          )}

                        <CardFooter className="py-4">
                          <Row>
                            <Col xs="6" />
                            <Col xs="6">
                              <Row>
                                <Col>
                                  <h4>POSTAGE & COURIER :</h4>
                                </Col>
                                <Col>
                                  <h4>{invoiceInfo[0].courier}</h4>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <h4>FREIGHT COST :</h4>
                                </Col>
                                <Col>
                                  <h4>{invoiceInfo[0].freightCost}</h4>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <h4>Round Off :</h4>
                                </Col>
                                <Col>
                                  <h4>{invoiceInfo[0].roundOff}</h4>
                                </Col>
                              </Row>
                              <hr></hr>
                              <Row>
                                <Col>Net Amount</Col>
                                <Col>
                                  {Math.round(
                                    subProducts.reduce(function (sum, current) {
                                      return sum + parseFloat(current.total);
                                    }, 0)
                                  )}
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </CardFooter>
                      </Card>
                    </div>
                  </Row>
                </Form>
                <Row></Row>
              </CardBody>
              <CardFooter>
                <Button
                  color="success"
                  href="#pablo"
                  onClick={() => handleSubmit()}
                  size="m"
                >
                  Save Billing
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EditBilling;
