import React, { useState, useEffect } from 'react';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
    Badge,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    Table,
    UncontrolledTooltip,
  } from "reactstrap";
import DealerProfileHeader from "components/Headers/DealerProfileHeader";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import moment from "moment";
import { firestore } from "../../config/Fire"
import { useLocation, useParams, useHistory } from "react-router-dom";
import {Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"

function DealerProfile({match}) {
    const history = useHistory();
    const location = useLocation();
    const [basicInfo, setbasicInfo] = useState([]);
    const [dealerBillings, setdealerBillings] = useState([]);
    const [creditNotes, setcreditNotes] = useState([]);
    const myParam = location.state.billTo;
    const [key, setkey] = useState("");
  
    useEffect(async () => {
      var v="";
            await firestore
            .collection("billings")
            .where("billTo","==",myParam)
            .onSnapshot(querrySnapshots => {
              if(!querrySnapshots.empty) {
                const dealerBillings = [];
                querrySnapshots.forEach(documentSnapshots => {
                  let tmp=documentSnapshots.data();
                    dealerBillings.push({
                        ...documentSnapshots.data(),
                        key: documentSnapshots.id,
                    })
                })
                const sortByDate = arr => {
                  const sorter = (a, b) => {
                    return new Date(a.timeStamp).getTime() - new Date(b.timeStamp).getTime();
                  }
                  arr.sort(sorter);
                };
                v=dealerBillings[0].billTo
                console.log(dealerBillings);
                sortByDate(dealerBillings);
                let reverseArray = [];
                for(let i=dealerBillings.length-1;i>=0;i--) reverseArray.push(dealerBillings[i]);
                // dealerBillings= dealerBillings.reverse();
                setdealerBillings(reverseArray);
                setbasicInfo(dealerBillings[0].billingInfo[0]);
              }
            });
            const creditNotes = [];
            await firestore
            .collection("credit-notes")
            .onSnapshot(querrySnapshots => {
              const creditNotes = [];
              querrySnapshots.forEach(documentSnapshots1 => {
                console.log(documentSnapshots1.data().billingInfo[0]?.to== v);
                if(documentSnapshots1.data().billingInfo[0]?.to==v){
                  console.log(documentSnapshots1.data());
                  creditNotes.push({
                    ...documentSnapshots1.data(),
                    key: documentSnapshots1.id,
                  });
                }
              });
              console.log("CREDITS");
              console.log(creditNotes);
              setcreditNotes(creditNotes);
            })
    }, []);

    const [modal, setModal] = useState(false);
    const handleDelete = (key) => {
        firestore
        .collection("billings")
        .doc(key)
        .delete()
        .then(res => {
          setModal(!modal);
        })
      }
    const handleModal = (key) => {
      setkey(key);
      setModal(!modal);
  
    } 
    const getTotalAmount = () => {
      console.log(dealerBillings);
        let amount = 0;
            for(var i = 0;i< dealerBillings.length;i++) {
                amount = amount+dealerBillings[i].netAmount
                console.log(dealerBillings[i].netAmount)
            }
            let num = (Math.round(amount * 100) / 100).toFixed(2)
            return  num;
    }

    const getPendingAmount = () => {
      console.log(dealerBillings);
      console.log(creditNotes);
        let amount = 0;
        let paid=0
            for(var i = 0;i< dealerBillings.length;i++) {
                amount = amount+dealerBillings[i].netAmount
                console.log(dealerBillings[i].netAmount)
            }
            let num = (Math.round(amount * 100) / 100).toFixed(2)
            for(var i = 0;i< creditNotes.length;i++) {
              console.log(creditNotes[i].price);
              paid = paid+parseInt(creditNotes[i].price)
          }

          let num2 = (Math.round(paid * 100) / 100).toFixed(2)
            // return  (num-(paid+parseInt(getCredit())))?(num-paid+parseInt(getCredit())):num;
            if(isNaN(num2)) return num;
            else return(num-num2);
    }


    const Download=()=>{
      const downloadPdfDocument = (rootElementId) => {
        const input = document.getElementById(rootElementId);
        html2canvas(input)
          .then((canvas) => {
              const imgData = canvas.toDataURL('image/png');
              const pdf = new jsPDF();
              pdf.addImage(imgData, 'JPEG', 0, 0);
              pdf.save("download.pdf");
          })
      }
    }
    const getCredit=()=>{
      var total=0
      console.log("HHHHHHHHHHHHHHH");
      creditNotes.map((i,j)=>{
        total+=(i.netAmount+parseInt(i?.price))
      })
      return total
    }
    return (
        <>
        <DealerProfileHeader />
        <Modal isOpen={modal} toggle={handleModal}>
        <ModalHeader toggle={handleModal}>Alert</ModalHeader>
        <ModalBody>
          Are you sure?
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => handleDelete(key)}>Confirm</Button>
          <Button color="secondary" onClick={handleModal}>Cancel</Button>
        </ModalFooter>
      </Modal>
        <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
            <Card className="card-profile shadow">
              <Row className="justify-content-center">
                <Col className="order-lg-2" lg="3">
                  <div className="card-profile-image bg-cyan">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="rounded-circle"
                        src={
                          require("../../assets/img/logo.png")
                            .default
                        }
                      />
                    </a>
                  </div>
                </Col>
              </Row>
              <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                <div className="d-flex justify-content-between">
                  <Button
                    className="mr-4"
                    color="info"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    size="sm"
                  >
                    Connect
                  </Button>
                  <Button
                    className="float-right"
                    color="default"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    size="sm"
                  >
                    Message
                  </Button>
                </div>
              </CardHeader>
              <CardBody className="pt-0 pt-md-4">
                <Row>
                  <div className="col">
                    <div className="card-profile-stats d-flex justify-content-center mt-md-5">
                      <div>
                        <span className="heading">{dealerBillings.length}</span>
                        <span className="description">Bills</span>
                      </div>
                      <div>
                        <span className="heading">{getTotalAmount()}</span>
                        <span className="description">TotalAmount</span>
                      </div>
                      <div>
                        <span className="heading">{getPendingAmount()}</span>
                        <span className="description">Pending</span>
                      </div>
                      <div>
                        <span className="heading">{getCredit()}</span>
                        <span className="description">Credits</span>
                      </div>
                    </div>
                  </div>
                </Row>
                <div className="text-center">
                  <h3>
                  
                    <span className="font-weight-light">{basicInfo?.billTo}</span>
                  </h3>
                  {/* <div className="h5 font-weight-300">
                    <i className="ni ni-email-83" />
                    <span className="font-weight-light ml-1">{basicInfo?.email}</span>
                  </div> */}
                  <div className="h5 mt-4">
                    <i className="ni business_briefcase-24 mr-2" />
                    {basicInfo?.contactPerson}
                  </div>
                  <div>
                    <i className="ni education_hat mr-2" />
                    {basicInfo?.address}
                  </div>
                  <hr className="my-4" />
                  {/* <p>
                    Ryan — the name taken by Melbourne-raised, Brooklyn-based
                    Nick Murphy — writes, performs and records all of his own
                    music.
                  </p> */}
                  <a href="#pablo" onClick={() => history.push(`/invoice3/${location.pathname.split('/').at(-1)}/${myParam}`)}>
                   Ledger
                  </a>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col className="order-xl-1" xl="8">
          <div className="col">
            <Card className="bg-default shadow">
              <CardHeader className="bg-transparent border-0">
                <h3 className="text-white mb-0">Total Billings</h3>
              </CardHeader>
              <Table
                className="align-items-center table-dark table-flush"
                responsive
              >
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">Bill Date</th>
                    <th scope="col">Bill To</th>
                    <th scope="col">Ship To</th>
                    <th scope="col">Invoice No.</th>
                    <th scope="col">Dated</th>
                    <th scope="col">Status</th>
                    <th scope="col">Amount</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                {
                  dealerBillings.map((item, index) => (
                  <tr>
                    <th scope="row">
                      <Media className="align-items-center">
                          <span className="mb-0 text-sm">
                          {moment(item?.timeStamp).date() +"/" + parseInt(moment(item?.timeStamp).month()+1) + "/" + moment(item?.timeStamp).year() + ", "}
                            {moment(item?.timeStamp).format('LT')}
                          </span>
                      </Media>
                    </th>
                    <th scope="row">
                    {item?.billingInfo[0]?.billTo}
                    </th>
                    <td>{item?.shippingInfo[0]?.shipTo}</td>
                    <td>{item?.invoiceInfo[0]?.invoiceNo}</td>
                    <td>{item?.invoiceInfo[0]?.dated}</td>
                    <td>
                      <Badge color="" className="badge-dot mr-4">
                        <i className="bg-warning" />
                        pending
                      </Badge>
                    </td>
                    <td>{item?.netAmount}</td>
                    <td className="text-right">
                      <UncontrolledDropdown>
                        <DropdownToggle
                          className="btn-icon-only text-light"
                          href="#pablo"
                          role="button"
                          size="sm"
                          color=""
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fas fa-ellipsis-v" />
                        </DropdownToggle>
                      </UncontrolledDropdown>
                    </td>
                  </tr>
                  ))
                }
                </tbody>
              </Table>
            </Card>
          </div>
          </Col>
        </Row>
      </Container>
        </>
    )
}

export default DealerProfile
