import React, { useEffect, useState } from "react";
import { firestore, firebaseAuth } from "../../config/Fire";
import moment from "moment";
 // reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { useHistory, useLocation } from "react-router-dom";
import BooksHeader from "components/Headers/BooksHeader";

const Books = () => {
  const history = useHistory();
  const [books, setBooks] = useState([]);
  const [isOpen, setModalOpen] = useState(false);
  const [key, setkey] = useState("");
  
  useEffect(() => {
    firestore
    .collection("new_books")
    .onSnapshot(querrySnapshots => {
      const books = [];
      querrySnapshots.forEach(documentSnapshots => {
        books.push({
          ...documentSnapshots.data(),
          key: documentSnapshots.id,
        });
      });
      setBooks(books);
    });
  }, []);
  const handleDelete = (key) => {
    firestore
    .collection("new_books")
    .doc(key)
    .delete()
    .then(res => {
      setModalOpen(false);
    })
  }
  
  const handleModal = (key) => {
    setModalOpen(true);
    setkey(key);

  } 
  return (
    <>
    <div>
    <Modal isOpen={isOpen} toggle={handleModal}>
      <ModalHeader toggle={handleModal}>Alert</ModalHeader>
      <ModalBody>
        Are you sure?
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => handleDelete(key)}>Confirm</Button>
        <Button color="secondary" onClick={handleModal}>Cancel</Button>
      </ModalFooter>
    </Modal>
  </div>
      <BooksHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <div className="col">
            <Card className="bg-default shadow">
              <CardHeader className="bg-transparent border-0">
                <h3 className="text-white mb-0">Total Billings</h3>
              </CardHeader>
              <Table
                className="align-items-center table-dark table-flush"
                responsive
              >
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">S. No.</th>
                    <th scope="col">Book Name</th>
                    <th scope="col">Price</th>
                    <th scope="col">Discount %</th>
                    <th scope="col">Published on</th>
                    <th scope="col">Options</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                {
                  books.map((item, index) => (
                  <tr>
                    <td>{index+1}</td>
                    <th scope="row">
                      <Media className="align-items-center">
                          <span className="mb-0 text-sm">
                            {item?.bookInfo?.name}
                          </span>
                      </Media>
                    </th>
                    <td>{item?.bookInfo?.price}</td>
                    <td>{item?.bookInfo?.discount + " %"}</td>
                    <th scope="row">
                      <Media className="align-items-center">
                          <span className="mb-0 text-sm">
                            {moment(item?.timeStamp).format('lll')}
                          </span>
                      </Media>
                    </th>
                    <td className="text-right">
                      <UncontrolledDropdown>
                        <DropdownToggle
                          className="btn-icon-only text-light"
                          href="#pablo"
                          role="button"
                          size="sm"
                          color=""
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fas fa-ellipsis-v" />
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-arrow" right>
                          <DropdownItem
                            href="#pablo"
                            //onClick={() => history.push(`/invoice/${item.key}`, {params: item.key})}
                          >
                            View Details
                          </DropdownItem>
                          <DropdownItem
                            href="#pablo"
                            // onClick={(e) => e.preventDefault()}
                            onClick={() => {
                              console.log(item);
                              handleModal(item?.key)
                            }}
                          >
                            Delete
                          </DropdownItem>
                          <DropdownItem
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            // onClick={() => {
                            //   console.log(item);
                            //   handleModal(item?.key)
                            // }}
                            
                          >
                            Something else here
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </td>
                  </tr>
                  ))
                }
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Books;
