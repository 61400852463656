import React, { useState, useEffect } from "react";
import {
  Button,
  Badge,
  Card,
  CardHeader,
  CardFooter,
  CardBody,
  DropdownItem,
  DropdownMenu,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  UncontrolledTooltip,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import AddSchoolHeader from "components/Headers/AddSchoolHeader";
import { firebaseAuth, firestore } from "../../config/Fire";
import { useHistory, useLocation } from "react-router-dom";

const AddSchool = () => {
  const history = useHistory();
  const [billingInfo, setbillingInfo] = useState([
    {
      billTo: '',
      address: '',
      contactPerson: '',
      phoneNo: '',
    }
  ]);
  const [shippingInfo, setshippingInfo] = useState([
    {
      shipTo: '',
      address: '',
      contactNo: '',
      grNo: '',
      weight: '',
    }
  ]);
  const [invoiceInfo, setinvoiceInfo] = useState([
    {
      invoiceNo: '',
      invoiceDate: '',
      dated: '',
      transporter: '',
      courier: '',
      pvtMark: '',
      noOfBoxes: '',
    }
  ]);
  const [productInfo, setproductInfo] = useState([
    { 
      particulars: '', 
      isbn: '', 
      quantity: '',
      rate: '',
      discount: '',
      amount: '',
     }
  ]);

  const handleAddFields = () => {
    const values = [...productInfo];
    values.push({ 
      particulars: '', 
      isbn: 0, 
      quantity: 0,
      rate: 0,
      discount: 0,
      amount: 0,
     });
    setproductInfo(values);
  };

  const handleRemoveFields = index => {
    const values = [...productInfo];
    values.splice(index, 1);
    setproductInfo(values);
  };

  const handleProductInputChange = (index, event) => {
    console.log(event.target.name)
    const values = [...productInfo];
    if (event.target.name === "particulars") {
      values[index].particulars = event.target.value;
    }
    else if (event.target.name === "isbn") {
      values[index].isbn = event.target.value;
    }
    else if (event.target.name === "quantity") {
      values[index].quantity = event.target.value;
    }
    else if (event.target.name === "rate") {
      values[index].rate = event.target.value;
    }
    else if (event.target.name === "discount") {
      values[index].discount = event.target.value;
    }
    else {
      values[index].amount = event.target.value;
    }

    setproductInfo(values);
  };

  const handleBillingInputChange = (event) => {
    const values = [...billingInfo];
    if (event.target.name === "billTo") {
      values[0].billTo = event.target.value;
    }
    else if (event.target.name === "address") {
      values[0].address = event.target.value;
    }
    else if (event.target.name === "contactPerson") {
      values[0].contactPerson = event.target.value;
    }
    else {
      values[0].phoneNo = event.target.value;
    }

    setbillingInfo(values);
  }
  const handleShippingInputChange = (event) => {
    const values = [...shippingInfo];
    if (event.target.name === "shipTo") {
      values[0].shipTo = event.target.value;
    }
    else if (event.target.name === "address") {
      values[0].address = event.target.value;
    }
    else if (event.target.name === "contactNo") {
      values[0].contactNo = event.target.value;
    }
    else if (event.target.name === "grNo") {
      values[0].grNo = event.target.value;
    }
    else {
      values[0].weight = event.target.value;
    }

    setshippingInfo(values);
  }
  const handleInvoiceInputChange = (event) => {
    const values = [...invoiceInfo];
    if (event.target.name === "invoiceNo") {
      values[0].invoiceNo = event.target.value;
    }
    else if (event.target.name === "invoiceDate") {
      values[0].invoiceDate = event.target.value;
    }
    // else if (event.target.name === "custOrderRef") {
    //   values[0].custOrderRef = event.target.value;
    // }
    else if (event.target.name === "dated") {
      values[0].dated = event.target.value;
    }
    else if (event.target.name === "transporter") {
      values[0].transporter = event.target.value;
    }
    else if (event.target.name === "courier") {
      values[0].courier = event.target.value;
    }
    else if (event.target.name === "pvtMark") {
      values[0].pvtMark = event.target.value;
    }
    else {
      values[0].noOfBoxes = event.target.value;
    }

    setinvoiceInfo(values);
  }
  const handleSubmit = e => {
    firestore
    .collection("billings")
    .add({
      billingInfo,
      shippingInfo,
      invoiceInfo,
      productInfo,
      timeStamp: Date.now(),
    })
    .catch(err => {
      console.log(err)
    })
    .then(() => {
      history.push("/admin/tables");
    })
  };
  return (
    <>
      <AddSchoolHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">My account</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="primary"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                      size="sm"
                    >
                      Settings
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <h6 className="heading-small text-muted mb-4">
                    Bill information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Bill To
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            name="billTo"
                            value={billingInfo.billTo}
                            onChange={(e) => handleBillingInputChange(e)}
                            placeholder="Bill To"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Address
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-email"
                            name="address"
                            value={billingInfo.address}
                            onChange={(e) => handleBillingInputChange(e)}
                            placeholder="Address"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Contact Person
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            name="contactPerson"
                            value={billingInfo.contactPerson}
                            onChange={(e) => handleBillingInputChange(e)}
                            placeholder="Contact Person"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Phone No./ Mob. No.
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-email"
                            name="phoneNo"
                            value={billingInfo.phoneNo}
                            onChange={(e) => handleBillingInputChange(e)}
                            placeholder="Phone Number"
                            type="number"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <h6 className="heading-small text-muted mb-4">
                    Shipping information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Ship To
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-first-name"
                            name="shipTo"
                            value={shippingInfo.shipTo}
                            onChange={(e) => handleShippingInputChange(e)}
                            placeholder="Ship To"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Shipping Address
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-last-name"
                            name="address"
                            value={shippingInfo.address}
                            onChange={(e) => handleShippingInputChange(e)}
                            placeholder="Address"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Contact No.
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-first-name"
                            name="contactNo"
                            value={shippingInfo.contactNo}
                            onChange={(e) => handleShippingInputChange(e)}
                            placeholder="Contact No"
                            type="number"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            G.R. No
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-last-name"
                            name="grNo"
                            value={shippingInfo.grNo}
                            onChange={(e) => handleShippingInputChange(e)}
                            placeholder="G R. No."
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Weight
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-first-name"
                            name="weight"
                            value={shippingInfo.weight}
                            onChange={(e) => handleShippingInputChange(e)}
                            placeholder="Weight"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <h6 className="heading-small text-muted mb-4">
                    Invoice information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Invoice No.
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-first-name"
                            name="invoiceNo"
                            value={invoiceInfo.invoiceNo}
                            onChange={(e) => handleInvoiceInputChange(e)}
                            placeholder="Invoice Number"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Invoice Date
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-last-name"
                            name="invoiceDate"
                            value={invoiceInfo.invoiceDate}
                            onChange={(e) => handleInvoiceInputChange(e)}
                            placeholder="Invoice Date"
                            type="date"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      {/* <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Cust Order Ref
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-first-name"
                            name="custOrderRef"
                            value={invoiceInfo.custOrderRef}
                            onChange={(e) => handleInvoiceInputChange(e)}
                            placeholder="Cust Order Info"
                            type="text"
                          />
                        </FormGroup>
                      </Col> */}
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Dated
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-last-name"
                            name="dated"
                            value={invoiceInfo.dated}
                            onChange={(e) => handleInvoiceInputChange(e)}
                            placeholder="Dated"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Transporter
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-first-name"
                            name="transporter"
                            value={invoiceInfo.transporter}
                            onChange={(e) => handleInvoiceInputChange(e)}
                            placeholder="Transporter"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Courier
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-last-name"
                            name="courier"
                            value={invoiceInfo.courier}
                            onChange={(e) => handleInvoiceInputChange(e)}
                            placeholder="Courier"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Pvt Mark
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-first-name"
                            name="pvtMark"
                            value={invoiceInfo.pvtMark}
                            onChange={(e) => handleInvoiceInputChange(e)}
                            placeholder="PVT Mark"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            No of Boxes
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-last-name"
                            name="noOfBoxes"
                            value={invoiceInfo.noOfBoxes}
                            onChange={(e) => handleInvoiceInputChange(e)}
                            placeholder="No of Boxes"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  <Row>
                    <div className="col">
                      <Card className="shadow">
                        <CardHeader className="border-0">
                            <Row>
                              <Col xs="10">
                                <h3 className="mb-0">Product Information</h3>
                              </Col>
                              <Col xs="">
                                <Button
                                  color="primary"
                                  href="#pablo"
                                  onClick={() => handleAddFields()}
                                  size="sm"
                                >
                                  Add New
                                </Button>
                              </Col>
                            </Row>
                        </CardHeader>
                       
                        <Table className="align-items-center table-flush" responsive>
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">Id</th>
                              <th scope="col">Particulars</th>
                              <th scope="col">ISBN</th>
                              <th scope="col">Quantity</th>
                              <th scope="col">Rate</th>
                              <th scope="col">Disc. %</th>
                              <th scope="col">Amount</th>
                              <th scope="col">Options</th>
                            </tr>
                          </thead>
                          {productInfo.map((inputField, index) => (
                            <tbody>
                            <tr>
                              <th scope="row">
                                <Media className="align-items-center">
                                    <span className="mb-0 text-sm">
                                      {index+1}
                                    </span>
                                </Media>
                              </th>
                              <td>
                              <Input
                                className="form-control-alternative"
                                style={{minWidth:"100px"}}
                                placeholder="Particulars"
                                type="text"
                                id="particulars"
                                name="particulars"
                                value={inputField.particulars}
                                onChange={event => handleProductInputChange(index, event)}
                              />
                              </td>
                              <td>
                                <Badge color="" className="badge-dot mr-4">
                                  <Input
                                    className="form-control-alternative"
                                    id="isbn"
                                    style={{minWidth:"100px"}}
                                    name="isbn"
                                    placeholder="ISBN"
                                    type="number"
                                    value={inputField.isbn}
                                    onChange={event => handleProductInputChange(index, event)}
                                  />
                                </Badge>
                              </td>
                              <td>
                                <Input
                                  className="form-control-alternative"
                                  id="quantity"
                                  style={{minWidth:"100px"}}
                                  name="quantity"
                                  placeholder="Quantity"
                                  type="number"
                                  value={inputField.quantity}
                                  onChange={event => handleProductInputChange(index, event)}
                                />
                              </td>
                              <td>
                              <Input
                                className="form-control-alternative"
                                id="rate"
                                style={{minWidth:"100px"}}
                                placeholder="Rate"
                                name="rate"
                                type="number"
                                value={inputField.rate}
                                onChange={event => handleProductInputChange(index, event)}
                              />
                              </td>
                              <td>
                              <Input
                                className="form-control-alternative"
                                id="discount"
                                style={{minWidth:"100px"}}
                                placeholder="Discount"
                                name="discount"
                                type="number"
                                value={inputField.discount}
                                onChange={event => handleProductInputChange(index, event)}
                              />
                              </td>
                              <td>
                              <Input
                                className="form-control-alternative"
                                id="amount"
                                style={{minWidth:"100px"}}
                                placeholder="Amount"
                                name="amount"
                                type="number"
                                value={inputField.amount}
                                onChange={event => handleProductInputChange(index, event)}
                              />
                              </td>
                              <td className="text-right">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    className="btn-icon-only text-light"
                                    href="#pablo"
                                    role="button"
                                    size="sm"
                                    color=""
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    <i className="fas fa-ellipsis-v" />
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-arrow" right>
                                    <DropdownItem
                                      href="#pablo"
                                      onClick={(e) => e.preventDefault()}
                                    >
                                      Edit
                                    </DropdownItem>
                                    <DropdownItem
                                      href="#pablo"
                                      onClick={() => handleRemoveFields(index)}
                                    >
                                      Delete
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          </tbody>
                            ))}
                        </Table>
                        <CardFooter className="py-4">
                        </CardFooter>
                      </Card>
                    </div>
                  </Row>
                </Form>
              </CardBody>
              <CardFooter>
              <Button
                color="success"
                href="#pablo"
                onClick={() => handleSubmit()}
                size="m"
              >
                Save Billing
              </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AddSchool;
