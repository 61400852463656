import { firestore } from "config/Fire";
import { useEffect } from "react";
import { useState } from "react";

const useBooks = () => {
  const [books, setBooks] = useState([]);
  useEffect(() => {
    firestore.collection("books").onSnapshot((querySnapshots) => {
      const books = [];
      querySnapshots.forEach((documentSnapshots) => {
        books.push({
          ...documentSnapshots.data(),
          key: documentSnapshots.id,
        });
      });
      setBooks(books);
    });
  }, []);

  return { books };
};

export default useBooks;
