import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Table,
  Media,
  Badge,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from "reactstrap";
import { savePDF } from '@progress/kendo-react-pdf';
// core components
import InvoiceHeader from "components/Headers/InvoiceHeader";
import { firestore } from "../../config/Fire";
import { ToWords } from 'to-words';
import { useLocation, useParams, useHistory } from "react-router-dom";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import CurrencyFormat from 'react-currency-format';
import Converter from "number-to-words"
var converter = require('number-to-words');
const Invoice = ({match}) => {
    const location = useLocation();
    const history = useHistory();
    const [param, setparam] = useState("")
    const [basicInfo, setbasicInfo] = useState([]);
    const [dealerBillings, setdealerBillings] = useState([]);
    const [creditNotes, setcreditNotes] = useState([]);
    const myParam =decodeURIComponent(location.pathname.split('/').at(-1))
    const [key, setkey] = useState("");
    const [billData, setbillData] = useState([]);
    let { slug } = useParams();
    const toWords = new ToWords();
    useEffect(async () => {
      var v="";
            await firestore
            .collection("billings")
            .where("billTo","==",myParam)
            // .orderBy("billings", "asc")
            .onSnapshot(querrySnapshots => {
              if(!querrySnapshots.empty) {
                const dealerBillings = [];
                querrySnapshots.forEach(documentSnapshots => {
                  let tmp=documentSnapshots.data();
                    dealerBillings.push({
                        ...documentSnapshots.data(),
                        key: documentSnapshots.id,
                    })
                })
                v=dealerBillings[0].billTo
                console.log(dealerBillings);
                setdealerBillings(dealerBillings)
                setbasicInfo(dealerBillings[0].billingInfo[0]);
              }
            });
            const creditNotes = [];
            await firestore
            .collection("credit-notes")
            .onSnapshot(querrySnapshots => {
              const creditNotes = [];
              querrySnapshots.forEach(documentSnapshots1 => {
                console.log(documentSnapshots1.data().billingInfo[0]?.to== v);
                if(documentSnapshots1.data().billingInfo[0]?.to==v){
                  console.log(documentSnapshots1.data());
                  creditNotes.push({
                    ...documentSnapshots1.data(),
                    key: documentSnapshots1.id,
                  });
                }
              });
              console.log("CREDITS");
              console.log(creditNotes);
              setcreditNotes(creditNotes);
            })
    }, []);
const bodyRef = React.createRef();


const generatePDF = () => {
  window.print()
};


 const createPdf = (html) => {
    savePDF(html, { 
      fileName: 'form.pdf',
    })
  }

  const getCredit=()=>{
    var total=0
    console.log("HHHHHHHHHHHHHHH");
    creditNotes.map((i,j)=>{
      total+=i.netAmount
    })
    return total
  }

  const getTotalAmount = () => {
    console.log(dealerBillings);
      let amount = 0;
          for(var i = 0;i< dealerBillings.length;i++) {
              amount = amount+dealerBillings[i].netAmount
              console.log(dealerBillings[i].netAmount)
          }
          let num = (Math.round(amount * 100) / 100).toFixed(2)
          return  num;
  }

  const getPendingAmount = () => {
    console.log(dealerBillings);
      let amount = 0;
      let paid=0
          for(var i = 0;i< dealerBillings.length;i++) {
              amount = amount+dealerBillings[i].netAmount
              console.log(dealerBillings[i].netAmount)
          }
          let num = (Math.round(amount * 100) / 100).toFixed(2)
          for(var i = 0;i< creditNotes.length;i++) {
            console.log(creditNotes[i].price);
            paid = paid+parseInt(creditNotes[i].price)
        }
        let num2 = (Math.round(paid * 100) / 100).toFixed(2)
          return  (parseInt(getCredit())+parseInt(paid))?parseInt(getCredit())+parseInt(paid):0;
  }


  return (
    <>
      {/* <InvoiceHeader /> */}
      {/* Page content */}
      <button onClick={()=>createPdf(bodyRef.current)}>Download</button>
      <div ref={bodyRef}>
      <Container className="p-1" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row>
                  <Col>
                    <img
                        alt="..."
                        className="rounded-circle"
                        src={
                        require("../../assets/img/brand/yep.png")
                        .default
                        }
                        style={{width:240,height:120}}
                        onClick={() => generatePDF()}
                    />
                  </Col>
                  <Col className="text-center">
                  <Row className="text-center">
                    <Col className="text-center">
                      <h2 style={{fontSize: 28, fontWeight:"bold"}}>YEP Education Pvt. Ltd </h2>
                    </Col>
                  </Row>
                  <Row className="text-center">
                    <Col className="text-center mt-4">
                    <h4 style={{fontWeight:"bold"}}>Address: C-313, Raghubir Nagar New Delhi 110027</h4>
                    </Col>
                  </Row>
                  </Col>
                  <Col className="text-right">
                    <h3 style={{fontWeight:"bold"}}>GSTIN : 07AABCY3475H1Z4</h3>
                    <h3 style={{fontWeight:"bold"}}>HSN Code : 49011010</h3>
                    <h4 style={{fontWeight:"bold"}}>Email: info@yep.education</h4>
                    <h4 style={{fontWeight:"bold"}}>Web: www.yep.education</h4>
                    <h4 style={{fontWeight:"bold"}}>Mob. : 8826467474</h4>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="bg-white mt--4">

                <Row className="border border-darker">
                <Table className="align-items-center table-flush" responsive>
                  <thead className="">
                    <tr>
                      <th scope="col" style={{fontWeight:"bold", fontSize:16}}>BILL DATE</th>
                      <th scope="col" style={{fontWeight:"bold", fontSize:16}}>BILL TO</th>
                      <th scope="col" style={{fontWeight:"bold", fontSize:16}}>SHIP TO</th>
                      <th scope="col" style={{fontWeight:"bold", fontSize:16}}>INVOICE NO</th>
                      {/*<th scope="col" style={{fontWeight:"bold", fontSize:16}}>DATED</th>*/}
                      {/* <th scope="col" style={{fontWeight:"bold", fontSize:16}}>Debit</th> */}
                      <th scope="col" style={{fontWeight:"bold", fontSize:16}}>Credit</th>
                    </tr>
                  </thead>
                  {dealerBillings.map((item, index) => (
                            <tbody>
                            <tr >
                              <th scope="row">
                                <Media className="align-items-center">
                                    <span className="mb-0 text-sm" style={{fontWeight:"bold", fontSize:16}}>
                                    {moment(item?.timeStamp).format('ll')}
                                    </span>
                                </Media>
                              </th>
                              <td style={{fontWeight:"bold", fontSize:16}}>{item?.billingInfo[0]?.billTo}</td>
                              <td style={{fontWeight:"bold", fontSize:16}}>{item?.shippingInfo[0]?.shipTo}
                              </td>
                              <td style={{fontWeight:"bold", fontSize:16}}>{item?.invoiceInfo[0]?.invoiceNo}
                              </td>
                              {/* <td style={{fontWeight:"bold", fontSize:16}}>{item?.invoiceInfo[0]?.dated}</td> */}
                              <td style={{fontWeight:"bold", fontSize:16}}>{item?.netAmount}
                              </td>
                              <td style={{fontWeight:"bold", fontSize:16}}>{item?.paid}</td>

                            </tr>
                          </tbody>
                    ))}
                    <tfoot className="bg-white">
                      <tr>
                        <th scope="col" colSpan="4" style={{fontWeight:"bold", fontSize:16}}>By Closing Balance </th>
                        <th scope="col" style={{fontWeight:"bold", fontSize:16}}></th>
                        <th scope="col" style={{fontWeight:"bold", fontSize:16}}>{getTotalAmount()}</th>
                        <th scope="col" style={{fontWeight:"bold", fontSize:16}}>{getPendingAmount()}</th>
                      </tr>
                      <tr>
                      <th scope="col" style={{fontWeight:"bold", fontSize:16}}></th>
                      <th scope="col" style={{fontWeight:"bold", fontSize:16}}>Total</th>
                      <th scope="col" style={{fontWeight:"bold", fontSize:16}}>{getTotalAmount()-getPendingAmount()}</th>
                      <th scope="col" style={{fontWeight:"bold", fontSize:16}}></th>
                      </tr>
                    </tfoot>
                </Table>
                </Row>
                {billData?.invoiceInfo?.map((item, index) => (
                  <>
                  <Row className="border border-darker pt-1 pb-1">
                    <Col xs="7">
                      <h3 style={{fontWeight:"bold", fontSize:16}}>Customer GST No. : {billData?.billingInfo[0]?.customerGST}</h3>
                    </Col>
                    <Col>
                      <Row>
                        <Col style={{fontWeight:"bold", fontSize:16}}>POSTAGE & COURIER :</Col>
                        <Col style={{fontWeight:"bold", fontSize:16}}>{item.courier}</Col>
                      </Row>
                      <Row>
                        <Col style={{fontWeight:"bold", fontSize:16}}>FREIGHT COST :</Col>
                        <Col style={{fontWeight:"bold", fontSize:16}}>{item.freightCost}</Col>
                      </Row>
                      <Row>
                        <Col style={{fontWeight:"bold", fontSize:16}}>Round Off :</Col>
                        <Col style={{fontWeight:"bold", fontSize:16}}>{item.roundOff}</Col>
                      </Row>
                      <hr/>
                      <Row className="mt-2">
                        <Col style={{fontWeight:"bold", fontSize:16}}><h4>TOTAL(Rs.) :</h4></Col>
                        <Col style={{fontWeight:"bold", fontSize:16}}><h4>
                          <CurrencyFormat value={parseInt(item.courier)
                          +parseInt(item.freightCost)+item.roundOff+ Math.round(billData?.netAmount)} displayType={'text'} thousandSeparator={true} prefix={'₹'} />
                          </h4>
                        </Col>
                      </Row>
                      {/* <Row className="mt-2">
                        <Col style={{fontWeight:"bold", fontSize:16}}><h4>Amount in words :</h4></Col>
                        <Col style={{fontWeight:"bold", fontSize:16}}><h4>
                        {toWords.convert(parseInt(item.courier)
                    +parseInt(item.freightCost)+item.roundOff+billData?.netAmount, { currency: true })}
                          </h4>
                        </Col>
                      </Row> */}
                    </Col>
                </Row>
                <Row className="border border-darker justify-content-center">
                  <h2>Amount in words :</h2>
                 <h2 className="pl-4">
                 {toWords.convert(parseInt(item.courier)
                    +parseInt(item.freightCost)+item.roundOff+billData?.netAmount, { currency: true })}
                </h2>
                </Row>
                </>
                ))}
              </CardBody>
              <CardFooter className="mt--4">
                <Row>
                    <Col xs="8">
                      <h2>Terms and Conditions</h2>
                      <div>
                      <Row>
                      1. Goods once sold are not returnable
                      </Row>
                      <Row>
                      2. Our responsibility ceases as soon as goods leave our premises.
                      </Row>
                      <Row>
                      3. Subject to DELHI jurisdiction only
                      </Row>
                      <Row>
                      4. All payments to be made to YEP Education Private Limited.
                      </Row>

                      </div>
                    </Col>
                    <Col>
                      <h3>For YEP Education Private Limited</h3>
                      <div>
                      <Col>
                      <img
                        alt="..."
                        className="rounded-circle"
                        src={
                        require("../../assets/img/signature (1).jpg").default
                        }
                        style={{width:180,height:120}}
                    />
                    <h3>Authorised Signatory</h3>
                    </Col>

                      </div>
                    </Col>
                   
                </Row>
              </CardFooter>
              <Row className="border border-darker justify-content-center m-1">
              <Col md="2">Cash/Cheque</Col>
              <Col md="3">
                <Row>
                <Col>Beneficiary Name:</Col>
                <Col>YEP EDUCATION PVT LTD</Col>
                </Row>
              </Col>
              <Col>ICICI Bank Ltd. Vishal Encalve, New Delhi 110027, A/c No. 630105007738</Col>
              <Col md="2">IFSC Code: ICIC0006301</Col>                
               </Row>
            </Card>
          </Col>
        </Row>
      </Container>
      </div>
    </>
  );
};

export default Invoice;
