import firebase from 'firebase';
import 'firebase/auth';

const firebaseConfig = firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
});
// const firebaseConfig =firebase.initializeApp({
//     apiKey: "AIzaSyDdfPDHECPE43e1fuDMj9OyG2QoABowDEU",
//     authDomain: "billing-software-6b868.firebaseapp.com",
//     projectId: "billing-software-6b868",
//     storageBucket: "billing-software-6b868.appspot.com",
//     messagingSenderId: "90538085902",
//     appId: "1:90538085902:web:60109034a47cb6de94765f",
//     measurementId: "G-F1E66DNWQC"
//   });
const firestore = firebase.firestore();
const firebaseAuth = firebase.auth();
const storage = firebase.storage();

export { firestore, firebaseAuth,storage  };