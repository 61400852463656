import React, { useEffect, useState } from "react";
import { firestore, firebaseAuth } from "../../config/Fire";
import moment from "moment";
// reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { useHistory, useLocation } from "react-router-dom";
import BillingsHeader from "components/Headers/BillingsHeader";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import CurrencyFormat from 'react-currency-format';
const Billings = () => {
  const history = useHistory();
  const location = useLocation();
  const [billings, setbillings] = useState([]);
  const [key, setkey] = useState("");
  const [search, setsearch] = useState("");
  useEffect(() => {
    firestore
      .collection("billings")
      .orderBy("timeStamp", "desc")
      .onSnapshot(querrySnapshots => {
        const billings = [];
        querrySnapshots.forEach(documentSnapshots => {
          billings.push({
            ...documentSnapshots.data(),
            key: documentSnapshots.id,
          });
        });
        setbillings(billings);
      })
  }, []);
  console.log(billings);

  const handleDelete = (key) => {
    firestore
      .collection("billings")
      .doc(key)
      .delete()
      .then(res => {
        console.log(res)
        setModal(!modal);
      })
  }

  // const {
  //   buttonLabel,
  //   className
  // } = props;

  const [modal, setModal] = useState(false);

  const handleModal = (key) => {
    setkey(key);
    setModal(!modal);

  }
  const handleSearchInput = (event) => {
    console.log(event.target.value)
    setsearch(event.target.value);
    const values = []
    for (var i = 0; i < billings.length; i++) {
      console.log(billings[i].billTo.includes(event.target.value))
      if (billings[i].billTo.toLowerCase().includes(event.target.value.toLowerCase())) {
        values.push(billings[i])
      }
    }
    if (event.target.value === "") {
      firestore
        .collection("billings")
        .orderBy("timeStamp", "desc")
        .onSnapshot(querrySnapshots => {
          const billings = [];
          querrySnapshots.forEach(documentSnapshots => {
            billings.push({
              ...documentSnapshots.data(),
              key: documentSnapshots.id,
            });
          });
          setbillings(billings);
        })
    }
    console.log(values.length)
    setbillings(values)
  }

  console.log(billings)

  return (
    <>
      <div>
        <Modal isOpen={modal} toggle={handleModal}>
          <ModalHeader toggle={handleModal}>Alert</ModalHeader>
          <ModalBody>
            Are you sure?
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => handleDelete(key)}>Confirm</Button>
            <Button color="secondary" onClick={handleModal}>Cancel</Button>
          </ModalFooter>
        </Modal>
      </div>
      <div
        className="header pb-4 pt-5 pt-lg-2 d-flex align-items-center"
        style={{
          minHeight: "600px",
          backgroundImage:
            "url(" +
            require("../../assets/img/theme/template-cover.png").default +
            ")",
          backgroundSize: "cover",
          backgroundPosition: "center top",
        }}
      >
        {/* Mask */}
        <span className="mask bg-gradient-default opacity-8" />
        {/* Header container */}
        <Container className="d-flex align-items-center" fluid>
          <Row>
            <Col lg="7" md="4">
              <h1 className="display-2 text-white">Hey YEP</h1>
              <p className="text-white mt-0 mb-5">
                This is Billing page. You can use these form to create invoice.
              </p>
              <Button
                color="info"
                href="#pablo"
                onClick={() => history.push("/admin/addbilling")}
              >
                Add Billings
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
      {/* Page content */}
      <Container className="mt--9" fluid>
        <Row className="mt-5">
          <div className="col">
            <Card className="bg-default shadow">
              <CardHeader className="bg-transparent border-0">
                <Row>
                  <Col>
                    <h3 className="text-white mb-0">Total Billings</h3>
                  </Col>
                  <Col>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-search" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Search"
                        type="text"
                        name="search"
                        value={search}
                        onChange={(e) => handleSearchInput(e)}
                      />
                    </InputGroup>
                  </Col>
                </Row>
              </CardHeader>
              <Table
                className="align-items-center table-dark table-flush"
                responsive
              >
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">Bill Date</th>
                    <th scope="col">Bill To</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Total Books</th>
                    <th scope="col">Invoice No.</th>
                    {/* <th scope="col">Dated</th> */}
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {
                    billings.map((item, index) => {
                      return (
                        <tr>
                          <th scope="row">
                            <Media className="align-items-center">
                              <span className="mb-0 text-sm">
                                {moment(item?.timeStamp).date() + "/" + parseInt(moment(item?.timeStamp).month() + 1) + "/" + moment(item?.timeStamp).year() + ", "}
                                {moment(item?.timeStamp).format('LT')}
                              </span>
                            </Media>
                          </th>
                          <th scope="row" onClick={() => history.push(`/dealerbilling/${item.key}`, { billTo: item.billTo })}>
                            {item?.billingInfo[0]?.billTo}
                          </th>
                          <td>
                            <CurrencyFormat value={Math.round(item?.netAmount) + parseInt((item?.invoiceInfo[0]?.courier !== "" ? item?.invoiceInfo[0]?.courier : 0)) + parseInt((item?.invoiceInfo[0]?.freightCost !== "" ? item?.invoiceInfo[0]?.freightCost : 0))} displayType={'text'} thousandSeparator={true} prefix={'₹'} />
                          </td>
                          <td>{item?.totalBooks}</td>
                          <td>{item?.invoiceInfo[0]?.invoiceNo}</td>
                          {/* <td>{(item?.invoiceInfo[0]?.dated === "undefined-undefined-" ? null: item?.invoiceInfo[0]?.dated)}</td> */}
                          <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                href="#pablo"
                                role="button"
                                size="sm"
                                color=""
                                onClick={(e) => e.preventDefault()}
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-arrow" right>
                                <DropdownItem
                                  href="#pablo"
                                  onClick={() => history.push(`/invoice/${item.key}`, { params: item.key })}
                                >
                                  View Details
                                </DropdownItem>
                                <DropdownItem
                                  href="#pablo"
                                  onClick={(e) => history.push(`/editbilling/${item.key}`, { params: item.key })}
                                >
                                  Edit
                                </DropdownItem>
                                <DropdownItem
                                  href="#pablo"
                                  onClick={() => handleModal(item?.key)}
                                >
                                  Delete
                                </DropdownItem>
                                <DropdownItem
                                  href="#pablo"
                                  onClick={() => history.push(`/invoice2/${item.key}`, { params: item.key })}
                                >
                                  Download
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Billings;
