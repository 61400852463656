import Index from "views/Index.js";
import Profile from "views/examples/Profile.js";
import Maps from "views/examples/Maps.js";
import Register from "views/examples/Register.js";
import Login from "views/examples/Login.js";
import Billings from "views/examples/Billings";
import CreditNotes from "views/examples/CreditNotes";
import Dealers from "views/examples/Dealers";
import Schools from "views/examples/Schools";
import AddBilling from "views/forms/AddBilling";
import AddCreditNotes from "views/forms/AddCreditNotes";
import EditBilling from "views/forms/EditBilling";
import AddDealer from "views/forms/AddDealer";
import AddSchool from "views/forms/AddSchool";
import AddBook from "views/forms/AddBook";
import AddGift from "views/forms/AddGift";
import Icons from "views/examples/Icons.js";
import Invoice from "views/examples/Invoice";
import DataList from "views/examples/DataList";
import Books from "views/examples/Books";
import Gifts from "views/examples/Gifts";
import DealerProfile from "views/examples/DealerProfile";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin",
  },
  {
    path: "/billings",
    name: "Billings",
    icon: "ni ni-credit-card text-red",
    component: Billings,
    layout: "/admin",
  },
  {
    path: "/credit-notes",
    name: "Credit Notes",
    icon: "ni ni-credit-card text-red",
    component: CreditNotes,
    layout: "/admin",
  },
  {
    path: "/addbilling",
    name: "Add Billings",
    icon: "ni ni-paper-diploma text-blue",
    component: AddBilling,
    layout: "/admin",
  },
  {
    path: "/add-credit-notes",
    name: "Add Credit Notes",
    icon: "ni ni-paper-diploma text-blue",
    component: AddCreditNotes,
    layout: "/admin",
  },
  // {
  //   path: "/dealerprofile",
  //   name: "Dealer Profile",
  //   icon: "ni ni-paper-diploma text-blue",
  //   component: DealerProfile,
  //   layout: "/admin",
  // },
  // {
  //   path: "/editbilling",
  //   name: "Edit Billings",
  //   icon: "ni ni-paper-diploma text-blue",
  //   component: EditBilling,
  //   layout: "/admin",
  // },
  {
    path: "/dealers",
    name: "Dealers",
    icon: "ni ni-single-02 text-green",
    component: Dealers,
    layout: "/admin",
  },
  {
    path: "/schools",
    name: "Schools",
    icon: "ni ni-single-02 text-orange",
    component: Schools,
    layout: "/admin",
  },
  {
    path: "/add-dealer",
    name: "Add Dealer",
    icon: "ni ni-single-02 text-orange",
    component: AddDealer,
    layout: "/admin",
  },
  {
    path: "/add-school",
    name: "Add School",
    icon: "ni ni-single-02 text-orange",
    component: AddSchool,
    layout: "/admin",
  },
  {
    path: "/datalist",
    name: "DataList",
    icon: "ni ni-single-02 text-orange",
    component: DataList,
    layout: "/admin",
  },
  {
    path: "/add-book",
    name: "Add Book",
    icon: "ni ni-single-02 text-orange",
    component: AddBook,
    layout: "/admin",
  },
  {
    path: "/add-gift",
    name: "Add Gift",
    icon: "ni ni-single-02 text-orange",
    component: AddGift,
    layout: "/admin",
  },
  {
    path: "/books",
    name: "Books",
    icon: "ni ni-single-02 text-orange",
    component: Books,
    layout: "/admin",
  },
  {
    path: "/gifts",
    name: "Gifts",
    icon: "ni ni-single-02 text-orange",
    component: Gifts,
    layout: "/admin",
  },
  // {
  //   path: "/invoice",
  //   name: "Invoice",
  //   icon: "ni ni-paper-diploma text-blue",
  //   component: Invoice,
  //   layout: "/admin",
  // },
   {
    path: "/icons",
    name: "Icons",
    icon: "ni ni-planet text-blue",
    component: Icons,
    layout: "/admin",
  },
  {
    path: "/maps",
    name: "Maps",
    icon: "ni ni-pin-3 text-orange",
    component: Maps,
    layout: "/admin",
  },
  {
    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-single-02 text-yellow",
    component: Profile,
    layout: "/admin",
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth",
  },
];
export default routes;
