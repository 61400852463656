import React, { useState, useEffect } from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { PdfDocument } from "./PDFDocument";
import { firestore } from "../../config/Fire";

export default function MovieList() {
  const [year, setYear] = useState("");
  const [data, setdata] = useState([]);
  const [show, setHide] = useState(false)

  useEffect(() => {
      firestore
      .collection("billings")
      .doc("80X5IGvMBodtiWYCN8YN")
      .onSnapshot(documentSnapshots => {
          const data = [];
          data.push({
              ...documentSnapshots.data(),
          })
          setdata(data);
          setHide(true)
      })
  }, [])

  return (
    <div className="container">
      <h2>Best movies of the year</h2>
      <label htmlFor="movies">Select Year</label>
      <select id="movies" className="select" onChange={(e) => e.preventDefault()}>
        <option defaultValue="" disabled>
          Select your option
        </option>
      </select>
      {show &&<PDFDownloadLink
        document={<PdfDocument data={data} />}
        fileName="movielist.pdf"
        style={{
          textDecoration: "none",
          padding: "10px",
          color: "#4a4a4a",
          backgroundColor: "#f2f2f2",
          border: "1px solid #4a4a4a"
        }}
      >
        {({ blob, url, loading, error }) =>
          loading ? "Loading document..." : "Download Pdf"
        }
      </PDFDownloadLink>}
    </div>
  );
}
