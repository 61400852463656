import React, { useState, useEffect } from "react";
import {
  Button,
  Badge,
  Card,
  CardHeader,
  CardFooter,
  CardBody,
  DropdownItem,
  DropdownMenu,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  UncontrolledTooltip,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import {  Select, TextField } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
// core components
import AddBookHeader from "components/Headers/AddBookHeader";
import { firebaseAuth, firestore } from "../../config/Fire";
import { useHistory, useLocation } from "react-router-dom";
import Books from "views/examples/Books";


const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: 200,
    },
  },
}));
const bookTypes = [
  'Gift Books',
  'Sarees',
  'Photo Frame',
  'Diary Set'
]
const AddBook = () => {
  const history = useHistory();
  const [error, setError] = useState(null);
  const [types, setTypes] = useState("Gift Books");
  const [bookInfo, setBookInfo] = useState([
    {
      name: '',
      price: 0,
      discount: 0,
    }
  ]);
  const handleBookInfoInputChange = (event) => {
    const book = [...bookInfo];
    if (event.target.name === "name") {
      book[0].name = event.target.value;
    }
    else if (event.target.name === "price") {
      book[0].price = event.target.value;
    }
    else {
      book[0].discount = event.target.value;
    }
    setBookInfo(book);
  }

  const handleSubmit = e => {
    console.log(bookInfo);
    if (bookInfo[0].name === "" && bookInfo[0].price === 0) {
      console.log("No");
      setError(true);
    } else {
      console.log("types: ", types);
      setError(false);
      console.log(bookInfo);
    //   firestore
    //     .collection("gifts")
    //     .add({
    //       giftType: types,
    //       giftInfo: bookInfo[0],
    //       timeStamp: Date.now(),
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     })
    //     .then((response) => {
    //       // console.log('response: ', response);
    //       history.push("/admin/gifts");
    //     });
      firestore
        .collection("gifts")
        .add({
          giftType: types,
          giftInfo: bookInfo[0],
          timeStamp: Date.now(),
        })
        .catch((err) => {
          console.log(err);
        })
        .then(() => {
          history.push("/admin/gifts");
        });
    }
    };
  return (
    <>
      <AddBookHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Please Fill the Required fields</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                <h6 className="heading-small text-muted mb-4">
                    Gift Info
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                    <Col lg="6">
                        <FormGroup>
                        <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Gift Type
                      </label>
                      <TextField
                        id="outlined-select-currency-native"
                        fullWidth
                        size="small"
                        style={{
                          backgroundColor: "#fff",
                          color: "#8898aa",
                        }}
                        select
                        placeholder="Bill To"
                        name="billTo"
                        value={types}
                        onChange={(e) =>{

                          setTypes(e.target.value)
                        } }
                        onch
                        SelectProps={{
                          native: true,
                          
                          // placeholder:'Please select book type'
                        }}
                        helperText="Please fill this out"
                        variant="outlined"
                      >
                        {bookTypes.map((option) => (
                          <option
                            key={option}
                            value={option}
                          >
                            {option}
                          </option>
                        ))}
                      </TextField>
                        </FormGroup>
                      </Col>  
                    <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Name
                          </label>
                          <TextField
                            fullWidth
                            style={{backgroundColor:"#fff",color:"#8898aa"}}
                            id="input-name"
                            size="small"
                            placeholder="Name"
                            name="name"
                            value={bookInfo[0].name}
                            onChange={(e) => handleBookInfoInputChange(e)}
                            type="text"
                            required={true}
                            helperText={bookInfo[0].name === ""? "Please fill this": null}
                            variant="outlined"
                          />
                        </FormGroup>
                      </Col>
                      
                    </Row>
                    <Row>
                    <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-price"
                          >
                            Price
                          </label>
                          <TextField
                            fullWidth
                            style={{backgroundColor:"#fff",color:"#8898aa"}}
                            id="input-price"
                            size="small"
                            placeholder="Price"
                            name="price"
                            value={bookInfo[0].price}
                            onChange={(e) => handleBookInfoInputChange(e)}
                            type="number"
                            required={true}
                            helperText={bookInfo[0].price === 0? "Please fill this": null}
                            variant="outlined"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-discount"
                          >
                            Discount
                          </label>
                          <TextField
                            fullWidth
                            style={{backgroundColor:"#fff",color:"#8898aa"}}
                            size="small"
                            placeholder="Discount"
                            id="input-discount"
                            name="discount"
                            value={bookInfo[0].discount}
                            onChange={(e) => handleBookInfoInputChange(e)}
                            type="number"
                            required={true}
                            variant="outlined"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                </Form>
              </CardBody>
              <CardFooter>
              <Row>
                <Col className="text-right">
                <label
                    className="form-control-label"
                    htmlFor="input-zipcode"
                    style={{color:"red"}}
                  >
                    {error? "* Please fill all the details" : null}
                </label>
                </Col>
                <Col className="text-right">
                  <Button
                    color="success"
                    href="#pablo"
                    onClick={() => handleSubmit()}
                    size="m"
                  >
                    Add Gift
                  </Button>
                  </Col>
              </Row>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AddBook;
