import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardFooter,
  CardBody,
  DropdownItem,
  DropdownMenu,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Table,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import AddBillingHeader from "components/Headers/AddBillingHeader";
import { TextField } from "@material-ui/core";
import { firestore } from "../../config/Fire";
import { useHistory } from "react-router-dom";
import useBooks from "hooks/use-books";

const EditCreditNotes = ({ match }) => {
  const history = useHistory();
  const { books: fetchedBooks } = useBooks();
  const [types, setTypes] = useState("");
  const [subType, setSubType] = useState("");
  const [fields, setFields] = useState([]);

  const [dealers, setdealers] = useState([]);
  const [books, setbooks] = useState(fetchedBooks);
  const [netAmount, setnetAmount] = useState(0);
  const [totalBooks, settotalBooks] = useState(0);
  const [creditNotes, setcreditNotes] = useState([]);

  const [billingInfo, setbillingInfo] = useState([
    {
      to: "",
      address: "",
      contactPerson: "",
      phoneNo: "",
      customerGST: "",
    },
  ]);
  const [productInfo, setproductInfo] = useState([
    {
      particulars: "",
      isbn: 0,
      quantity: 0,
      price: 0,
      discount: 0,
      amount: 0,
      total: 0,
    },
  ]);
  const [subProducts, setSubProducts] = useState([]);
  const [initialSubType, setInitialSubType] = useState([]);
  const [initialSubProducts, setInitialSubProducts] = useState([]);
  const [newBooks, setNewBooks] = useState([]);
  const [newGifts, setNewGifts] = useState([]);
  const [intialproduct, setInitialProductInfo] = useState([]);

  const products = [
    {
      name: "Books",
      sub_products: [
        { name: "It Kode", values: ["It Kode 1", "It Kode 2"] },
        { name: "Krypto" },
        { name: "Cyber Navigation" },
        { name: "Analog" },
      ],
    },
    {
      name: "Gifts",
      sub_products: [
        { name: "Gift Books" },
        { name: "Sarees" },
        { name: "Photo Frame" },
        { name: "Diary Set" },
      ],
    },
  ];

  useEffect(() => {
    firestore
      .collection("credit-notes")
      .orderBy("timeStamp", "desc")
      .onSnapshot((querrySnapshots) => {
        const creditNotes = [];
        if (!querrySnapshots.empty) {
          querrySnapshots.forEach((documentSnapshots) => {
            creditNotes.push({
              ...documentSnapshots.data(),
              key: documentSnapshots.id,
            });
          });
        }
        setcreditNotes(creditNotes);
      });
  }, []);

  useEffect(() => {
    firestore.collection("dealers").onSnapshot((querrySnapshots) => {
      const dealers = [];
      querrySnapshots.forEach((documentSnapshots) => {
        dealers.push({
          ...documentSnapshots.data(),
          key: documentSnapshots.id,
        });
      });
      setdealers(dealers);
      const billing = [...billingInfo];
      billing[0].to = dealers[0].basicInfo[0].name;
      billing[0].address = dealers[0].billingAddress[0].address;
      billing[0].contactPerson = dealers[0].basicInfo[0].primaryContactName;
      billing[0].phoneNo = dealers[0].basicInfo[0].phoneNo;
      billing[0].customerGST =
        dealers[0].basicInfo[0].customerGST === undefined
          ? ""
          : dealers[0].basicInfo[0].customerGST;
      setbillingInfo(billing);
    });
  }, []);

  useEffect(() => {
    if (match.params.id != "") {
      firestore
        .collection("credit-notes")
        .doc(match.params.id)
        .onSnapshot((documentSnapshots) => {
          const creditNotes = [];
          creditNotes.push({
            ...documentSnapshots.data(),
            key: documentSnapshots.id,
          });
          setTypes(creditNotes[0].types);
          setSubType(creditNotes[0].subType);
          setSubProducts(creditNotes[0].productInfo);
          setInitialProductInfo(creditNotes[0].productInfo);
          setproductInfo(creditNotes[0].productInfo);
          setbillingInfo(creditNotes[0].billingInfo);
          settotalBooks(creditNotes[0].totalBooks);
          setnetAmount(creditNotes[0].netAmount);
          setcreditNotes(creditNotes[0]);

          let filteredProducts = [];
          if (creditNotes[0].types === "Books") {
            let XProducts = newBooks.filter((book) => {
              return book.bookType === creditNotes[0].subType;
            });
            filteredProducts = XProducts.map((product) => {
              let book = creditNotes[0].productInfo.find((book) => {
                return book.particulars === product.bookInfo.name;
              });
              return {
                ...product,
                quantity: book ? book.quantity : 0,
                total: book ? book.total : 0,
                price: book ? book.price : product.bookInfo.price,
                discount: book ? book.discount : product.bookInfo.discount,
              };
            });
          } else {
            filteredProducts = newGifts
              .filter((gift) => {
                return gift.giftType === creditNotes[0].types;
              })
              .map((product) => {
                return {
                  ...product,
                  quantity: 0,
                  total: 0,
                  price: product.giftInfo.price,
                  discount: product.giftInfo.discount,
                };
              });
          }

          setInitialSubProducts(filteredProducts);
          setInitialSubType(creditNotes[0].subType);
          setSubProducts(filteredProducts);
        });
    }
  }, [match?.params?.id, newBooks, newGifts]);

  useEffect(() => {
    firestore
      .collection("new_books")
      .orderBy("bookInfo.name", "asc")
      .onSnapshot((querrySnapshots) => {
        const books = [];
        querrySnapshots.forEach((documentSnapshots) => {
          books.push({
            ...documentSnapshots.data(),
            key: documentSnapshots.id,
          });
        });
        setbooks(books);
        setNewBooks(books);
      });

    firestore.collection("gifts").onSnapshot((querrySnapshots) => {
      const gifts = [];
      querrySnapshots.forEach((documentSnapshots) => {
        gifts.push({
          ...documentSnapshots.data(),
          key: documentSnapshots.id,
        });
      });
      setNewGifts(gifts);
    });
  }, []);

  // Handlers Methods
  const handleAddFields = () => {
    const values = [...productInfo];
    values.push({
      particulars: "",
      isbn: 0,
      quantity: 0,
      price: 0,
      discount: 0,
      amount: 0,
      total: 0,
    });
    setproductInfo(values);
  };

  const handleRemoveFields = (index) => {
    const values = [...productInfo];
    values.splice(index, 1);
    setproductInfo(values);
  };

  // On Change Methods
  const handleProductInputChange = (index, event) => {
    const values = [...subProducts];
    if (event.target.name === "particulars") {
      const product = [...subProducts];
      product[index].total = 0;
      for (var i = 0; i < books.length; i++) {
        if (books[i].bookInfo.name === event.target.value) {
          product[index].particulars = books[i].bookInfo.name;
          product[index].price = books[i].bookInfo.price;
          product[index].discount = books[i].bookInfo.discount;
          product[index].total =
            parseInt(product[index].price) * parseInt(product[index].quantity) -
            (parseInt(product[index].price) *
              parseInt(product[index].quantity) *
              parseInt(product[index].discount)) /
              100;
          setproductInfo(product);
          setSubProducts(product);
        }
      }
    } else if (event.target.name === "isbn") {
      values[index].isbn = event.target.value;
    } else if (event.target.name === "quantity") {
      values[index].quantity = event.target.value;
      values[index].total =
        parseInt(values[index].price) * parseInt(values[index].quantity) -
        (parseInt(values[index].price) *
          parseInt(values[index].quantity) *
          parseInt(values[index].discount)) /
          100;
      setSubProducts(values);
    } else if (event.target.name === "price") {
      values[index].price = event.target.value;
      values[index].total =
        parseInt(values[index].price) * parseInt(values[index].quantity) -
        (parseInt(values[index].price) *
          parseInt(values[index].quantity) *
          parseInt(values[index].discount)) /
          100;
    } else if (event.target.name === "discount") {
      values[index].discount = event.target.value;
      values[index].total =
        parseInt(values[index].price) * parseInt(values[index].quantity) -
        (parseInt(values[index].price) *
          parseInt(values[index].quantity) *
          parseInt(values[index].discount)) /
          100;
    } else {
      values[index].amount = event.target.value;
    }
    setproductInfo(values);
    setSubProducts(values);

    var temp1 = 0;
    var temp2 = 0;
    for (var i = 0; i < productInfo.length; i++) {
      temp1 = temp1 + productInfo[i].total;
      temp2 = temp2 + parseInt(productInfo[i].quantity);
    }
    setnetAmount(temp1);
    settotalBooks(temp2);
  };

  const handleBillingInputChange = (event) => {
    const values = [...billingInfo];
    if (event.target.name === "to") {
      values[0].to = event.target.value;
      const billing = [...billingInfo];
      for (var i = 0; i < dealers.length; i++) {
        if (dealers[i].basicInfo[0].name === event.target.value) {
          billing[0].address = dealers[i].billingAddress[0].address;
          billing[0].contactPerson = dealers[i].basicInfo[0].primaryContactName;
          billing[0].phoneNo = dealers[i].basicInfo[0].phoneNo;
          billing[0].customerGST = dealers[i].basicInfo[0].customerGST;
          setbillingInfo(billing);
        }
      }
    } else if (event.target.name === "address") {
      values[0].address = event.target.value;
    } else if (event.target.name === "contactPerson") {
      values[0].contactPerson = event.target.value;
    } else if (event.target.name === "customer-gst-no") {
      values[0].customerGST = event.target.value;
    } else {
      values[0].phoneNo = event.target.value;
    }

    setbillingInfo(values);
  };

  // Submit Methods
  const handleSubmit = (e) => {
    e.preventDefault();

    let selectedProducts = subProducts
      .filter((sub_product) => {
        return sub_product.quantity && sub_product.quantity != 0;
      })
      .map((sub_product) => {
        if (sub_product.quantity) {
          calcTotalBooks = calcTotalBooks + parseInt(sub_product.quantity);
        }
        return {
          particulars:
            types === "Books"
              ? sub_product.bookInfo.name
              : sub_product.giftInfo.name,
          amount:
            types === "Books"
              ? sub_product.bookInfo.price
              : sub_product.giftInfo.price,
          price:
            types === "Books"
              ? sub_product.bookInfo.price
              : sub_product.giftInfo.price,
          total: sub_product.total,
          isbn: 0,
          quantity: sub_product.quantity ? sub_product.quantity : 0,
          discount: sub_product.discount ? sub_product.discount : 0,
        };
      });

    selectedProducts = selectedProducts.map((p) => {
      if (intialproduct?.some((t) => t.particulars === p.particulars)) {
        return {
          ...p,
          quantity: p.quantity,
          total:
            parseInt(p.price) * parseInt(p.quantity) -
            (parseInt(p.price) * parseInt(p.quantity) * parseInt(p.discount)) /
              100,
        };
      } else {
        return p;
      }
    });

    const updatedInitalProduct = intialproduct.filter(
      (p) => !selectedProducts.some((t) => t.particulars === p.particulars)
    );

    selectedProducts =
      updatedInitalProduct.length > 0
        ? [...updatedInitalProduct, ...selectedProducts]
        : selectedProducts;
    let netAmount = selectedProducts.reduce(function (sum, current) {
      return sum + parseFloat(current.total);
    }, 0);
    let calcTotalBooks = selectedProducts.reduce(function (sum, current) {
      return sum + parseFloat(current.quantity);
    }, 0);

    firestore
      .collection("credit-notes")
      .doc(match.params.id)
      .set(
        {
          billingInfo,
          productInfo: selectedProducts,
          netAmount,
          totalBooks: calcTotalBooks,
          timeStamp: Date.now(),
          to: billingInfo[0].to,
          creditNoteNo: creditNotes.creditNoteNo,
          types,
          subType,
        },
        { merge: true }
      )
      .catch((err) => console.log(err))
      .then(() => history.push("/admin/credit-notes"));
  };

  return (
    <>
      <AddBillingHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Please fill the required details</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <h6 className="heading-small text-muted mb-4">
                    Bill information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            To
                          </label>
                          <TextField
                            id="outlined-select-currency-native"
                            fullWidth
                            size="small"
                            style={{
                              backgroundColor: "#fff",
                              color: "#8898aa",
                            }}
                            select
                            placeholder="Bill To"
                            name="to"
                            value={billingInfo[0].to}
                            onChange={(e) => handleBillingInputChange(e)}
                            onch
                            SelectProps={{
                              native: true,
                            }}
                            helperText="Please fill this out"
                            variant="outlined"
                          >
                            {dealers.map((option) => (
                              <option
                                key={option.key}
                                value={option.basicInfo[0].name}
                              >
                                {option.basicInfo[0].name}
                              </option>
                            ))}
                          </TextField>
                          {/* <Input
                            className="form-control-alternative"
                            id="input-username"
                            required
                            name="to"
                            value={billingInfo.to}
                            onChange={(e) => handleBillingInputChange(e)}
                            placeholder="Bill To"
                            type="text"
                          /> */}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Address
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-email"
                            name="address"
                            value={billingInfo[0].address}
                            onChange={(e) => handleBillingInputChange(e)}
                            placeholder="Address"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Contact Person
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            name="contactPerson"
                            value={billingInfo[0].contactPerson}
                            onChange={(e) => handleBillingInputChange(e)}
                            placeholder="Contact Person"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Phone No./ Mob. No.
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-email"
                            name="phoneNo"
                            value={billingInfo[0].phoneNo}
                            onChange={(e) => handleBillingInputChange(e)}
                            placeholder="Phone Number"
                            type="number"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-zipcode"
                          >
                            Customer GST No.
                          </label>
                          <TextField
                            fullWidth
                            style={{
                              backgroundColor: "#fff",
                              color: "#8898aa",
                            }}
                            size="small"
                            id="input-zipcode"
                            name="customer-gst-no"
                            value={billingInfo[0].customerGST}
                            onChange={(e) => handleBillingInputChange(e)}
                            placeholder="Customer GST No."
                            type="text"
                            variant="outlined"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  <Row>
                    <div className="col">
                      <Card className="shadow">
                        <CardHeader className="border-0">
                          <Row>
                            <Col xs="10">
                              <h3 className="mb-0">
                                Product Information &emsp;
                                <select
                                  style={{
                                    backgroundColor: "#fff",
                                    color: "#8898aa",
                                    borderRadius: "4px",
                                    paddingTop: "6px",
                                    paddingBottom: "6px",
                                    paddingLeft: "12px",
                                    paddingRight: "12px",
                                  }}
                                  onChange={(e) => {
                                    setTypes(e.target.value);
                                    setSubType("");
                                    setFields([]);
                                  }}
                                  value={types}
                                >
                                  <option
                                    selected="true"
                                    style={{ display: "none" }}
                                  >
                                    Select
                                  </option>
                                  {products.map((product) => {
                                    return (
                                      <option value={product.value}>
                                        {product.name}
                                      </option>
                                    );
                                  })}
                                </select>{" "}
                                &emsp;
                                <select
                                  style={{
                                    backgroundColor: "#fff",
                                    color: "#8898aa",
                                    borderRadius: "4px",
                                    paddingTop: "6px",
                                    paddingBottom: "6px",
                                    paddingLeft: "12px",
                                    paddingRight: "12px",
                                  }}
                                  onChange={(e) => {
                                    setSubType(e.target.value);
                                    // let newFields = [...fields];
                                    let filteredProducts = [];
                                    setSubProducts([]);
                                    if (e.target.value === initialSubType) {
                                      setSubProducts(initialSubProducts);
                                      return;
                                    }
                                    if (types === "Books") {
                                      let XProducts = newBooks.filter(
                                        (book) => {
                                          return (
                                            book.bookType === e.target.value
                                          );
                                        }
                                      );
                                      filteredProducts = XProducts.map(
                                        (product) => {
                                          return {
                                            ...product,
                                            quantity: 0,
                                            total: 0,
                                            price: product.bookInfo.price,
                                            discount: product.bookInfo.discount,
                                          };
                                        }
                                      );
                                    } else {
                                      filteredProducts = newGifts
                                        .filter((gift) => {
                                          return (
                                            gift.giftType === e.target.value
                                          );
                                        })
                                        .map((product) => {
                                          return {
                                            ...product,
                                            quantity: 0,
                                            total: 0,
                                            price: product.giftInfo.price,
                                            discount: product.giftInfo.discount,
                                          };
                                        });
                                    }
                                    setSubProducts(filteredProducts);
                                  }}
                                  value={subType}
                                  disabled={types === "0"}
                                >
                                  <option
                                    selected="true"
                                    style={{ display: "none" }}
                                    value={"0"}
                                  >
                                    Select
                                  </option>

                                  {types === "Books" && (
                                    <>
                                      {products[0].sub_products.map(
                                        (sub_product) => {
                                          return (
                                            <option value={sub_product.name}>
                                              {sub_product.name}
                                            </option>
                                          );
                                        }
                                      )}
                                    </>
                                  )}

                                  {types === "Gifts" && (
                                    <>
                                      {products[1].sub_products.map(
                                        (sub_product) => {
                                          return (
                                            <option value={sub_product.name}>
                                              {sub_product.name}
                                            </option>
                                          );
                                        }
                                      )}
                                    </>
                                  )}
                                </select>
                              </h3>
                            </Col>
                            <Col xs="">
                              <Button
                                color="primary"
                                href="#pablo"
                                onClick={() => handleAddFields()}
                                size="sm"
                              >
                                Add New
                              </Button>
                            </Col>
                          </Row>
                        </CardHeader>
                        {types &&
                          subType &&
                          subProducts &&
                          subProducts.length > 0 && (
                            <Table
                              className="align-items-center table-flush"
                              responsive
                            >
                              <thead className="thead-light">
                                <tr>
                                  <th scope="col">Id</th>
                                  <th scope="col">Book Name</th>
                                  {/* <th scope="col">ISBN</th> */}
                                  <th scope="col">Price</th>
                                  <th scope="col"> Fixed Disc. %</th>
                                  <th scope="col">Quantity</th>
                                  <th scope="col">Total</th>
                                  <th scope="col">Options</th>
                                </tr>
                              </thead>
                              {subProducts.map((inputField, index) => (
                                <tbody>
                                  <tr>
                                    <th scope="row">
                                      <Media className="align-items-center">
                                        <span className="mb-0 text-sm">
                                          {index + 1}
                                        </span>
                                      </Media>
                                    </th>
                                    <td>
                                      <TextField
                                        id="outlined-read-only-input"
                                        value={
                                          types === "Books"
                                            ? inputField?.bookInfo?.name
                                            : inputField?.giftInfo?.name
                                        }
                                        InputProps={{
                                          readOnly: true,
                                        }}
                                        style={{ width: "max-content" }}
                                        variant="outlined"
                                        size="small"
                                      />
                                      {/* <Input
                                className="form-control-alternative"
                                style={{minWidth:"100px"}}
                                placeholder="Particulars"
                                type="text"
                                id="particulars"
                                name="particulars"
                                value={inputField.particulars}
                                onChange={event => handleProductInputChange(index, event)}
                              /> */}
                                    </td>
                                    {/* <td>
                                <Badge color="" className="badge-dot mr-4">
                                  <Input
                                    className="form-control-alternative"
                                    id="isbn"
                                    style={{minWidth:"100px"}}
                                    name="isbn"
                                    placeholder="ISBN"
                                    type="number"
                                    value={inputField.isbn}
                                    onChange={event => handleProductInputChange(index, event)}
                                  />
                                </Badge>
                              </td> */}
                                    <td>
                                      <Input
                                        className="form-control-alternative"
                                        id="price"
                                        style={{ minWidth: "100px" }}
                                        placeholder="Price"
                                        min={0}
                                        name="price"
                                        type="number"
                                        value={inputField.price}
                                        onChange={(event) =>
                                          handleProductInputChange(index, event)
                                        }
                                      />
                                    </td>
                                    <td>
                                      <Input
                                        className="form-control-alternative"
                                        id="discount"
                                        style={{ minWidth: "100px" }}
                                        placeholder="Discount"
                                        min={0}
                                        name="discount"
                                        type="number"
                                        value={inputField.discount}
                                        onChange={(event) =>
                                          handleProductInputChange(index, event)
                                        }
                                      />
                                    </td>
                                    <td>
                                      <Input
                                        className="form-control-alternative"
                                        id="quantity"
                                        style={{ minWidth: "100px" }}
                                        name="quantity"
                                        placeholder="Quantity"
                                        min={0}
                                        type="number"
                                        value={inputField.quantity}
                                        onChange={(event) =>
                                          handleProductInputChange(index, event)
                                        }
                                      />
                                    </td>
                                    {/* <td>
                              <Input
                                className="form-control-alternative"
                                id="amount"
                                style={{minWidth:"100px"}}
                                placeholder="Amount"
                                name="amount"
                                type="number"
                                value={inputField.amount}
                                  onChange={event => handleProductInputChange(index, event)}
                              />
                              </td> */}
                                    <td>{inputField.total}</td>
                                    <td className="text-right">
                                      <UncontrolledDropdown>
                                        <DropdownToggle
                                          className="btn-icon-only text-light"
                                          href="#pablo"
                                          role="button"
                                          size="sm"
                                          color=""
                                          onClick={(e) => e.preventDefault()}
                                        >
                                          <i className="fas fa-ellipsis-v" />
                                        </DropdownToggle>
                                        <DropdownMenu
                                          className="dropdown-menu-arrow"
                                          right
                                        >
                                          <DropdownItem
                                            href="#pablo"
                                            onClick={(e) => e.preventDefault()}
                                          >
                                            View Details
                                          </DropdownItem>
                                          <DropdownItem
                                            href="#pablo"
                                            onClick={(e) =>
                                              handleRemoveFields(index)
                                            }
                                          >
                                            Delete
                                          </DropdownItem>
                                          <DropdownItem
                                            href="#pablo"
                                            onClick={(e) => e.preventDefault()}
                                          >
                                            Something else here
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                    </td>
                                  </tr>
                                </tbody>
                              ))}
                              <thead className="thead-light">
                                <tr>
                                  <th scope="col" colSpan="4">
                                    Total No. of Books
                                  </th>
                                  <th scope="col">{totalBooks}</th>
                                  <th scope="col" colSpan="2">
                                    {"₹ " + netAmount}
                                  </th>
                                </tr>
                              </thead>
                            </Table>
                          )}
                        <CardFooter className="py-4">
                          <Row>
                            <Col xs="6" />
                            <Col xs="6">
                              <Row>
                                <Col>
                                  <h4>POSTAGE & COURIER :</h4>
                                </Col>
                                <Col>
                                  <h4>0.0</h4>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <h4>FREIGHT COST :</h4>
                                </Col>
                                <Col>
                                  <h4>0.0</h4>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <h4>Round Off :</h4>
                                </Col>
                                <Col>
                                  <h4>0.0</h4>
                                </Col>
                              </Row>
                              <hr></hr>
                              <Row>
                                <Col>Net Amount</Col>
                                <Col>{"Rs " + parseInt(netAmount)}</Col>
                              </Row>
                            </Col>
                          </Row>
                        </CardFooter>
                      </Card>
                    </div>
                  </Row>
                </Form>
                <Row></Row>
              </CardBody>
              <CardFooter>
                <Button
                  color="success"
                  href="#pablo"
                  onClick={(e) => handleSubmit(e)}
                  size="m"
                >
                  Save Note
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EditCreditNotes;
