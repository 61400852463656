import React, { useState, useEffect } from "react";
import {
  Button,
  Badge,
  Card,
  CardHeader,
  CardFooter,
  CardBody,
  DropdownItem,
  DropdownMenu,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  UncontrolledTooltip,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import {  TextField } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
// core components
import AddDealerHeader from "components/Headers/AddDealerHeader";
import { firebaseAuth, firestore } from "../../config/Fire";
import { useHistory, useLocation } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: 200,
    },
  },
}));

const EditDealer = ({match}) => {
  const history = useHistory();
  const [error, setError] = useState(null)
  const [dealerData, setdealerData] = useState([]);

  const [basicInfo, setBasicInfo] = useState([
    {
      name: '',
      primaryContactName: '',
      email: '',
      phoneNo: '',
      customerGST:"",
    }
  ]);
  const [billingAddress, setBillingAddress] = useState([
    {
      name: '',
      country: '',
      state: '',
      city: '',
      address: '',
      phone: '',
      zipcode: '',
    }
  ]);
  const [shippingAddress, setshippingAddress] = useState([
    {
      name: '',
      country: '',
      state: '',
      city: '',
      address: '',
      phone: '',
      zipcode: '',
    }
  ]);

  useEffect(() => {
    if(match.params.id != "") {
        firestore
        .collection("dealers")
        .doc(match.params.id)
        .onSnapshot(documentSnapshots => {
            const dealerData = [];
            dealerData.push({
                ...documentSnapshots.data(),
                key: documentSnapshots.id,
            })
            setBasicInfo(dealerData[0].basicInfo);
            setBillingAddress(dealerData[0].billingAddress);
            setshippingAddress(dealerData[0].shippingAddress);
            console.log(dealerData[0].basicInfo);
            console.log(dealerData[0].shippingAddress);
            console.log(dealerData[0].billingAddress);

            setdealerData(dealerData[0]);
            console.log(dealerData)
        });
    }
  }, []);

  const handleBasicInfoInputChange = (event) => {
    const basic = [...basicInfo];
    if (event.target.name === "name") {
      basic[0].name = event.target.value;
    }
    else if (event.target.name === "primaryContactName") {
      basic[0].primaryContactName = event.target.value;
    }
    else if (event.target.name === "email") {
      basic[0].email = event.target.value;
    }
    else if (event.target.name === "customer-gst-no") {
      basic[0].customerGST = event.target.value;
    }
    else {
      basic[0].phoneNo = event.target.value;
    }

    setBasicInfo(basic);
  }
  const handleBillingAddressInputChange = (event) => {
    const billing = [...billingAddress];
    if (event.target.name === "name") {
      billing[0].name = event.target.value;
    }
    else if (event.target.name === "country") {
      billing[0].country = event.target.value;
    }
    else if (event.target.name === "state") {
      billing[0].state = event.target.value;
    }
    else if (event.target.name === "city") {
      billing[0].city = event.target.value;
    }
    else if (event.target.name === "address") {
      billing[0].address = event.target.value;
    }
    else if (event.target.name === "phone") {
      billing[0].phone = event.target.value;
    }
    else {
      billing[0].zipcode = event.target.value;
    }

    setBillingAddress(billing);
  }
  const handleShippingAddressInputChange = (event) => {
    const shipping = [...shippingAddress];
    if (event.target.name === "name") {
      shipping[0].name = event.target.value;
    }
    else if (event.target.name === "country") {
      shipping[0].country = event.target.value;
    }
    else if (event.target.name === "state") {
      shipping[0].state = event.target.value;
    }
    else if (event.target.name === "city") {
      shipping[0].city = event.target.value;
    }
    else if (event.target.name === "address") {
      shipping[0].address = event.target.value;
    }
    else if (event.target.name === "phone") {
      shipping[0].phone = event.target.value;
    }
    else {
      shipping[0].zipcode = event.target.value;
    }

    setshippingAddress(shipping);
  }

  const handleSubmit = e => {
    console.log(shippingAddress[0].address === "");
    console.log(basicInfo);
    console.log(shippingAddress);
    if(
      basicInfo[0].name === "" || basicInfo[0].phoneNo === "" && 
      billingAddress[0].name === "" || billingAddress[0].address === "" && 
      shippingAddress[0].name === "" || shippingAddress[0].address === ""
      ) {
        console.log("No")
        setError(true)
      } else {
        firestore
        .collection("dealers")
        .doc(match.params.id)
        .set({
          basicInfo,
          billingAddress,
          shippingAddress,
          timeStamp: Date.now(),
        }, { merge: true})
        .catch(err => {
          console.log(err)
        })
        .then(() => {
          history.push("/admin/dealers");
        })
    
      }
    };

  const handleCopy = () => {
    const values = [...shippingAddress];
    console.log(billingAddress);
    values[0].name = billingAddress[0].name;
    values[0].country = billingAddress[0].country;
    values[0].state = billingAddress[0].state;
    values[0].city = billingAddress[0].city;
    values[0].address = billingAddress[0].address;
    values[0].phone = billingAddress[0].phone;
    values[0].zipcode = billingAddress[0].zipcode;
    setshippingAddress(values);
    console.log(shippingAddress[0].name);
  }
  return (
    <>
      <AddDealerHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Please Fill the Required fields</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                <h6 className="heading-small text-muted mb-4">
                    Basic Information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Name
                          </label>
                          <TextField
                            fullWidth
                            style={{backgroundColor:"#fff",color:"#8898aa"}}
                            id="input-name"
                            size="small"
                            placeholder="Name"
                            name="name"
                            value={basicInfo[0].name}
                            onChange={(e) => handleBasicInfoInputChange(e)}
                            type="text"
                            required={true}
                            helperText={basicInfo[0].name === ""? "Please fill this": null}
                            variant="outlined"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-name"
                          >
                            Primary Contact Name
                          </label>
                          <TextField
                            fullWidth
                            style={{backgroundColor:"#fff",color:"#8898aa"}}
                            id="input-name"
                            size="small"
                            placeholder="Primary Contact Name"
                            name="primaryContactName"
                            value={basicInfo[0].primaryContactName}
                            onChange={(e) => handleBasicInfoInputChange(e)}
                            type="text"
                            required={true}
                            helperText={basicInfo[0].primaryContactName === ""? "Please fill this": null}
                            variant="outlined"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Email
                          </label>
                          <TextField
                            fullWidth
                            style={{backgroundColor:"#fff",color:"#8898aa"}}
                            size="small"
                            placeholder="Email"
                            id="input-email"
                            name="email"
                            value={basicInfo[0].email}
                            onChange={(e) => handleBasicInfoInputChange(e)}
                            type="email"
                            required={true}
                            helperText={basicInfo[0].email === ""? "Please fill this": null}
                            variant="outlined"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-phone"
                          >
                            Phone No.
                          </label>
                          <TextField
                            fullWidth
                            style={{backgroundColor:"#fff",color:"#8898aa"}}
                            size="small"
                            id="input-phone"
                            name="phoneNo"
                            value={basicInfo[0].phoneNo}
                            onChange={(e) => handleBasicInfoInputChange(e)}
                            placeholder="Phone Number"
                            type="number"
                            required={true}
                            helperText={basicInfo[0].phoneNo === ""? "Please fill this": null}
                            variant="outlined"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <h6 className="heading-small text-muted mb-4">
                    Billing Address
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-name"
                          >
                            Name
                          </label>
                          <TextField
                            fullWidth
                            style={{backgroundColor:"#fff",color:"#8898aa"}}
                            size="small"
                            id="input-name"
                            name="name"
                            value={billingAddress[0].name}
                            onChange={(e) => handleBillingAddressInputChange(e)}
                            placeholder="Name"
                            type="text"
                            required={true}
                            helperText={billingAddress[0].name === ""? "Please fill this": null}
                            variant="outlined"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-country"
                          >
                            Country
                          </label>
                          <TextField
                            fullWidth
                            style={{backgroundColor:"#fff",color:"#8898aa"}}
                            size="small"
                            id="input-country"
                            name="country"
                            value={billingAddress[0].country}
                            onChange={(e) => handleBillingAddressInputChange(e)}
                            placeholder="Country"
                            type="text"
                            required={true}
                            helperText={billingAddress[0].country === ""? "Please fill this": null}
                            variant="outlined"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-state"
                          >
                            State
                          </label>
                          <TextField
                            fullWidth
                            style={{backgroundColor:"#fff",color:"#8898aa"}}
                            size="small"
                            id="input-state"
                            name="state"
                            value={billingAddress[0].state}
                            onChange={(e) => handleBillingAddressInputChange(e)}
                            placeholder="State"
                            type="text"
                            required={true}
                            helperText={billingAddress[0].state === ""? "Please fill this": null}
                            variant="outlined"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-city"
                          >
                            City
                          </label>
                          <TextField
                            fullWidth
                            style={{backgroundColor:"#fff",color:"#8898aa"}}
                            size="small"
                            id="input-city"
                            name="city"
                            value={billingAddress[0].city}
                            onChange={(e) => handleBillingAddressInputChange(e)}
                            placeholder="City"
                            type="text"
                            required={true}
                            helperText={billingAddress[0].city === ""? "Please fill this": null}
                            variant="outlined"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-address"
                          >
                            Address
                          </label>
                          <TextField
                            fullWidth
                            style={{backgroundColor:"#fff",color:"#8898aa"}}
                            size="small"
                            id="input-address"
                            name="address"
                            value={billingAddress[0].address}
                            onChange={(e) => handleBillingAddressInputChange(e)}
                            placeholder="Address"
                            type="text"
                            required={true}
                            helperText={billingAddress[0].address === ""? "Please fill this": null}
                            variant="outlined"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-phone"
                          >
                            Phone No.
                          </label>
                          <TextField
                            fullWidth
                            style={{backgroundColor:"#fff",color:"#8898aa"}}
                            size="small"
                            id="input-phone"
                            name="phone"
                            value={billingAddress[0].phone}
                            onChange={(e) => handleBillingAddressInputChange(e)}
                            placeholder="Phone"
                            type="number"
                            required={true}
                            helperText={billingAddress[0].phone === ""? "Please fill this": null}
                            variant="outlined"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-zipcode"
                          >
                            Zip Code
                          </label>
                          <TextField
                            fullWidth
                            style={{backgroundColor:"#fff",color:"#8898aa"}}
                            size="small"
                            id="input-zipcode"
                            name="zipcode"
                            value={billingAddress[0].zipcode}
                            onChange={(e) => handleBillingAddressInputChange(e)}
                            placeholder="Zip Code"
                            type="text"
                            required={true}
                            helperText={billingAddress[0].zipcode === ""? "Please fill this": null}
                            variant="outlined"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-zipcode"
                          >
                            Customer GST No.
                          </label>
                          <TextField
                            fullWidth
                            style={{backgroundColor:"#fff",color:"#8898aa"}}
                            size="small"
                            id="input-zipcode"
                            name="customer-gst-no"
                            value={basicInfo[0]?.customerGST}
                            onChange={(e) => handleBasicInfoInputChange(e)}
                            placeholder="Customer GST No."
                            type="text"
                            required={true}
                            helperText={basicInfo[0].customerGST === ""? "Please fill this": null}
                            variant="outlined"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <Row>
                    <Col xs="10">
                    <h6 className="heading-small text-muted mb-4">
                      Shipping Address
                    </h6>
                    </Col>
                    <Col>
                    <Button
                      color="info"
                      onClick={() => handleCopy()}
                      size="sm"
                    >
                      Same as Billing
                    </Button>
                    </Col>
                  </Row>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-name"
                          >
                            Name
                          </label>
                          <TextField
                            fullWidth
                            style={{backgroundColor:"#fff",color:"#8898aa"}}
                            size="small"
                            id="input-name"
                            name="name"
                            value={shippingAddress[0].name}
                            onChange={(e) => handleShippingAddressInputChange(e)}
                            placeholder="Name"
                            type="text"
                            required={true}
                            helperText={shippingAddress[0].name === ""? "Please fill this": null}
                            variant="outlined"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-country"
                          >
                            Country
                          </label>
                          <TextField
                            fullWidth
                            style={{backgroundColor:"#fff",color:"#8898aa"}}
                            size="small"
                            id="input-country"
                            name="country"
                            value={shippingAddress[0].country}
                            onChange={(e) => handleShippingAddressInputChange(e)}
                            placeholder="Country"
                            type="text"
                            required={true}
                            helperText={shippingAddress[0].country === ""? "Please fill this": null}
                            variant="outlined"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-state"
                          >
                            State
                          </label>
                          <TextField
                            fullWidth
                            style={{backgroundColor:"#fff",color:"#8898aa"}}
                            size="small"
                            id="input-state"
                            name="state"
                            value={shippingAddress[0].state}
                            onChange={(e) => handleShippingAddressInputChange(e)}
                            placeholder="State"
                            type="text"
                            required={true}
                            helperText={shippingAddress[0].state === ""? "Please fill this": null}
                            variant="outlined"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-city"
                          >
                            City
                          </label>
                          <TextField
                            fullWidth
                            style={{backgroundColor:"#fff",color:"#8898aa"}}
                            size="small"
                            id="input-city"
                            name="city"
                            value={shippingAddress[0].city}
                            onChange={(e) => handleShippingAddressInputChange(e)}
                            placeholder="City"
                            type="text"
                            required={true}
                            helperText={shippingAddress[0].city === ""? "Please fill this": null}
                            variant="outlined"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-address"
                          >
                            Address
                          </label>
                          <TextField
                            fullWidth
                            style={{backgroundColor:"#fff",color:"#8898aa"}}
                            size="small"
                            id="input-address"
                            name="address"
                            value={shippingAddress[0].address}
                            onChange={(e) => handleShippingAddressInputChange(e)}
                            placeholder="Address"
                            type="text"
                            required={true}
                            helperText={shippingAddress[0].address === ""? "Please fill this": null}
                            variant="outlined"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-phone"
                          >
                            Phone No.
                          </label>
                          <TextField
                            fullWidth
                            style={{backgroundColor:"#fff",color:"#8898aa"}}
                            size="small"
                            id="input-phone"
                            name="phone"
                            value={shippingAddress[0].phone}
                            onChange={(e) => handleShippingAddressInputChange(e)}
                            placeholder="Phone"
                            type="number"
                            required={true}
                            helperText={shippingAddress[0].phone === ""? "Please fill this": null}
                            variant="outlined"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-zipcode"
                          >
                            Zip Code
                          </label>
                          <TextField
                            fullWidth
                            style={{backgroundColor:"#fff",color:"#8898aa"}}
                            size="small"
                            id="input-zipcode"
                            name="zipcode"
                            value={shippingAddress[0].zipcode}
                            onChange={(e) => handleShippingAddressInputChange(e)}
                            placeholder="Zip Code"
                            type="text"
                            required={true}
                            helperText={shippingAddress[0].zipcode === ""? "Please fill this": null}
                            variant="outlined"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                </Form>
              </CardBody>
              <CardFooter>
              <Row>
                <Col className="text-right">
                <label
                            className="form-control-label"
                            htmlFor="input-zipcode"
                            style={{color:"red"}}
                          >
                            {error?"* Please fill all the details" : null}
                </label>
                </Col>
                <Col className="text-right">
                  <Button
                    color="success"
                    href="#pablo"
                    onClick={() => handleSubmit()}
                    size="m"
                  >
                    Update Dealer
                  </Button>
                  </Col>
              </Row>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EditDealer;
